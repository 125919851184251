export const LOCAL_FILTER_FIELDS = [
    {
        label: "Location Code",
        type: "text",
        name: "locationCode",
    },
    {
        label: "Location Name",
        type: "text",
        name: "locationName",
    }
]