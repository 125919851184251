import { combineReducers } from "redux";

import userReducer from "./user/user.reducer";
import navigationReducer from "./navigation/navigation.reducer";
import modalReducer from "./modal/modal.reducer";
import formReducer from "./form/form.reducer";
import reportReducer from "./report/report.reducer";
import { persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import filterReducer from "./filter/filter.reducer";
import exportReducer from "./export/export.reducer";
import refreshReducer from "./refresh/refresh.reducer";
import detailReducer from "./detail/detail.reducer";
import alertReducer from "./alert/alert.reducer";
import deleteReducer from "./delete/delete.reducer";
import listReducer from "./list/list.reducer";
import documentReducer from "./document/document.reducer";
import deactivateReducer from "./deactivate/deactivate.reducer";


const persistConfig = {
    key: 'root',
    storage: storageSession,
    whitelist: ['user', 'detail']
}

const rootReducer = combineReducers({
    user: userReducer,
    navigation: navigationReducer,
    modal: modalReducer,
    form:formReducer,
    report: reportReducer,
    filter: filterReducer,
    exportReport: exportReducer,
    refresh: refreshReducer,
    detail: detailReducer,
    alert: alertReducer,
    delete: deleteReducer,
    list: listReducer,
    document: documentReducer,
    deactivate :deactivateReducer,
});

export default persistReducer(persistConfig, rootReducer);