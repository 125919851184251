import "./home-nav.styles.scss";

import IconNavList from '../../assets/icons/nav-list.svg';
import IconNavListItem from '../../assets/icons/nav-list-item.svg';
import { Link } from "react-router-dom";
import HomeNavItem from "../home-nav-item/home-nav-item.component";
import HomeChart from "../home-chart/home-chart.component";

const HomeNav = ({
    navItems,
    hasChart,
    isLoading,
    isReportNoAccess
}) => {
    return (
        <div className="home-nav">
            <div className="container-fluid">
                <div className="row">
                    {navItems.map(navItem => {
                        return (
                            <div className="home-nav__single col-md-4">
                                <HomeNavItem
                                    navItem={navItem}
                                />
                            </div>
                        )
                    })}
                    {!isReportNoAccess && hasChart &&
                        <div className="home-nav__chart col-md-8">
                            <HomeChart
                                isLoading={isLoading}
                            />
                        </div>
                    }

                </div>
            </div>
        </div>
    )
}

export default HomeNav;