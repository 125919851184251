import { format } from 'date-fns';
import TableCellStatus from '../../components/table-cell-status/table-cell-status.component';
import { displayCellValue, displayCellValueNumberToTwoDecimal } from '../../utils/column-values.util';
// import FormTableCalculateDisplay from '../../../components/form-table-calculate/form-table-calculate.component';
// import FormTableHeaderCell from '../../../components/form-table-header-cell/form-table-header-cell.component';
// import FormTableInlineDelete from '../../../components/form-table-inline-delete/form-table-inline-delete.component';
// import FormTableSelect from '../../../components/form-table-select/form-table-select.component';
// import FormTableTextField from '../../../components/form-table-text-field/form-table-text-field.component';

export const SALES_REPORT_BY_ITEM_INFO = {
  reportTitle: "Sales Reports",
  path: "/salesreports",
  subPath: "/salesbyitem",
  listPage: "/sales-reports",
  hasChart: false,
  tableHasGrandTotal: true,
}

export const SALES_REPORT_BY_ITEM_COLUMN_TEMPLATE = [
  {
    Header: "Product Code",
    accessor: "itemCode",
    Cell: ({ value }) => {
      return displayCellValue(value);
    },
    Footer: 'Grand Total'
  },
  {
    Header: "Product Name",
    accessor: "itemName",
    Cell: ({ value }) => {
      return displayCellValue(value);
    },
  },
  {
    Header: "Qty Sold",
    accessor: "qtySold",
    Cell: ({ value }) => {
      return displayCellValue(value);
    }
  },
  {
    Header: "Total Sales (ETB)",
    accessor: "totalSales",
    Cell: ({ value }) => {
        return displayCellValueNumberToTwoDecimal(value);
    },
    Footer: 'grandTotalSales'
  },
  {
    Header: "Cost of Goods Sold (ETB)",
    accessor: "costOfGoodsSold",
    Cell: ({ value }) => {
        return displayCellValueNumberToTwoDecimal(value);
    },
    Footer: 'grandcostOfGoodsSold'
  },
  {
    Header: "Gross Profit (ETB)",
    accessor: "grossProfit",
    Cell: ({ value }) => {
        return displayCellValueNumberToTwoDecimal(value);
    }
  },
 
]
