import React, { useEffect } from "react";

import DropDownContainer from '../drop-down-container/drop-down-container.componenet';
import IconActionDown from "../../assets/icons/action-down.svg";
import { setAlert, setAlertEndpoint, setUUIDForAlert } from "../../redux/alert/alert.actions";
import { connect } from "react-redux";
import { createStructuredSelector } from 'reselect';
import { selectSelectedRowsUUID } from "../../redux/list/list.reselect";
import { deactivate } from "../../redux/deactivate/deactivate.actions";
import { authTokenSelector } from "../../redux/user/user.reselect";
import { withRouter } from "react-router-dom";
import { selectFormInfo } from "../../redux/form/form.reselect";
import useFetchActiveState from "../../effects/use-fetch-active-state";
import { useState } from "react";

const ACTION_DROP_DOWN_ITEMS = [
    {
        id: "deactivate",
        text:"deactivate"
    },
    {
        id: "reactivate",
        text:"reactivate"
    }
]

const Deactivate = ({
    DropDownButton,
    authToken,
    deactivate,
    formInfo,
    match
}) => {
    const [isDeactivate, setisDeactivate] = useState(false);
    const activeStateUrl = formInfo ? `${formInfo.path}/state/${match.params.uuid}` : null
    const { activeStateData, activeStateError, isActiveStateLoading } = useFetchActiveState(
        activeStateUrl,
        authToken,
        setisDeactivate,
        isDeactivate
    )

    const handleClick = async (event) => {
        if (event.target.id === "deactivate") {
            const endpoint = `${formInfo.path}/${match.params.uuid}/deactivate`
            await deactivate(authToken, endpoint);
            setisDeactivate(true)
        } else if(event.target.id === "reactivate"){
            const endpoint = `${formInfo.path}/${match.params.uuid}/reactivate`
            await deactivate(authToken, endpoint);
            setisDeactivate(true);
        }
    }

    useEffect(() => {
        console.log("the following is active state data")
        console.log(activeStateData)
    }, [isDeactivate])

    return (
        <DropDownContainer
            DropDownButton={DropDownButton}
            dropDownItems={activeStateData.deactivate ? [ACTION_DROP_DOWN_ITEMS[1]] : [ACTION_DROP_DOWN_ITEMS[0]]}
            icon={IconActionDown}
            dropDwon={true}
            size="medium"
            handleClick={(event) => { handleClick(event) }}
        />
    )
}

const mapStateToProps = createStructuredSelector({
    authToken: authTokenSelector,
    formInfo: selectFormInfo,
});


const mapDispatchToProps = (dispatch) => ({
    deactivate: (authToken, endpoint) =>
        dispatch(deactivate(authToken, endpoint))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Deactivate))
