import React from 'react';

import './public-footer.styles.scss';

const PublicFooter = () => (
    <div className="public-footer">
        <p className="public-footer__text">© { new Date().getFullYear() } Kaliber ICT Solution. All rights reserved.</p>
    </div>
)

export default PublicFooter;