import React from "react";

import "./detail-body-subsection.styles.scss"

import lodash from 'lodash';

const DetailBodySubsection = ({
    sectionData,
    subSection,
    forPrint
}) => {

    return (
        <>
            <div className={`detail-body-subsection`} id={sectionData.sectionId}>
                <div className={`row ${forPrint ? "detail-body-subsection--print" : null}`}>
                    {
                        Object.keys(sectionData).map(key => {
                            if (key === "sectionId" ||
                                key === "sectionTitle" ||
                                key === "subSection" ||
                                key === "hasContent" ||
                                key === "uuid" ||
                                forPrint && key === "createdAt"  ||
                                forPrint && key ===  "updatedAt"
                            ) {
                                return null
                            }
                            return (
                                <>
                                    {
                                        !sectionData[key] && forPrint ?
                                            null :
                                            sectionData[key] instanceof Array && !sectionData[key].length && forPrint ?
                                                null :
                                                <div className={`detail-body-subsection__item ${forPrint ? "detail-body-subsection__item--print" : "col-md-6"}`}
                                                    key={key}
                                                >
                                                    <div className={`detail-body-subsection__item__title 
                                                        ${forPrint ? "detail-body-subsection__item__title--print" : null}`
                                                    }>
                                                        {lodash.startCase(key)}
                                                    </div>
                                                    <div className={`detail-body-subsection__item__content
                                                    ${forPrint ? "detail-body-subsection__item__content--print" : null}`
                                                    }>
                                                        {
                                                            sectionData[key] instanceof Array ?
                                                                <div className="detail-body-subsection__item__content__list">
                                                                    {sectionData[key].map((item, index) => {
                                                                        return (
                                                                            <div className="detail-body-subsection__item__content__list__single-list" key={index}>
                                                                                {item.label}
                                                                            </div>)
                                                                    })}
                                                                </div>
                                                                :
                                                                sectionData[key]
                                                        }
                                                    </div>
                                                </div>
                                    }
                                </>
                            )
                        })
                    }
                </div>
            </div>
            {subSection && <span className={`detail-body-subsection__separator ${forPrint ? "detail-body-subsection__separator--print" : null}`}></span>}
        </>
    )
}

export default DetailBodySubsection;