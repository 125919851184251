import React from 'react';
import { Switch, Route, Redirect} from 'react-router-dom';

import PublicHeader from '../public-header/public-header.componenet';
import PublicFooter from '../public-footer/public-footer.componenet';
import LogIn from '../../../pages/log-in/log-in.component';
import PublicRoute from '../public-route/public-route.component';

const PublicLayout = () => <div>
    <PublicHeader />
        <Switch>
            <PublicRoute exact path="/" component={ LogIn } />
        </Switch>
    <PublicFooter />
</div>

export default PublicLayout;