import navigationActionsTypes from "./navigation.types"

export const setSupplierDropdownHidden = () => ({
    type: navigationActionsTypes.SET_SUPPLIER_NAV
})

export const resetNav = () => ({
    type: navigationActionsTypes.RESET_NAV
})

export const setBurgerHidden = () => ({
    type: navigationActionsTypes.SET_BURGER_MENU
})

export const setWindowWidth = (width) => ({
    type: navigationActionsTypes.SET_WINDOW_WIDTH,
    payload: width
});