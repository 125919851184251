
import { useState, useEffect } from "react";
import { Axios } from "../config";
import { getAuthToken } from '../utils/auth_token.utils'

const useFetchDefault = (
    endpoint,
    authTokenFromState,
    hasDefaultValue
) => {
    const [defaultData, setDefaultData] = useState([]);
    const [defaultError, setDefaultError] = useState(null);
    const [isDefaultLoading, setIsDefaultLoading] = useState(false);

    const tokenHeader = getAuthToken(authTokenFromState);
    const fetchData = async () => {
        try {
            setIsDefaultLoading(true);
            const response = await Axios.get(endpoint, {
                headers: tokenHeader
            })

            setDefaultData(response.data);
            setIsDefaultLoading(false);

        } catch (error) {
            if (error.response) {
                if (error.response.status === 400
                    || error.response.status === 401
                    || error.response.status === 404
                ) {
                    setDefaultError({
                        name: error.response.data.name,
                        message: error.response.data.message,
                        status: error.response.status
                    })
                } else {
                    setDefaultError({
                        name: "system error",
                        message: "Please, try again after few moments. If you still can’t get the result your are looking for, contact your administrator."
                    })
                }
            }
            else if (error.request) {
                setDefaultError({
                    name: "network error",
                    message: "Please, make sure you are connected to the network. If you still can’t get the result your are looking for, contact your administrator."
                })
            }
            else {
                setDefaultError({
                    name: "unknown error",
                    message: "An unknown error occurred, please contact your administrator."
                })
            }

            setIsDefaultLoading(false);
            setDefaultData([]);
        }
    }

    useEffect(() => {
        if (hasDefaultValue) {
            fetchData();
        }
    }, []);

    return {
        defaultData,
        defaultError,
        isDefaultLoading
    };
}

export default useFetchDefault;