import { object } from "yup";

export const isCollectionValuesNull = (collection) => {
    const isCollectionValuesNotNull = collection.map(object => {
        const temp = Object.keys(object).every(key => {
            return object["value"] === ""
        })
        return temp
    }).includes(false);

    return !isCollectionValuesNotNull;
}

export const getCollectionHasValue = (collection) => {
    const filedHasValue = collection.filter(object => (
        object.value !== ""
    ))

    if (filedHasValue.length < 1) {
        return null
    }

    return filedHasValue;
}

export const isObjectsEqual = (object1, object2) => {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
        return false;
    } else if (keys1.length === 0 && keys2.length === 0) {
        return true;
    }

    for (const key of keys1) {
        if (object1[key] !== object2[key]) {
            return false;
        }
    }

    return true
}

export const removeEmptyValuedObjects = (collection) => {
    const collectionWithValuedObjects = collection.filter(object => (
        !(Object.keys(object).every(key => {
            return object[key] === undefined
        }))
    ));
    return collectionWithValuedObjects;
}