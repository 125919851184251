import { format } from 'date-fns';
import FormTableCalculateDisplay from '../../../components/form-table-calculate/form-table-calculate.component';
import FormTableHeaderCell from '../../../components/form-table-header-cell/form-table-header-cell.component';
import FormTableInlineDelete from '../../../components/form-table-inline-delete/form-table-inline-delete.component';
import FormTableSelect from '../../../components/form-table-select/form-table-select.component';
import FormTableTextField from '../../../components/form-table-text-field/form-table-text-field.component';

export const ITEM_TAX_INFO = {
  formTitle: "Item Tax",
  formTitleForNew: "New Item Tax",
  path: "/itemtaxes",
  listPage: "/item-taxes",
}

export const ITEM_TAX_FORM_TEMPLATE = {
  bodySections: {
    basic: {
      sectionType: "basic",
      sectionTypeId: "BASIC",
      expandable: false,
      breakPoint: 2,
      fieldName: "itemTax",
      fieldItems: [
        {
          label: "Item Tax Name",
          type: "text",
          name: "itemTax",
          initialFocus: false,
          validationProps: {
            required: "Item tax is required"
          }
        },
        {
          label: "Set as Default",
          type: "checkbox",
          name: "itemTaxDefault",
          initialFocus: false
        },
        {
          label: "Tax Rate (%)",
          type: "text",
          name: "taxRate",
          initialFocus: false,
          validationProps: {
            required: "Item tax rate is required"
          }
        }
      ]
    }
  },
  watchFields: []
}
