import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { selectReportColumn, selectReportInfo } from "../../redux/report/report.reselect"
import ReportLineChart from "../report-line-chart/report-line-chart.componenet"
import ReportTable from "../report-table/report-table.component"
import "./report-body.styles.scss"

const ReportBody = ({
    reportColumn,
    endpoint,
    dataKeys,
    reportInfo,
    hasNoDate
}) => {


    return (
        <div className="report-body">
            <div className="report-body__wrapper report-body__table">
                <ReportTable
                    reportColumn={reportColumn}
                    endpoint={endpoint}
                    hasNoDate={hasNoDate}
                />
            </div>
            {
                reportInfo && reportInfo.hasChart &&
                <div className="report-body__wrapper mt-4 report-body__chart">
                    <ReportLineChart
                        dataKeys={dataKeys}
                    />
                </div>
            }
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    reportInfo: selectReportInfo
})

export default connect(mapStateToProps)(ReportBody);
