import react from 'react';

import './form-error.styles.scss';

const FormError = ({errorMessage}) => (
    <div className='form-error'>
        { errorMessage }
    </div>
)

export default FormError;