import "./table-count-cell.styles.scss"

const TableCountCell = ({ count, hasAlert }) => {
    return (
        <>
            <span className="table-count-cell">
                {count}

            </span>
            {
                hasAlert &&
                <span className="table-count-cell--alert"> </span>
            }
        </>
    )
}

export default TableCountCell;