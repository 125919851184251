const listActionTypes = {
    SET_LIST_TYPE: "SET_LIST_TYPE",
    SET_SELECTED_ROWS_UUID: "SET_SELECTED_ROWS_UUID",
    SET_LOADED_DATA: "SET_LOADED_DATA",
    SET_SCROLL_POSITION: "SET_SCROLL_POSITION",
    SET_TOTAL_NUM_ITEMS: "SET_TOTAL_NUM_ITEMS",
    FETCH_ITEMS_START: "FETCH_ITEMS_START",
    FETCH_ITEMS_SUCCESS: "FETCH_ITEMS_SUCCESS",
    FETCH_ITEMS_FAILURE: "FETCH_ITEMS_FAILURE",
    SET_FETCHED_ITEM: "SET_FETCHED_ITEM",
    REMOVE_EDITED_ITEM: "REMOVE_EDITED_ITEM",
    REST_LIST: "REST_LIST",
    SET_PAGE_PATH: "SET_PAGE_PATH",
    SET_DEACTIVATE_CHECK: "SET_DEACTIVATE_CHECK"
}

export default listActionTypes;
