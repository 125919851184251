export const LOCAL_FILTER_FIELDS = [
    {
        label: "PO Number",
        type: "text",
        name: "purchaseOrderNumber",
    },
    {
        label: "Supplier",
        type: "text",
        name: "supplierName",
    },
    {
        label: "Location",
        type: "text",
        name: "locationCode",
    },
    {
        label: "Status",
        type: "text",
        name: "statusName",
    }
]