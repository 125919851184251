import React from "react";
import { connect } from "react-redux";
import { setEditData, setModalTemplate, setModalType, setShowEditLocationModal } from '../../redux/modal/modal.actions';
import { createStructuredSelector } from 'reselect';
import { selectEditUUID, selectModalFormTemplate, selectShowEditLocationModal } from "../../redux/modal/modal.reselect";
import { useEffect } from "react";

import EDIT_LOCATION_FORM_TEMPLATE from './edit-location.template';

import ModalContainer from "../modal/modal.container";
import useFetchEdit from "../../effects/use-fetch-edit.effect";
import { authTokenSelector } from "../../redux/user/user.reselect";

const EditLocation = ({
    showEditLocation,
    setModalTemplate,
    setModalType,
    formTemplate,
    editUUID,
    authToken,
    setShowEditLocationModal,
    isFetching,
    setEditData
}) => {
    useEffect(() => {
        setModalType("EDIT_LOCATION")
        setModalTemplate(EDIT_LOCATION_FORM_TEMPLATE);
    }, []);
    const endpoint = '/locations';
    const endpointUrl = `${endpoint}/${editUUID}`;
    const authTokenFromState = authToken;

    const { data, error } = useFetchEdit(endpointUrl, authTokenFromState, showEditLocation);

    useEffect(() => {
        setEditData(data)
    })
    
    return (
        <div className="edit-item-group">
            {
                Object.keys(formTemplate).length > 0 ?
                    <ModalContainer
                        showModal={showEditLocation}
                        setShowModal={setShowEditLocationModal}
                        endpointUrl={endpointUrl}
                        size={"Large"}
                    />
                    : null
            }
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    showEditLocation: selectShowEditLocationModal,
    formTemplate: selectModalFormTemplate,
    editUUID: selectEditUUID,
    authToken: authTokenSelector,
})

const mapDispatchToProps = (disptach) => ({
    setShowEditLocationModal: () =>
        disptach(setShowEditLocationModal()),
    setModalTemplate: (formTemplate) =>
        disptach(setModalTemplate(formTemplate)),
    setModalType: (modalType) =>
        disptach(setModalType(modalType)),
    setEditData: (editData) =>
        disptach(setEditData(editData))
})


export default connect(mapStateToProps, mapDispatchToProps)(EditLocation);