export const LOCAL_FILTER_FIELDS = [
    {
        label: "WO Number",
        type: "text",
        name: "workOrderNumber",
    },
    {
        label: "Location",
        type: "text",
        name: "locationCode",
    }
]