import React, { useRef } from "react";

import { useEffect } from "react";
import { useState } from "react";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import DocumentHeader from "../../components/document-header/document-header.componenet";
import DocumentBody from "../../components/document-body/document-body.componenet";
import { PURCHASE_INVOICES_COLUMNS_DOCUMENT } from "./purchase-invoice-columns-document.data";
import useFetchDocument from "../../effects/use-fetch-document";
import { withRouter } from "react-router-dom";
import { authTokenSelector } from "../../redux/user/user.reselect";
import { selectIsRefresh } from "../../redux/refresh/refresh.reselect";

const PurchaseInvoiceDocument = ({
    authTokenFromState,
    match,
    isRefresh
}) => {
    const contentRef = useRef(null);
    const title = "Purchase Invoice"
    const path = "/purchaseorders";
    const subPath = "/invoices/doucments"

    const endpoint = `${path}/${match.params.uuid}${subPath}`;
    const { data, error, isLoading } = useFetchDocument(
        endpoint,
        authTokenFromState,
        isRefresh
    );

    const [dataIsReady, setDataIsReady] = useState(false);
    const [dataForDoucment, setDataForDoucment] = useState(null);
    useEffect(() => {
        setDataForDoucment({
            header: [
                data.company,
                data.purchaseOrderSummary
            ],
            body: {
                sections: [
                    data.supplierSummary,
                    data.shipToAddress,
                    data.invoicedDate,
                    data.invoiceNumber
                ],
                table: data.items,
                total: data.total,
                totalBalance: data.totalBalance
            }
        })
        setDataIsReady(true)
    }, [data])

    return (
        <>{dataIsReady &&
            <>
                <DocumentHeader
                    title={title}
                    contentRef={contentRef}
                />
                <DocumentBody
                    title={title}
                    endpoint={endpoint}
                    contentRef={contentRef}
                    data={dataForDoucment}
                    tableColumns={PURCHASE_INVOICES_COLUMNS_DOCUMENT}
                    tableData={data.items}
                    error={error}
                    isLoading={isLoading}
                    totalBalance={data.totalBalance}
                />
            </>
        }

        </>
    )
}

const mapStateToProps = createStructuredSelector({
    authTokenFromState: authTokenSelector,
    isRefresh: selectIsRefresh,
});

export default withRouter(connect(mapStateToProps, null)(PurchaseInvoiceDocument));
