import React from 'react';

import './log-in-custom-checkbox.styles.scss';

const LogInCustomCheckbox = ({ label, handleChange, ...otherProps }) => (
    <div className="log-in-custom-checkbox">
        <input 
            className='log-in-custom-checkbox__input' 
            type="checkbox" 
            id="log-in-checkbox"
            onChange={ handleChange } 
            {...otherProps} 
        />
        <label htmlFor="log-in-checkbox" className='log-in-custom-checkbox__label'>{ label }</label>
    </div>
);

export default LogInCustomCheckbox;