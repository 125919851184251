import "./document-body-section.styles.scss"
import lodash from 'lodash';
import { useEffect } from "react";
import { useState } from "react";

const DocumentBodySection = ({
    sectionData,
    isSmall
}) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        window.addEventListener("resize", () => {
            setWindowWidth(window.innerWidth);
        })

        return () => {
            window.removeEventListener("resize", () => {
                setWindowWidth(window.innerWidth);
            })
        }
    }, [])

    return (
        <div className={`document-body-section  ${windowWidth < 991 ? "w-100" : isSmall ? "w-25" : "w-50"}`}>
            <h4 className={`document-body-section__title`}>
                {sectionData.title}
            </h4>
            <ul className="document-body-section__list">
                {
                    sectionData.content.map((value, index) => {
                        if (value && typeof value === "object") {
                            return (
                                <ul className="document-body-section__list mt-3" key={index}>
                                    {Object.keys(value).map((key, index) => {
                                        if (value[key]) {
                                            return (
                                                <li className="document-body-section__list__title-text" key={index}>
                                                    <span className="document-body-section__list__title-text__title">{lodash.startCase(key)}: </span>
                                                    <span className="document-body-section__list__title-text__text">{value[key]}</span>
                                                </li>
                                            )
                                        }
                                    })}
                                </ul>
                            )
                        }
                        return <li>{value}</li>
                    })
                }
            </ul>
        </div>
    )
}

export default DocumentBodySection