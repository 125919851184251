import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { setShowAddItemGroupModal, setShowAddItemTaxModal } from '../../redux/modal/modal.actions';

import ListTableBody from '../../components/list-table-body/list-table-body.componenet';
import ListHeader from '../../components/list-header/list-header.component';

import { ITEM_TAXES_COLUMNS } from './columns-item-taxes.data';
import { LOCAL_FILTER_FIELDS } from './local-filter-filters-item-taxes.data';
import { useEffect } from 'react';
import { resetList, setListType, setLoadedData } from '../../redux/list/list.actions';
import { setDetailPagePath } from '../../redux/detail/detail.actions';
import { setFilterEmpty } from '../../redux/filter/filter.actions';
import { selectListType } from '../../redux/list/list.reselect';

const LIST_TYPE = "LIST_ITEM_GROUPS";

const TYPE = {
    LIST_ITEM_TAX: "LIST_ITEM_TAX",
    EDIT_ITEM_TAX: "EDIT_ITEM_TAX"
}

const ListItemGroups = ({
    setShowAddItemTaxModal,
    setListType,
    setLoadedData,
    setModalType,
    setDetailPagePath,
    setFilterEmpty,
    listType
}) => {
    const endpoint = "/itemtaxes";

    useEffect(() => {
        if (listType !== TYPE.LIST_ITEM_TAX) {
            setLoadedData({ data: null, skip: null, totalNumItems: 0 });
            setFilterEmpty();
        }

        setListType(TYPE.LIST_ITEM_TAX);
        setDetailPagePath("/detail-item-tax")
    }, [])
    
    return (
        <>
            <ListHeader
                buttonAction={setShowAddItemTaxModal}
                buttonActionLabel={"Add Item Tax"}
                endpoint={endpoint}
                title={"Item Taxes"}
            />
            <ListTableBody
                tableColumns={ITEM_TAXES_COLUMNS}
                localFilterFields={LOCAL_FILTER_FIELDS}
                endpoint={endpoint}
            />
        </>
    )
};

const mapStateToProps = createStructuredSelector({
    listType: selectListType,
})

const mapDispatchToProps = (dispatch) => ({
    setShowAddItemTaxModal: () =>
        dispatch(setShowAddItemTaxModal()),
    setListType: (listType) =>
        dispatch(setListType(listType)),
    setLoadedData: (data) =>
        dispatch(setLoadedData(data)),
    setDetailPagePath: (pagePath) =>
        dispatch(setDetailPagePath(pagePath)),
    setFilterEmpty: () =>
        dispatch(setFilterEmpty())
})
export default connect(mapStateToProps, mapDispatchToProps)(ListItemGroups)