import React, { useRef, useMemo } from 'react';
import {
    useTable,
    useResizeColumns,
    useBlockLayout,
    useSortBy,
    useRowSelect,
    useGlobalFilter
} from 'react-table';

import './detail-table.styles.scss';

import IconArrowUpward from '../../assets/icons/arrow_upward.svg';
import IconArrowDownward from '../../assets/icons/arrow_downward.svg';


import { getObjectsOnlyFromArray } from '../../utils/object-to-array';

const DetailTable = ({
    sectionData,
    detailTableColumns
}) => {
    const columns = useMemo(() => detailTableColumns, []);
    const defaultColumn = React.useMemo(
        () => ({
            minWidth: 30,
            width: 140,
            maxWidth: 400
        }),
        []
    );

    const dataFilterd = getObjectsOnlyFromArray(sectionData)

    const data = useMemo(() => dataFilterd, []);

    const tableMethods2 = useTable({
        columns,
        data,
        defaultColumn
    },
        useGlobalFilter,
        useResizeColumns,
        useBlockLayout,
        useSortBy,
        useRowSelect
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,

    } = tableMethods2;

    return (
        <div className="detail-table-container">
            <table className="detail-table" {...getTableProps()} >
                <thead className="detail-table__header">
                    {headerGroups.map(headerGroup => (
                        <tr className="detail-table__header__group" {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th
                                    className="detail-table__header__item"
                                    {...column.getHeaderProps([

                                        {
                                            className: column.className,
                                            style: column.style
                                        }
                                    ])}
                                >
                                    <span {...column.getSortByToggleProps()}>
                                        {column.render('Header')}
                                    </span>
                                    <span className="">
                                        {column.isSorted ?
                                            column.isSortedDesc ?
                                                <img
                                                    src={IconArrowDownward}
                                                    className="detail-table__header__item__sort__icon"
                                                /> :
                                                <img
                                                    src={IconArrowUpward}
                                                    className="detail-table__header__item__sort__icon"
                                                />
                                            : ''}
                                    </span>
                                    <span style={{ border: '3px solid coral', width: '3px', height: '40px' }}
                                        {...column.getResizerProps()}
                                        className={`detail-table__header__item__resizer 
                                                ${column.isResizing ?
                                                "detail-table__header__item__resizer--isResizing" : ""
                                            }`}
                                    />
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody className="detail-table__body" {...getTableBodyProps()}>
                    {rows.map(row => {
                        prepareRow(row)
                        return (
                            <tr className="detail-table__body__row" {...row.getRowProps()}>
                                {row.cells.map(cell => (
                                    <td
                                        className="detail-table__body__row__cell"
                                        {...cell.getCellProps([
                                            {
                                                className: cell.column.className,
                                                style: cell.column.style
                                            }
                                        ])}
                                        data-label={cell.render('Header')}
                                    >
                                        <span className="detail-table__body__row__cell__header">
                                            {cell.render('Header')}
                                        </span>
                                        <span className="detail-table__body__row__cell__data">
                                            {cell.render('Cell')}
                                        </span>
                                    </td>
                                ))}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
};

export default DetailTable;