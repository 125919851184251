import { Axios } from "../config";
import { getAuthToken } from '../utils/auth_token.utils'

const fetchSelect = async (endpointUrl, authTokenFromState, inputValue) => {
    const tokenHeader = getAuthToken(authTokenFromState);
    try{
        const response = await Axios.get(endpointUrl,{
            headers: tokenHeader,
            params: {
                inputValue
            }
        })
    
        return response.data
    } catch(error){
        if (error.response) {
            if (error.response.status === 400
                || error.response.status === 401
                || error.response.status === 404
            ) {
                return {
                    name: error.response.data.name,
                    message: error.response.data.message,
                    status: error.response.status
                }
            } else {
                return {
                    name: "system error",
                    message: "Please, try again after few moments. If you still can’t get the result your are looking for, contact your administrator."
                }
            }
        }
        else if (error.request) {
            return {
                name: "network error",
                message: "Please, make sure you are connected to the network. If you still can’t get the result your are looking for, contact your administrator."
            }
        }
        else {
            return {
                name: "unknown error",
                message: "An unknown error occurred, please contact your administrator."
            }
        }
    }
}

export default fetchSelect 
