import { format } from 'date-fns';
import { 
    displayCellValue, 
    displayCellValueNumberToTwoDecimal 
} from '../../../utils/column-values.util';

export const ITEM_DETAIL_COLUMNS = [
    {
        id: "count",
        Cell: ({ row }) => Number(row.id) + 1,
        width: 50,
    },
    {
        Header: "Location",
        accessor: "locationCode",
        style: {
            position: "sticky",
            left: 0,
            top: 0,
            zIndex: 2
        },
        Cell: ({ value, row }) => {
            return displayCellValue(value);
        }
    },
    {
        Header: "Beginning Qty",
        accessor: "beginningQty",
        Cell: ({ value }) => {
            return displayCellValueNumberToTwoDecimal(value);
        }
    },
    {
        Header: "Stock In",
        accessor: "stockIn",
        Cell: ({ value }) => {
            return displayCellValueNumberToTwoDecimal(value);
        }
    },
    {
        Header: "Stock Out",
        accessor: "stockOut",
        Cell: ({ value }) => {
            return displayCellValueNumberToTwoDecimal(value);
        }
    },
    {
        Header: "Qty on Hand",
        accessor: "qtyOnHand",
        Cell: ({ value }) => {
            return displayCellValueNumberToTwoDecimal(value);
        }
    },
    {
        Header: "Qty Available",
        accessor: "qtyAvailable",
        Cell: ({ value }) => {
            return displayCellValueNumberToTwoDecimal(value);
        }
    },
    {
        Header: "Qty on PO",
        accessor: "qtyOnPO",
        Cell: ({ value }) => {
            return displayCellValueNumberToTwoDecimal(value);
        }
    },
    {
        Header: "Qty Transfer",
        accessor: "qtyTransfer",
        Cell: ({ value }) => {
            return displayCellValueNumberToTwoDecimal(value);
        }
    }
]