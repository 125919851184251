import { createSelector } from "reselect";

const form = (state) => state.form;

// export const selectShowEditSupplierForm = createSelector(
//     [form],
//     form => form.showEditSupplier
// );

// export const selectShowAddPurchaseOrderForm = createSelector(
//     [form],
//     form => form.showAddPurchaseOrder
// );

export const selectEditUUID = createSelector(
    [form],
    form => form.editUUID
);

export const selectFormEdit = createSelector(
    [form],
    form => form.formEdit
);

export const selectFormTableData = createSelector(
    [form],
    form => form.tableData
);

export const selectFormTableLatestData = createSelector(
    [form],
    form => form.tableLatestData
);

export const selectFormTableLatestRows = createSelector(
    [form],
    form => form.tableLatestRows
);

// export const selectFormTableRows = createSelector(
//     [selectFormTableLatestData],
//     tableLatestData => tableLatestData.items && [...tableLatestData.items]
// );

export const selectRemoveTableRow = createSelector(
    [form],
    form => form.removeTableRow
);

export const selectEditData = createSelector(
    [form],
    form => form.editData
);

export const selectAutoFillDataForm = createSelector(
    [form],
    form => form.autoFillDataForm
);

export const selectFormIsFetching = createSelector(
    [form],
    form => form.isFetching
);

export const selectFormExpandSection = createSelector(
    [form],
    form => form.formExpandSection
);

export const selectFormInfo = createSelector(
    [form],
    form => form.formInfo
);

export const selectFormType = createSelector(
    [form],
    form => form.formType
);

export const selectFormTemplate = createSelector(
    [form],
    form => form.formTemplate
);

export const selectFormValue = createSelector(
    [form],
    form => form.formValues
)

export const selectFormTotalValues = createSelector(
    [form],
    form => form.formTotalValues
);

export const selectFormChangedValues = createSelector(
    [form],
    form => form.formChangedValues
);

export const selectFormErrors = createSelector(
    [form],
    form => form.errorMessage
)

export const selectFormValidationErrors = createSelector(
    [form],
    form => form.formValidationErrors
)

export const selectIsLoading = createSelector(
    [form],
    form => form.isLoading
)

export const selectIsSaved = createSelector(
    [form],
    form => form.isSaved
);

export const selectIsFail = createSelector(
    [form],
    form => form.isFail
);

export const selectErrorMessage = createSelector(
    [form],
    form => form.errorMessage
)

export const selectWindowHeight = createSelector(
    [form],
    form => form.windowHeight
)

export const selectIsEditByFullForm = createSelector(
    [form],
    form => form.isEditByFullForm
)

export const selectSelectedField = createSelector(
    [form],
    form => form.selectedField
)

export const selectSelectedFieldValue = createSelector(
    [form],
    form => form.selectedFieldValue
)

export const selectFormSideBarNav = createSelector(
    [form],
    form => form.formSideBarNav
)

export const selectFormSideBarNavActiveItem = createSelector(
    [form],
    form => form.formSideBarNavActiveItem
)

export const selectFormProgress= createSelector(
    [form],
    form => form.formProgress
)

export const selectValueEntredBlur= createSelector(
    [form],
    form => form.valueEntredBlur
)





