const ADD_SUPPLIER_FORM_TEMPLATE = {
  modalTitle: "New Supplier",
  path: "/suppliers",
  listPage: "/list-suppliers",
  bodySections: {
    basic: {
      sectionType: "basic",
      sectionTypeId: "basic",
      expandable: false,
      breakPoint: 0,
      fieldName: "supplier",
      fieldItems: [
        {
          label: "Supplier Code",
          type: "text",
          name: "supplierCode",
          initialFocus: true,
          validationProps: {
            required: "Supplier Code is required"
          }
        },
        {
          label: "Supplier name",
          type: "text",
          name: "supplierName",
          required: true,
          initialFocus: false,
          validationProps: {
            required: "Supplier Name is required"
          }
        },
        {
          label: "Supplier TIN number",
          type: "text",
          name: "supplierTinNumber",
          required: true,
          initialFocus: false,
          validationProps: {
            required: "Supplier TIN Number is required"
          }
        },
        {
          label: "Supplier Group",
          type: "select",
          name: "SupplierGroups",
          required: false,
          initialFocus: false,
          muliselect: true,
          url: "/suppliergroups"
        }
      ]
    },
    contactDetails: {
      sectionType: "contact details",
      sectionTypeId: "contactDetails",
      multiForm: true,
      mutliFormTitle: "Contact",
      expandable: true,
      addButton: true,
      breakPoint: 3,
      fieldName: "supplierContact",
      fieldItems: [
        {
          label: "contact name",
          type: "text",
          name: "contactName",
          required: false,
          initialFocus: false
        },
        {
          label: "phone no 1",
          type: "text",
          name: "phoneNumber1",
          required: false,
          initialFocus: false,
          break: true
        },
        {
          label: "phone no 2",
          type: "text",
          name: "phoneNumber2",
          required: false,
          initialFocus: false
        },
        {
          label: "email",
          type: "text",
          name: "email",
          required: false,
          initialFocus: false,
          validationProps: {
            pattern: {
              value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: 'Valid email required on contact details',
            }
          }
        }
      ]
    },
    addressDetails: {
      sectionType: "address details",
      sectionTypeId: "addressDetails",
      multiForm: true,
      mutliFormTitle: "address",
      expandable: true,
      addButton: true,
      breakPoint: 5,
      fieldName: "supplierAddress",
      fieldItems: [
        {
          label: "street",
          type: "textarea",
          name: "street",
          required: false,
          initialFocus: false
        },
        {
          label: "city",
          type: "text",
          name: "city",
          required: false,
          initialFocus: false
        },
        {
          label: "state",
          type: "text",
          name: "state",
          size: "small",
          required: false,
          initialFocus: false
        },
        {
          label: "zip code",
          type: "text",
          name: "zipCode",
          size: "small",
          required: false,
          initialFocus: false
        },
        {
          label: "country",
          type: "text",
          name: "country",
          required: false,
          initialFocus: false
        },
        {
          label: "phone no 1",
          type: "text",
          size: "small",
          name: "phoneNumber1",
          required: false,
          initialFocus: false
        },
        {
          label: "phone no 2",
          type: "text",
          size: "small",
          name: "phoneNumber2",
          required: false,
          initialFocus: false
        },
        {
          label: "email",
          type: "text",
          name: "email",
          required: false,
          initialFocus: false,
          validationProps: {
            pattern: {
              value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: 'Valid email required on payment address details',
            }
          }
        },
        {
          label: "website",
          type: "text",
          name: "website",
          required: false,
          initialFocus: false
        },
        {
          label: "Address Type",
          type: "select",
          name: "SupplierAddressTypes",
          required: false,
          initialFocus: false,
          url: "/supplieraddresstypes"
        }
      ]
    },
    bankAccountDetails: {
      sectionType: "bank account details",
      sectionTypeId: "bankAccountDetails",
      multiForm: true,
      mutliFormTitle: "Bank Account",
      expandable: true,
      addButton: true,
      position: "LAST",
      breakPoint: 2,
      fieldName: "supplierBankAccount",
      fieldItems: [
        {
          label: "bank name",
          type: "text",
          name: "bankName",
          required: false,
          initialFocus: false
        },
        {
          label: "account name",
          type: "text",
          name: "accountName",
          required: false,
          initialFocus: false
        },
        {
          label: "account number",
          type: "text",
          name: "accountNumber",
          required: false,
          initialFocus: false
        }
      ]
    }
  }
}

export default ADD_SUPPLIER_FORM_TEMPLATE;