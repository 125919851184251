import './table-inline-action-button.styles.scss';
import IconActionDown from '../../assets/icons/action-down.svg'

const TableInlineActionButton = ({ handleClickDropDownBtn, reference }) => (
    <button className='table-inline-action-button' onClick={handleClickDropDownBtn} ref={reference}>
        <img src={IconActionDown} className='table-inline-action-button__icon' />
    </button>
)


export default TableInlineActionButton;