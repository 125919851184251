import { format } from 'date-fns';
import FormTableCalculateDisplay from '../../../components/form-table-calculate/form-table-calculate.component';
import FormTableHeaderCell from '../../../components/form-table-header-cell/form-table-header-cell.component';
import FormTableInlineDelete from '../../../components/form-table-inline-delete/form-table-inline-delete.component';
import FormTableSelect from '../../../components/form-table-select/form-table-select.component';
import FormTableTextField from '../../../components/form-table-text-field/form-table-text-field.component';

export const PURCHASE_ORDER_INFO = {
  formTitle: "Purchase Order",
  formTitleForNew: "New Purchase Order",
  path: "/purchaseorders",
  listPage: "/purchase-orders",
}

export const PURCHASE_ORDER_FORM_TEMPLATE = {
  bodySections: {
    basic: {
      sectionType: "basic",
      sectionTypeId: "BASIC",
      expandable: false,
      breakPoint: 2,
      fieldName: "purchaseOrder",
      fieldItems: [
        {
          label: "PO Number",
          type: "text",
          name: "purchaseOrderNumber",
          initialFocus: false,
          disabled: true,
          defaultValue: `PO-`
        },
        {
          label: "Supplier",
          type: "select",
          name: "Supplier",
          required: false,
          initialFocus: false,
          muliselect: false,
          url: "/suppliers",
          // validationProps: {
          //   required: "Supplier is required"
          // }
        },
        // {
        //   label: "Assign To",
        //   type: "select",
        //   name: "User",
        //   required: false,
        //   initialFocus: false,
        //   muliselect: false,
        //   url: "/users"
        // },
        {
          label: "PO Date",
          type: "date",
          name: "purchaseOrderDate",
          initialFocus: false,
          validationProps: {
            required: "PO date is required"
          }
        },
        {
          label: "Required Date",
          type: "date",
          name: "requiredDate",
          initialFocus: false,
        },
      ]
    },
    shippingDetails: {
      sectionType: "Shipping details",
      sectionTypeId: "shippingDetails",
      multiForm: false,
      mutliFormTitle: "Shipping",
      expandable: true,
      isExpand: false,
      addButton: true,
      breakPoint: 1,
      fieldName: "shipping",
      fieldItems: [
        {
          label: "Location",
          type: "select",
          name: "Location",
          initialFocus: false,
          muliselect: false,
          url: "/locations",
        },
        {
          label: "Shipping Cost (ETB)",
          type: "text",
          name: "shippingCost",
          initialFocus: false,
        },
      ]
    },
    statusDetails: {
      sectionType: "Status details",
      sectionTypeId: "statusDetails",
      multiForm: false,
      mutliFormTitle: "Status",
      expandable: true,
      showEditOnly: true, 
      isExpand: false,
      addButton: true,
      breakPoint: 1,
      fieldName: "status",
      fieldItems: [
        {
          label: "Current Status",
          type: "select",
          name: "PurchaseOrderStatus",
          initialFocus: false,
          muliselect: false,
          defaultValue: { label: 'unfulfilled', value: 1 },
          url: "/purchaseorderstatus"
        }
      ]
    },
    itemTaxDetails: {
      sectionType: "Item tax details",
      sectionTypeId: "itemTaxDetails",
      multiForm: false,
      expandable: true,
      isExpand: false,
      addButton: true,
      breakPoint: 1,
      fieldName: "itemTax",
      fieldItems: [
        {
          label: "Item Tax",
          type: "select",
          name: "ItemTax",
          initialFocus: false,
          muliselect: false,
          hasDefaultValue: true, 
          defaultValueUrl: "/itemtaxesdefault",
          url: "/itemtaxes"
        }
      ]
    },
    tableForm: {
      sectionType: "Items",
      sectionTypeId: "tableForm",
      fieldName: "items",
      isDisable: false,
      tableColumns: [
        {
          Header: "No.",
          id: "count",
          Cell: ({ row }) => Number(row.id) + 1,
          width: 50,
        },
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"Product Code/Name"}
                required={true}
              />
            )
          },
          accessor: "item",
          width: 200,
          Cell: ({ row }) => {
            const endpointUrl = "/items";
            const validationProps = {
              required: "Product Code/Name is required"
            }

            return (<FormTableSelect
              fieldName={"items"}
              fieldItemName={"Item"}
              rowNumber={row.id}
              endpointUrl={endpointUrl}
              validationProps={validationProps}
              disabled={row.isDisable}
            />)
          }
        },
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"Qty"}
                required={true}
              />
            )
          },
          accessor: "qty",
          width: 80,
          Cell: ({ row }) => {
            const validationProps = {
              required: "Qty is required"
            }

            return (<FormTableTextField
              fieldName={"items"}
              fieldItemName={"qty"}
              rowNumber={row.id}
              valueType={"number"}
              validationProps={validationProps}
              align="right"
              disabled={row.isDisable}
            />)
          }
        },
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"UoM"}
                required={true}
              />
            )
          },
          accessor: "unitOfMeasure",
          width: 120,
          Cell: ({ row }) => {
            const endpointUrl = "/unitofmeasures";
            const validationProps = {
              required: "UoM is required"
            }

            return (<FormTableSelect
              fieldName={"items"}
              fieldItemName={"UnitOfMeasure"}
              rowNumber={row.id}
              value={row.value}
              endpointUrl={endpointUrl}
              validationProps={validationProps}
              disabled={true}
            />)
          }
        },
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"Unit Cost (ETB)"}
                required={true}
              />
            )
          },
          accessor: "unitCost",
          width: 190,
          Cell: ({ row, value }) => {
            const validationProps = {
              required: "Unit cost is required"
            }

            return (<FormTableTextField
              fieldName={"items"}
              fieldItemName={"unitCost"}
              rowNumber={row.id}
              valueType={"number"}
              validationProps={validationProps}
              align="right"
              disabled={row.isDisable}
            />)
          }
        },
        {
          Header: "Subtotal (ETB)",
          accessor: "subTotal",
          width: 190,
          Cell: ({ row }) => {
            return (
              <FormTableCalculateDisplay
                fieldName={"items"}
                rowNumber={row.id}
                calculateType="multiply"
                valuesToCalculate={["qty", "unitCost"]}
              />
            )
          }
        },
        {
          id: "action",
          width: 70,
          Cell: ({ row, toggleRowSelected }) => {
            const uuid = row.original.uuid;
            return (<FormTableInlineDelete
              rowNumber={row.id}
              toggleRowSelected={toggleRowSelected}
            />)
          }
        }
      ]
    },
    totalDetails: {
      sectionType: "Total",
      sectionTypeId: "totalDetails",
      multiForm: false,
      expandable: false,
      isExpand: true,
      addButton: true,
      breakPoint: 0,
      fieldName: "total",
      fieldItems: [
        {
          label: "Product Count",
          type: "text",
          name: "itemCount",
          initialFocus: false,
          disabled: true
        },
        {
          label: "Subtotal",
          type: "text",
          name: "subTotal",
          initialFocus: false,
          disabled: true
        },
        {
          label: "Item Tax (ETB)",
          type: "text",
          name: "itemTax",
          initialFocus: false,
          disabled: true
        },
        {
          label: "Shipping Cost (ETB)",
          type: "text",
          name: "shippingCost",
          initialFocus: false,
          disabled: true
        },
        {
          label: "Grand Total (ETB)",
          type: "text",
          name: "grandTotal",
          initialFocus: false,
          disabled: true
        }
      ],
    },
    paymentDetails: {
      sectionType: "Payment details",
      sectionTypeId: "paymentDetails",
      showEditOnly: true, 
      multiForm: false,
      mutliFormTitle: "Payment",
      expandable: true,
      isExpand: true,
      addButton: true,
      breakPoint: 0,
      fieldName: "payment",
      fieldItems: [
        {
          label: "Paid Amount (ETB)",
          type: "text",
          name: "paidAmount",
          initialFocus: false,
        },
        {
          label: "Avaliable Credit (ETB)",
          type: "text",
          name: "availableCreditAmount",
          initialFocus: false,
          disabled: true
        },
        {
          label: "Creidt Applied (ETB)",
          type: "text",
          name: "appliedCreditAmount",
          initialFocus: false,
          disabled: false
        },
        {
          label: "Total Paid Amount (ETB)",
          type: "text",
          name: "totalPaidAmount",
          initialFocus: false,
          disabled: true
        },
        {
          label: "Balance (ETB)",
          type: "text",
          name: "balance",
          initialFocus: false,
          disabled: true
        },
        {
          label: "Refund amount (ETB)",
          type: "text",
          name: "refundAmount",
          initialFocus: false,
          disabled: false
        },
        {
          label: "Issue Credit (ETB)",
          type: "text",
          name: "issuedCreditAmount",
          initialFocus: false,
          disabled: false
        },
      ]
    },
    purchaseInvoiceDetails: {
      sectionType: "Invoice details",
      sectionTypeId: "invoiceDetails",
      multiForm: false,
      mutliFormTitle: "",
      expandable: true,
      isExpand: true,
      addButton: true,
      breakPoint: 1,
      position: "LAST",
      fieldName: "invoice",
      fieldItems: [
        {
          label: "Supplier Invoice Date",
          type: "date",
          name: "invoicedDate",
          initialFocus: false,
        },
        {
          label: "Supplier Invoice Number",
          type: "text",
          name: "invoiceNumber",
          initialFocus: false,
        },
      ]
    },
  },
  watchFields: [
    "shipping.0.shippingCost",
    "itemTax.0.ItemTax",
    "payment.0.paidAmount",
    "payment.0.availableCreditAmount",
    "payment.0.appliedCreditAmount",
    "payment.0.refundAmount",
    "payment.0.issuedCreditAmount"
  ]
}
