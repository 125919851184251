import React, { useRef } from 'react';

import './list-local-filter.styles.scss';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { setFilterInput, setLocalFilterFieldNamesAndValues } from "../../redux/filter/filter.actions";
import { setLocalFilterFieldName } from "../../redux/filter/filter.actions";
import { selectFilterInput, selectLocalFilterFieldNamesAndValues } from "../../redux/filter/filter.reselect";
import { useDebouncedCallback, useDebounce } from "use-debounce"

import LocalFilterField from '../local-filter-filed/local-filter-field.component';
import { useEffect } from 'react';
import { getCollectionHasValue, isCollectionValuesNull, isObjectsEqual, isObjectValuesNull } from '../../utils/object-values.util';
import { useState } from 'react';



const ListLocalFilter = ({
    localFilterFields,
    setLocalFilterFieldNamesAndValues,
    localFilter,
    setFilterInput,
    filterInput
}) => {
    const [mount, setMount] = useState(false)
    const [inputNameAndValue, setInputNameAndValue] = useState({});
    const [value] = useDebounce(inputNameAndValue, 1000);

    useEffect(() => {
        if (Object.keys(localFilter).length !== 0) {
            setInputNameAndValue(localFilter)
        }
        setMount(true)
    }, [])

    useEffect(() => {
        if (mount &&
            !isObjectsEqual(inputNameAndValue, localFilter)
        ) {
            setLocalFilterFieldNamesAndValues(inputNameAndValue);
        }
    }, [value])

    useEffect(() => {
        if (mount && 
            Object.keys(inputNameAndValue).length !== 0 &&
            filterInput !== ''
        ) {
            setFilterInput('')
        }
    }, [value])

    useEffect(() => {
        setInputNameAndValue(localFilter)
    }, [localFilter])

    return (
        <div className="list-table-local-filter">
            <div className="list-table-local-filter__title">
                Filter by
            </div>
            <div className="list-table-local-filter__fields">
                {
                    localFilterFields.map((localFilterField, index) => (
                        <LocalFilterField key={index}
                            {...localFilterField}
                            handleChange={event => setInputNameAndValue({ [event.target.name]: event.target.value })}
                            value={inputNameAndValue ?
                                Object.keys(inputNameAndValue).length ?
                                    inputNameAndValue[localFilterField.name] :
                                    '' :
                                ''}
                        />
                    ))
                }
            </div>
        </div>
    )
};

const mapStateToProps = createStructuredSelector({
    localFilter: selectLocalFilterFieldNamesAndValues,
    filterInput: selectFilterInput,
});

const mapDispatchToProps = (dispatch) => ({
    setLocalFilterFieldNamesAndValues: (inputNameAndValue) => dispatch(setLocalFilterFieldNamesAndValues(inputNameAndValue)),
    setFilterInput: (filterInput) => dispatch(setFilterInput(filterInput))
})

export default connect(mapStateToProps, mapDispatchToProps)(ListLocalFilter);