import { format } from 'date-fns';

import DropDownContainer from '../../components/drop-down-container/drop-down-container.componenet';
import ListActions from '../../components/list-actions/list-actions.componenet';
import { displayCellValueWithPercentile } from '../../utils/column-values.util';

const ENDPOINT = "/itemTaxes";

export const ITEM_TAXES_COLUMNS = [
    {
        id: "count",        
        Cell: ({row}) =>  Number(row.id) + 1,
        width: 50,
    },
    {
        Header: "Item Tax",
        accessor: "itemTax",
        style: {
            position: "sticky",
            left:0,
            top:0,
            zIndex: 2
        }
    },
    {
        Header: "Tax Rate",
        accessor: "taxRate",
        Cell: ({ value }) => {
            return displayCellValueWithPercentile(value);
        }
    },
    {
        Header: "Created At",
        accessor: "createdAt",
        Cell: ({ value }) => (
            format(new Date(value), 'dd/MM/yyyy')
        )
    },
    {
        id: "action",
        width: 70,
        Cell: ({row}) => {
            const uuid = row.original.uuid;
            return (<ListActions 
                uuid={uuid} 
                endpoint={ENDPOINT}
            />)
        }
    }
]