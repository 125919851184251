import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectModalType } from '../../redux/modal/modal.reselect';

import MODAL_TYPE from './modal-type.data';

import AddItemGroup from '../add-item-group/add-item-group.componenet';
import AddSupplierGroup from '../add-supplier-group/add-supplier-group.component';

import AddSupplier from '../add-supplier/add-supplier.component';
import EditItemGroup from '../edit-item-group/edit-item-group.componenet';
import EditSupplierGroup from '../edit-supplier-group/edit-supplier-group.componenet';
import EditSupplier from '../edit-supplier/edit-supplier.componenet';
import AddItemTax from '../add-item-tax/add-item-tax.componenet';
import EditItemTax from '../edit-item-taxes/edit-item-tax.componenet';
import AddUnitOfMeasure from '../add-unit-of-measure/add-unit-of-measure.componenet';
import EditUnitOfMeasure from '../edit-unit-of-measures/edit-unit-of-measures.componenet';
import AddItem from '../add-item/add-item.componenet';
import EditItem from '../edit-item/edit-item.componenet';
import AddCustomerGroup from '../add-customer-group/add-customer-group.component';
import EditCustomerGroup from '../edit-customer-group/edit-customer-group.componenet';
import AddLocation from '../add-location/add-location.componenet';
import EditLocation from '../edit-location/edit-location.componenet';
import AddCustomer from '../add-customer/add-customer.component';
import EditCustomer from '../edit-customer/edit-customer.componenet';

const ModalManager = ({
    modalType
}) => {
    const loadModal = (modalType) => {
        switch (modalType) {
            case MODAL_TYPE.ADD_SUPPLIER:
                return <AddSupplier />
            case MODAL_TYPE.EDIT_SUPPLIER:
                return <EditSupplier />
            case MODAL_TYPE.ADD_SUPPLIER_GROUP:
                return <AddSupplierGroup />
            case MODAL_TYPE.EDIT_SUPPLIER_GROUP:
                return <EditSupplierGroup />
            case MODAL_TYPE.ADD_ITEM_GROUP:
                return <AddItemGroup />
            case MODAL_TYPE.EDIT_ITEM_GROUP:
                return <EditItemGroup />
            case MODAL_TYPE.ADD_ITEM_TAX:
                return <AddItemTax />
            case MODAL_TYPE.EDIT_ITEM_TAX:
                return <EditItemTax />
            case MODAL_TYPE.ADD_UNIT_OF_MEASURE:
                return <AddUnitOfMeasure />
            case MODAL_TYPE.EDIT_UNIT_OF_MEASURE:
                return <EditUnitOfMeasure />
            case MODAL_TYPE.ADD_ITEM:
                return <AddItem />
            case MODAL_TYPE.EDIT_ITEM:
                return <EditItem />
            case MODAL_TYPE.ADD_CUSTOMER_GROUP:
                return <AddCustomerGroup />
            case MODAL_TYPE.EDIT_CUSTOMER_GROUP:
                return <EditCustomerGroup />
            case MODAL_TYPE.ADD_LOCATION:
                return <AddLocation />
            case MODAL_TYPE.EDIT_LOCATION:
                return <EditLocation />
            case MODAL_TYPE.ADD_CUSTOMER:
                return <AddCustomer />
            case MODAL_TYPE.EDIT_CUSTOMER:
                return <EditCustomer />
            default:
                return null
        }
    }

    return (
        <div className="modal-manager">
            {loadModal(modalType)}
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    modalType: selectModalType,
})

export default connect(mapStateToProps)(ModalManager);

