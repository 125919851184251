import React, { useEffect } from 'react';

import './modal-body-edit-section.styles.scss';

import IconExpand from '../../assets/icons/expand-arrow-modal.svg';
import FormTextarea from '../form-textarea/form-textarea.componenet';
import SelectField from '../select-field/select-field.componenet';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { selectEditData, selectModalEdit, selectModalExpandSection, selectModalFormValue } from '../../redux/modal/modal.reselect';
import { addMorePart, removePart, setIsModalFetching, setModalExpandSection } from '../../redux/modal/modal.actions';

import FormField from '../form-field/form-field.component';
import AddRemoveButton from '../add-remove-button/add-remove-button.componenet';
import { checkForNonDisplayField } from '../../utils/check-value.utlis';
import ModalBodyEditSubsection from '../modal-body-edit-subsection/modal-body-edit-subsection.component';
import { objectToArray } from '../../utils/object-to-array';
import { useState } from 'react';
import { authTokenSelector } from '../../redux/user/user.reselect';


const ModalBodyEditSection = ({
    modalExpandSection,
    setModalExpandSection,
    setModalFieldValue,
    modalFormValues,
    addMorePart,
    removePart,
    setIsModalFetching,
    editSectionData,
    authToken,
    focusFieldName,
    bodySection: {
        sectionType,
        sectionTypeId,
        fieldName,
        expandable,
        isExpand,
        fieldItems,
        addButton,
        multiForm,
        mutliFormTitle,
        position,
        breakPoint
    }
}) => {

    const editSectionDataHasSubSection = () => {
        if (editSectionData.subSection) {
            return objectToArray(editSectionData)
        }

        return []
    }

    const { fields, append, remove, insert } = useFieldArray({
        name: fieldName,
        focusAppend: false
    });

    const editSectionDataSubSectionArray = editSectionDataHasSubSection();

    const [subSectionCount, setSubSectionCount] = useState(0)

    useEffect(() => {
        if (editSectionData.subSection &&
            (subSectionCount < (editSectionDataSubSectionArray.length))) {
            append({ ...editSectionDataSubSectionArray[subSectionCount] }, {
                focusName: focusFieldName
            });
            setSubSectionCount(subSectionCount + 1)
        }
    }, [subSectionCount])

    useEffect(() => {
        if (editSectionData.subSection &&
            !(subSectionCount < (editSectionDataSubSectionArray.length))) {
            append({}, {
                focusName: focusFieldName
            });
        }
    }, [])

    useEffect(() => {
        if (!editSectionData.subSection) {
            append({ ...editSectionData }, {
                focusName: focusFieldName
            })
        }
    }, [])

    useEffect(() => {
        setIsModalFetching(false)
    }, [])

    const hanldeAppend = (event) => {
        event.preventDefault();
        append({}, true)
    }

    let partNumber = null;
    const part = "part";
    const handleChange = (event, partNumber) => {
        setModalFieldValue(
            sectionTypeId,
            part + (partNumber),
            event.target.name,
            event.target.value
        )
    }
    const handleSelectChange = (value, action, partNumber) => {
        setModalFieldValue(
            sectionTypeId, part + (partNumber),
            action.name,
            value
        )
    }
    const handleSectionExpand = (event) => {
        event.preventDefault();
        setModalExpandSection(event.target.dataset.sectiontypeid);
    }
    const handleAddMore = (event) => {
        event.preventDefault();
        addMorePart(sectionTypeId, fields[0]);
    }
    const handleRemove = (event) => {
        event.preventDefault();
        removePart(sectionTypeId, part + (partNumber));
    }

    useEffect(() => {
        if (isExpand) {
            setModalExpandSection(sectionTypeId)
        }
    }, [])

    const renderForm = (field, partNumber, fieldIndex) => {
        switch (field.type) {
            case 'text':
            case 'email':
                return (
                    <FormField
                        label={field.label}
                        type={field.type}
                        initialFocus={field.initialFocus}
                        validationProps={field.validationProps}
                        fieldName={fieldName}
                        partNumber={partNumber}
                        fieldItemName={field.name}
                        fieldIndex={fieldIndex}
                        sectionTypeId={sectionTypeId}
                    />)
            case 'textarea':
                return (
                    <FormTextarea
                        label={field.label}
                        type={field.type}
                        initialFocus={field.initialFocus}
                        validationProps={field.validationProps}
                        fieldName={fieldName}
                        partNumber={partNumber}
                        fieldItemName={field.name}
                    />
                )
            case 'select':
                return (
                    <SelectField
                        label={field.label}
                        type={field.type}
                        required={field.required}
                        initialFocus={field.initialFocus}
                        validationProps={field.validationProps}
                        fieldName={fieldName}
                        partNumber={partNumber}
                        fieldItemName={field.name}
                        authToken={authToken}
                        endpointUrl={field.url}
                        selectEdit={true}
                        mutliselect={field.muliselect}
                    />
                )

            default:
                break;
        }
    }

    let needFlat;
    const myField = <input type="radio" name="contactName" />
    // useEffect(() => {
    //     console.log(needFlat);
    //     insert(0,myField)
    // })

    const fieldLength = fieldItems.length;
    const col1Fields = fieldItems.slice(0, breakPoint)
    const col2Fields = fieldItems.slice(breakPoint, fieldLength + 1)

    return (
        <div
            className={`modal-body-section ${sectionTypeId !== 'BASIC_DETAILS' ?
                'modal-body-section--margin-top-20' :
                null}
                ${position === 'LAST' ?
                    'modal-body-section--last' :
                    null}
                `}
        >
            {
                sectionTypeId !== 'BASIC_DETAILS' ?
                    <div className="modal-body-section__header">
                        {expandable ? <button
                            className="modal-body-section__header__title-action"
                            data-sectiontypeid={sectionTypeId}
                            id={sectionTypeId}
                            onClick={handleSectionExpand}>
                            <h5 className="modal-body-section__header__title-action__title">{sectionType}</h5>
                            <img
                                className={`modal-body-section__header__title-action__img
                                        ${modalExpandSection[sectionTypeId] ?
                                        'modal-body-section__header__title-action__img--expand' :
                                        null}`}
                                src={IconExpand} alt="expand icon" />
                        </button> :
                            null
                        }
                    </div> :
                    null
            }
            <div className={`modal-body-section__sub-section
                    ${sectionTypeId !== 'BASIC_DETAILS' ?
                    modalExpandSection[sectionTypeId] ?
                        'modal-body-section__sub-section--expand' :
                        'modal-body-section__sub-section--collapse' :
                    null}`}
            >
                <div className="modal-body-section__body" >
                    {fields.map((field, index) => {
                        partNumber = index;
                        return (<ModalBodyEditSubsection
                            key={index}
                            index={index}
                            sectionTypeId={sectionTypeId}
                            remove={remove}
                            editSectionData={editSectionData}
                            field={field}
                            multiForm={multiForm}
                            mutliFormTitle={mutliFormTitle}
                            partNumber={partNumber}
                            col1Fields={col1Fields}
                            col2Fields={col2Fields}
                            renderForm={renderForm}

                        />)
                    })}
                </div>
                <div className={`row modal-body-section__footer`}>
                    {
                        addButton && <AddRemoveButton
                            handleClick={(event) => { hanldeAppend(event) }}
                            type="add"
                        > Add </AddRemoveButton>
                    }
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    modalExpandSection: selectModalExpandSection,
    modalFormValues: selectModalFormValue,
    authToken: authTokenSelector
});

const mapDispatchToProps = (dispatch) => ({
    setModalExpandSection: (sectionTypeId) =>
        dispatch(setModalExpandSection(sectionTypeId)),
    addMorePart: (sectionTypeId, addedField) =>
        dispatch(addMorePart(sectionTypeId, addedField)),
    removePart: (sectionTypeId, partNumber) =>
        dispatch(removePart(sectionTypeId, partNumber)),
    setIsModalFetching: (isFetching) =>
        dispatch(setIsModalFetching(isFetching))
})

export default connect(mapStateToProps, mapDispatchToProps)(ModalBodyEditSection);