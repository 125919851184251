import React from 'react';

const withModalFormTemplate = WrappedComponent => {
    const modal = ({ modalType, formTemplate, showModal, setShowModal, size, isLoading = false, endpointUrl }) => {
        return formTemplate ?
            modalType ?
                <WrappedComponent
                    showModal={showModal}
                    setShowModal={setShowModal}
                    isLoading={isLoading}
                    endpointUrl={endpointUrl}
                    size={size}
                /> :
                null :
            null
    }

    return modal;
};

export default withModalFormTemplate;