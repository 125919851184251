import React, { useRef, useMemo } from 'react';
import {
    useTable,
    useResizeColumns,
    useBlockLayout,
    useSortBy,
    useRowSelect,
    useGlobalFilter,
    useRowState,
    useFlexLayout
} from 'react-table';

import './document-table.styles.scss';

import { useFormContext, Controller } from "react-hook-form";
import TableCheckbox from '../table-checkbox/table-checkbox.componenet';
import FormTableButton from '../form-table-button/form-table-button.component';
import { addRowFormTable, setFormTableLatestData } from '../../redux/form/form.actions';
import { connect } from 'react-redux';
import { useState } from 'react';
import { selectFormEdit, selectFormTableLatestData, selectFormTableLatestRows, selectRemoveTableRow } from '../../redux/form/form.reselect';
import { createStructuredSelector } from 'reselect';
import { useEffect } from 'react';

const DocumentTable = ({
    title,
    tableColumns,
    tableData,
    fieldName,
    removeTableRow,
    tableLatestRows,
    setFormTableLatestData,
    editSectionData,
    formEdit
}) => {
    const columns = useMemo(() => tableColumns, []);

    const [data, setData] = useState(tableData);

    const tableMethods = useTable({
        columns,
        data
    },
        useGlobalFilter,
        useResizeColumns,
        useFlexLayout,
        useRowSelect,
        useRowState,
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,

    } = tableMethods;


    return (
        <div className="document-table-container">
            <div className="document-table-header">
                {title}
            </div>
            <div className="document-table-wrapper">
                <table className="document-table document-table-page-break" {...getTableProps()} >
                    <thead className="document-table__header">
                        {headerGroups.map(headerGroup => (
                            <tr className="document-table__header__group" {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th
                                        className="document-table__header__item"
                                        {...column.getHeaderProps([

                                            {
                                                className: column.className,
                                                style: column.style
                                            }
                                        ])}
                                    >
                                        <span>
                                            {column.render('Header')}
                                        </span>
                                        <span style={{ border: '3px solid coral', width: '3px', height: '40px' }}
                                            {...column.getResizerProps()}
                                            className={`document-table__header__item__resizer 
                                                ${column.isResizing ?
                                                    "document-table__header__item__resizer--isResizing" : ""
                                                }`}
                                        />
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody className="document-table__body" {...getTableBodyProps()}>
                        {rows.map(row => {
                            prepareRow(row)
                            return (
                                <tr className="document-table__body__row" {...row.getRowProps()}>
                                    {row.cells.map(cell => (
                                        <td
                                            className="document-table__body__row__cell"
                                            {...cell.getCellProps([
                                                {
                                                    className: cell.column.className,
                                                    style: cell.column.style
                                                }
                                            ])}
                                            data-label={cell.render('Header')}
                                        >
                                            <span className="document-table__body__row__cell__header">
                                                {cell.render('Header')}
                                            </span>
                                            <span className="document-table__body__row__cell__data">
                                                {cell.render('Cell')}
                                            </span>
                                        </td>
                                    ))}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
};

const mapStateToProps = createStructuredSelector({
    removeTableRow: selectRemoveTableRow,
    // tableLatestData: selectFormTableLatestData,
    tableLatestRows: selectFormTableLatestRows,
    formEdit: selectFormEdit
})

const mapDispatchToProps = (dispatch) => ({
    addRowFormTable: () =>
        dispatch(addRowFormTable()),
    setFormTableLatestData: (data) =>
        dispatch(setFormTableLatestData(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(DocumentTable)
