import React, { useRef } from "react";

import "./list-global-filter.styles.scss";

import IconSearch from "../../assets/icons/search-icon-gray.svg";

import { useDebouncedCallback, useDebounce } from "use-debounce"
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";

import { setFilterInput, setLocalFilterFieldNamesAndValues } from "../../redux/filter/filter.actions";
import { selectFilterInput, selectLocalFilterFieldNamesAndValues } from "../../redux/filter/filter.reselect";
import { useState } from "react";
import { useEffect } from "react";

const ListGlobalFilter = ({
    setFilterInput,
    filterInput,
    setLocalFilterFieldNamesAndValues,
    localFilter
}) => {
    const field = useRef();
    const [mount, setMount] = useState(false)
    const handleClick = () => {
        field.current.focus();
    }

    const [inputValue, setInputValue] = useState('');
    const [value] = useDebounce(inputValue, 1000);

    useEffect(() => {
        if (filterInput !== '') {
            setInputValue(filterInput)
        }
        setMount(true)
    }, [])

    useEffect(() => {
        if (mount && inputValue !==  filterInput) {
            setFilterInput(inputValue);
        }
    }, [value])

    useEffect(() => {
        if (mount && 
            inputValue !== ''  && 
            Object.keys(localFilter).length !== 0
        ) {
            setLocalFilterFieldNamesAndValues({})
        }
    }, [value])

    useEffect(() => {
        setInputValue(filterInput)
    }, [filterInput])

    // useEffect(() => {
    //     setInputValue(filterInput)
    // }, [])

    // useEffect(() => {
    //     if (inputValue !== '' &&
    //         inputValue !== filterInput
    //     ) {
    //         setFilterInput(inputValue);
    //     }
    // }, [value])

    return (
        <span className="list-global-filter" onClick={handleClick}>
            <img
                className="list-global-filter__icon"
                src={IconSearch}
            />
            <input
                className="list-global-filter__field"
                type="search"
                placeholder="Search this list"
                ref={field}
                onChange={event => setInputValue(event.target.value)}
                value={inputValue}
            />
        </span>
    )
};

const mapStateToProps = createStructuredSelector({
    filterInput: selectFilterInput,
    localFilter: selectLocalFilterFieldNamesAndValues
});

const mapDispatchToPropos = (dispatch) => ({
    setFilterInput: (filterInput) => dispatch(setFilterInput(filterInput)),
    setLocalFilterFieldNamesAndValues: (inputNameAndValue) => dispatch(setLocalFilterFieldNamesAndValues(inputNameAndValue))
})

export default connect(mapStateToProps, mapDispatchToPropos)(ListGlobalFilter);