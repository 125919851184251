import Form from './form.componenet';
import withFormTemplate from '../with-form-template/with-form-template.component';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectModalFormTemplate, selectModalType, selectShowSupplierModal } from '../../redux/modal/modal.reselect';
import { selectFormTemplate, selectFormType } from '../../redux/form/form.reselect';

const mapStateToProps = createStructuredSelector({
    formTemplate: selectFormTemplate,
});

const FormContainer = connect(mapStateToProps)(withFormTemplate(Form));

export default FormContainer;