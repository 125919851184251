export const LOCAL_FILTER_FIELDS = [
    {
        label: "UoM Code",
        type: "text",
        name: "unitOfMeasureCode",
    },
    {
        label: "UoM Name",
        type: "text",
        name: "unitOfMeasureName",
    }
]