import "./document-body-header-section.styles.scss"
import lodash from 'lodash';
import bwipjs from 'bwip-js';
import { useEffect } from "react";

const DocumentBodyHeaderSection = ({
    headerData,
    isMainTitle
}) => {
    useEffect(() => {
        try {
            // The return value is the canvas element
            let canvas = bwipjs.toCanvas('barcode', {
                bcid: 'code128',       // Barcode type
                text: headerData.barcode ? headerData.barcode : "",    // Text to encode
                scale: 2,               // 3x scaling factor
                height: 8,              // Bar height, in millimeters
                includetext: false,            // Show human-readable text
                textxalign: 'center',        // Always good to set this
            });
        } catch (e) {
            // `e` may be a string or Error object
        }
    }, [])

    return (
        <div className={`document-body-header-section ${isMainTitle ? "document-body-header-section--right" : null}`}>
            <h4 className={`document-body-header-section__title ${isMainTitle ? "document-body-header-section__title--large" : null}`}>
                {headerData.title}
            </h4>
            {
                 headerData.subTitles &&  headerData.subTitles.map((subTitle) => {
                    return <h6 className="document-body-header-section__sub-title">{subTitle}</h6>
                })
            }
            {
                isMainTitle && headerData.barcode ?
                <div className="document-body-header-section__barcode">
                    <canvas id="barcode"></canvas>
                </div> : null
            }

            <ul className="document-body-header-section__list">
                {
                    headerData.content.map((value, index) => {
                        if (typeof value === "object") {
                            return (
                                <ul className="document-body-header-section__list" key={index}>
                                    {Object.keys(value).map((key, index) => {
                                        return (

                                            <li className="document-body-header-section__list__title-text" key={index}>
                                                <span className="document-body-header-section__list__title-text__title">{lodash.startCase(key)}</span>
                                                <span>{value[key]}</span>
                                            </li>
                                        )
                                    })}
                                </ul>
                            )
                        }
                        return <li>{value}</li>
                    })
                }
            </ul>
        </div>
    )
}

export default DocumentBodyHeaderSection