import React from 'react';

import './modal-footer.styles.scss';

import { connect } from 'react-redux';

import CustomButton from '../custom-button/custom-button.componenet';
import { createStructuredSelector } from 'reselect';
import { selectIsLoading, selectModalFormValue } from '../../redux/modal/modal.reselect';
import ButtonSpinner from '../button-spinner/button-spinner.compoenent';

const ModalFooter = ({
    isLoading,
    handleCloseModal,
    handleSave,
    handleSaveandNew
}) => {

    return (
        <div className='general-modal__footer'>
            <div className='general-modal__footer__spinner'>
                {
                    isLoading &&
                    <ButtonSpinner />
                }
            </div>
            <CustomButton
                inverted={true}
                handleClick={(event) => { handleCloseModal(event) }}
            >
                Cancel
            </CustomButton>
            {
                handleSaveandNew &&
                <CustomButton
                    inverted={true}
                    isLoading={isLoading}
                    handleClick={(event) => { handleSaveandNew(event) }}
                >
                    Save & New
                </CustomButton>
            }
            <CustomButton
                isLoading={isLoading}
                handleClick={(event) => { handleSave(event) }}
            >
                Save
            </CustomButton>
        </div>
    )
};

const mapStateToProps = createStructuredSelector({
    isLoading: selectIsLoading
});

export default connect(mapStateToProps)(ModalFooter);

