export const CUSTOMER_GROUPS_COLUMNS = [
    {
        Header: "Customer Group Code",
        accessor: "customerGroupCode",
        style: {
            position: "sticky",
            left:0,
            top:0,
            zIndex: 2
        }
    },
    {
        Header: "Customer Group Name",
        accessor: "customerGroupName",
    }
]