export const formActionTypes = {
    SHOW_ADD_SUPPLIER_FORM: "SHOW_ADD_SUPPLIER_FORM",
    SHOW_EDIT_SUPPLIER_FORM: "SHOW_EDIT_SUPPLIER_FORM",
    SHOW_ADD_PURCHASE_ORDER_FORM: "SHOW_ADD_PURCHASE_ORDER_FORM",
    SET_EDIT_UUID: "SET_EDIT_UUID",
    SET_EDIT_DATA: "SET_EDIT_DATA",
    SET_AUTO_FILL_DATA_FORM: "SET_AUTO_FILL_DATA_FORM",
    SET_FORM_EXPAND_SECTION: "SET_FORM_EXPAND_SECTION",
    SET_FORM_INFO: "SET_FORM_INFO",
    SET_FORM_TEMPLATE: "SET_FORM_TEMPLATE",
    SET_FORM_TYPE: "SET_FORM_TYPE",
    ADD_MORE_PART: "ADD_MORE_PART",
    REMOVE_PART: "REMOVE_PART",
    SET_FORM_FIELD_VALUE: "SET_FORM_FIELD_VALUE",
    SUBMIT_FORM_START: "SUBMIT_FORM_START",
    SUBMIT_FORM_SUCCESS: "SUBMIT_FORM_SUCCESS",
    SUBMIT_FORM_FAILURE: "SUBMIT_FORM_FAILURE",
    SET_FORM_VALIDATION_ERROR: "SET_FORM_VALIDATION_ERROR",
    SET_IS_FAIL: "SET_IS_FAIL",
    SET_WINDOW_HEIGHT: "SET_WINDOW_HEIGHT",
    SET_IS_FETCHING: "SET_IS_FETCHING",
    SET_FORM_TABLE_DATA: "SET_FORM_TABLE_DATA",
    SET_FORM_LATEST_DATA: "SET_FORM_LATEST_DATA",
    ADD_ROW_FORM_TABLE: "ADD_ROW_FORM_TABLE",
    REMOVE_ROW_FORM_TABLE: "REMOVE_ROW_FORM_TABLE",
    SET_FORM_TOTAL_VALUES: "SET_FORM_TOTAL_VALUES",
    SET_TABLE_LATEST_ROWS: "SET_TABLE_LATEST_ROWS",
    SET_FORM_CHANGED_VALUES: "SET_FORM_CHANGED_VALUES",
    SET_IS_EDIT_FULL_FORM: "SET_IS_EDIT_FULL_FORM",
    SET_FORM_EDIT: "SET_FORM_EDIT",
    SET_SELECTED_FIELD: "SET_SELECTED_FIELD",
    SET_SELECTED_FIELD_VALUE: "SET_SELECTED_FIELD_VALUE",
    SET_FORM_SIDE_BAR_NAV: "SET_FORM_SIDE_BAR_NAV",
    SET_FORM_SIDE_BAR_NAV_ACTIVE_ITEM: "SET_FORM_SIDE_BAR_NAV_ACTIVE_ITEM",
    SET_FORM_PROGRESS: "SET_FORM_PROGRESS",
    SET_REMOVE_REMOVE_TABLE_ROW: "SET_REMOVE_REMOVE_TABLE_ROW",
    SET_FORM_TEMPLATE_ENABLE_FIELD: "SET_FORM_TEMPLATE_ENABLE_FIELD",
    SET_VALUE_ENTERED_BLUR: "SET_VALUE_ENTERED_BLUR",
    RESET_ERROR_MESSAGE_AND_IS_FAIL: "RESET_ERROR_MESSAGE_AND_IS_FAIL"
}