export const displayCellValue = (value) => {
    if(value){
        return value
    }

    return "-"
}

export const displayCellValueNumberToTwoDecimal = (number) => {
    if (number) {
        const roundedNumber = (Math.round(number * 100) / 100).toFixed(2);
        return new Intl.NumberFormat('en-US').format(roundedNumber)
    }
    
    return "-"
}

export const displayCellValueWithPercentile = (value) => {
    if(value){
       return value + "%"
    }

    return "-"
}
