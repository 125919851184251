const EDIT_SUPPLIER_GROUP_FORM_TEMPLATE ={
    modalTitle: "Edit Supplier Group",
    path: "/suppliergroups",
    listPage: "/list-supplier-groups",
    bodySections: {
      basic: {
        sectionType: "basic",
        sectionTypeId: "BASIC_DETAILS",
        expandable: false,
        position: "LAST",
        breakPoint: 0,
        fieldName: "supplierGroup",
        fieldItems: [
          {
            label: "Supplier Group Name",
            type: "text",
            name: "supplierGroup",
            initialFocus: true,
            validationProps: {
              required: "Supplier group is required"
            }
          }
        ]
      }
    }
}

export default EDIT_SUPPLIER_GROUP_FORM_TEMPLATE;