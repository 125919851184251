export const LOCAL_FILTER_FIELDS = [
    {
        label: "transfer code",
        type: "text",
        name: "transferCode",
    },
    {
        label: "From location",
        type: "text",
        name: "fromLocationCode",
    },
    {
        label: "To location",
        type: "text",
        name: "toLocationCode",
    }
]