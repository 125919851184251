import React from "react";
import { checkForNonDisplayField } from "../../utils/check-value.utlis";
import AddRemoveButton from "../add-remove-button/add-remove-button.componenet";

const ModalBodyEditSubsection = ({
    index,
    sectionTypeId,
    editSectionData,
    field,
    multiForm,
    mutliFormTitle,
    partNumber,
    col1Fields,
    col2Fields,
    renderForm,
    remove
}) => {

    return (
        <div key={field.id} >
            {
                index > 0 &&
                <div className="modal-body-section__body__parts__separator" />
            }
            {
                multiForm === true ?
                    <div className="modal-body-section__body__sub-header">
                        <h6 className="modal-body-section__body__sub-header__title">{mutliFormTitle} {partNumber + 1}</h6>
                    </div> :
                    null
            }
            <div className='container'>
                <div className="row modal-body-section__body__parts" >
                    <div className={`${sectionTypeId === "BASIC_DETAILS" ? "col-md-12" : "col-md-6"}`}>
                        <div className="row">
                            {col1Fields.map((fieldItem, fieldIndex) => (
                                <div
                                    className={`modal-body-section__body__parts__item  ${fieldItem.size === 'small' ?
                                        'col-6  col-md-6' :
                                        'col-md-12'
                                        }`}
                                    key={fieldItem.name}
                                >
                                    {renderForm(
                                        fieldItem,
                                        partNumber,
                                        fieldIndex
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className={`${sectionTypeId === "BASIC_DETAILS" ? "col-md-12" : "col-md-6"}`}>
                        <div className="row" >
                            {col2Fields.map((fieldItem, fieldIndex) => (
                                <div
                                    className={`modal-body-section__body__parts__item  ${fieldItem.size === 'small' ?
                                        'col-6  col-md-6' :
                                        'col-md-12'
                                        }`}
                                    key={fieldItem.name}
                                >
                                    {renderForm(
                                        fieldItem,
                                        partNumber,
                                        index
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-body-section__body__buttons">
                {
                    sectionTypeId !== 'BASIC_DETAILS' && <AddRemoveButton
                        handleClick={(event) => { event.preventDefault(); remove(index); }}
                        type="remove"
                    > Remove </AddRemoveButton>
                }
            </div>
        </div>
    )
}

export default ModalBodyEditSubsection;