import { format } from 'date-fns';
import ListActions from '../../../components/list-actions/list-actions.componenet';
import ListTableMainCell from '../../../components/list-table-main-cell/list-table-main-cell.component';
import TableCellStatus from '../../../components/table-cell-status/table-cell-status.component';

import { displayCellValue, displayCellValueNumberToTwoDecimal } from '../../../utils/column-values.util';

export const STOCK_TRANSFER_ORDERS_COLUMNS = [
    {
        Header: "Transfer Code",
        accessor: "transferCode",
        style: {
            position: "sticky",
            left:0,
            top:0,
            zIndex: 2
        },
        Cell: ({ value, row }) => {
            const uuid = row.original.uuid;
            return (
                <ListTableMainCell
                    value={value}
                    uuid={uuid}
                />
            )
        }
    },
    {
        Header: "From Location",
        accessor: "fromLocation",
        Cell: ({ value }) => {
            return displayCellValue(value);
        }
    },
    {
        Header: "To Location",
        accessor: "toLocation",
        Cell: ({ value }) => {
            return displayCellValue(value);
        }
    },
    {
        Header: "Requested Date",
        accessor: "requestedDate",
        Cell: ({ value }) => (
            format(new Date(value), 'dd/MM/yyyy')
        )
    },
]