import "./home.styles.scss";

import { navItems1, navItems2 } from './home-nav.data';
import HomeBody from "../../components/home-body/home-body.component";
import HomeHeader from "../../components/home-header/home-header.component";
import { useEffect, useRef } from "react";
import { setTabTitle } from "../../utils/tab-title.utils";
import useFetchReport from "../../effects/use-fetch-report.effect";
import { useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { authTokenSelector, selectAccessRights } from "../../redux/user/user.reselect";
import { selectReportDateRange } from "../../redux/report/report.reselect";
import { setReportDateRange, setReportSummaryData } from "../../redux/report/report.actions";
import { format } from "date-fns";
import { getThisWeek } from "../../utils/date.utils";

const Home = ({
    authToken,
    reportDateRange,
    setReportSummaryData,
    setReportDateRange,
    accessRights
}) => {
    const [isFristRender, setIsFristRender] = useState(true); 
    const [isAccessRightChecked, setIsAccessRightCheked] = useState(false);
    const isReportNoAccess = useRef(false);

    useEffect(() => {
        const accessRight = accessRights.find((accessRight => {
            return accessRight.application.toLowerCase() === "report"
        }))

        if (accessRight && (accessRight.permission.toLowerCase() === "no access")) {
            isReportNoAccess.current = true;
        }
        
        setIsAccessRightCheked(true)
    }, [])


    useEffect(() => {
        setReportDateRange({})
        setIsFristRender(false)
    }, [])
    
    useEffect(() => {
        setTabTitle(`Home`)
    }, [])

    return (
        <>
            {isAccessRightChecked && !isFristRender && 
                <div className="home">
                    <div className="home__body">
                        <HomeBody
                            navItems1={navItems1}
                            navItems2={navItems2}
                            isReportNoAccess={isReportNoAccess.current}
                        />
                    </div>
                </div>
            }
        </>
    )
}

const mapStateToProps = createStructuredSelector({
    accessRights: selectAccessRights
})

const mapDispatchToProps = (dispatch) => ({
    setReportDateRange: (data) =>
        dispatch(setReportDateRange(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
