import React from "react";

import "./detail-header.styles.scss";

import TitleHeader from "../title-header/title-header.componenet";
import DetailEdit from "../detail-edit/detail-edit.componenet";
import DropDownButton from "../drop-down-button/drop-down-button.componenet";
import DetailRefresh from "../detail-refresh/detail-refresh.component";
import DetailPrintView from "../detail-print-view/detail-print-view.component";


const DetailHeader = ({ title, setEditModal }) => {
    return (
        <div className="detail-header container">
            <TitleHeader>{title}</TitleHeader>
            <div className="detail-header__tools">
                <div className="detail-header__tools__actions">
                    <DetailPrintView
                        DropDownButton={DropDownButton}
                    />
                    <DetailRefresh
                        DropDownButton={DropDownButton}
                    />
                    <DetailEdit
                        DropDownButton={DropDownButton}
                        setEditModal={setEditModal}
                    />
                </div>
            </div>
        </div>
    )
}

export default DetailHeader;