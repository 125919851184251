const EDIT_UNIT_OF_MEASURE_FORM_TEMPLATE ={
    modalTitle: "Edit Unit of Measure",
    path: "/unitofmeasures",
    listPage: "/list-unit-of-measures",
    bodySections: {
      basic: {
        sectionType: "basic",
        sectionTypeId: "BASIC_DETAILS",
        expandable: false,
        position: "LAST",
        breakPoint: 0,
        fieldName: "unitOfMeasure",
        fieldItems: [
          {
            label: "Unit of Measure Code",
            type: "text",
            name: "unitOfMeasureCode",
            initialFocus: true,
            validationProps: {
              required: "Unit of Measure Code is required"
            }
          },
          {
            label: "Unit of Measure Name",
            type: "text",
            name: "unitOfMeasureName",
            initialFocus: false,
            validationProps: {
              required: "Unit of Measure Name is required"
            }
          },
          {
            label: "description",
            type: "textarea",
            name: "description",
            required: false,
            initialFocus: false
          }
        ]
      }
    }
}

export default EDIT_UNIT_OF_MEASURE_FORM_TEMPLATE;