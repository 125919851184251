import "./drop-down-button.styles.scss";

const DropDownButton = ({
    handleClickDropDownBtn,
    content,
    icon,
    iconLeft,
    reference,
    inverted
}) => (
    <button
        className={`drop-down-button ${inverted ? "drop-down-button--inverted" : null}`}
        onClick={handleClickDropDownBtn} ref={reference}>
        {iconLeft?
            <img className="drop-down-button__icon drop-down-button__icon--right-margin" src={iconLeft} /> :
            null
        }
        {content && <span className={`drop-down-button__content`}>{content}</span>}
        {icon ?
            <img className="drop-down-button__icon" src={icon} /> :
            null
        }
    </button>
);

export default DropDownButton;