export const disableFormFields = (formBodySections) => {
    for (const section of Object.keys(formBodySections)) {
        const fieldItems = formBodySections[section].fieldItems;

        if(!fieldItems && section === "tableForm"){
            formBodySections[section].isDisable = true
        }else{
            for (const fieldItem of fieldItems) {
                fieldItem.disabled = true
            }
        }
    }
}
