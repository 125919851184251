import react from 'react';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { logoutUser } from '../../redux/user/user.actions';
import { authTokenSelector } from '../../redux/user/user.reselect';

const LoggedInTest = ({ logoutUser, authToken}) => {
    const handleChnage = (event) => {
        logoutUser(authToken);
    }
    return (
        <div className='container'>
            <div>
             Logged In! 
            </div>
            
            <button onClick={handleChnage}>Log out</button>
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    authToken : authTokenSelector
});

const mapDisptachToProps = (dispatch) => ({
    logoutUser: (authToken) => dispatch(logoutUser(authToken))
}) 

export default connect(mapStateToProps,mapDisptachToProps)(LoggedInTest);