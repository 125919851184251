const EDIT_ITEM_GROUP_FORM_TEMPLATE ={
    modalTitle: "Edit Item Group",
    path: "/itemgroups",
    listPage: "/list-item-groups",
    bodySections: {
      basic: {
        sectionType: "basic",
        sectionTypeId: "BASIC_DETAILS",
        expandable: false,
        position: "LAST",
        breakPoint: 0,
        fieldName: "itemGroup",
        fieldItems: [
          {
            label: "Item Group Name",
            type: "text",
            name: "itemGroup",
            initialFocus: true,
            validationProps: {
              required: "Item group is required"
            }
          }
        ]
      }
    }
}

export default EDIT_ITEM_GROUP_FORM_TEMPLATE;