import { Axios } from "../config";
import validator from 'validator';

import { isValidPhoneNumber } from 'libphonenumber-js';
import parasePhoneNumber from '../utils/parase-phone.utils';
import { getAuthToken } from '../utils/auth_token.utils';

const login = async (username, password, rememberMe) => {
    try {
        const credentialConditions = validator.isEmail(username) ? { email: username } :
            isValidPhoneNumber(username, 'ET') ? { phoneNumber: parasePhoneNumber(username) } :
                { username: username }

        const response = await Axios.post("/users/login", {
            ...credentialConditions,
            password
        })

        if (rememberMe && response.data.authToken) {
            localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
    } catch (error) {
        if (error.response) {
            if (error.response.status === 400 || error.response.status === 401) {
                return {
                    name: "client_error",
                    message: "Please enter a valid username, email, phone number, or password. If you still can’t login, contact your administrator."
                }
            } else {
                return {
                    name: "server_error",
                    message: "System error, please try again after few moments. If you still can’t login, contact your administrator."
                }
            }
        } else if (error.request) {
            return {
                name: "network_error",
                message: "Please, make sure you are connected to the network. If you still can’t login, contact your administrator."
            }
        } else {
            return {
                name: "unknown_error",
                message: "An unknown error occurred, please contact your administrator."
            }
        }
    }
}

const logout = async (authTokenFromState) => {
    try {
        const loggedInUser = JSON.parse(localStorage.getItem("user"));

        if (loggedInUser) {
            localStorage.removeItem("user");
        }

        const tokenHeader = getAuthToken(authTokenFromState);
        await Axios.post("/users/logout", null, {
            headers: tokenHeader,
        });

    } catch (error) {
        console.log(error.message);
    }
};

export default {
    login,
    logout,
};
