import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import IconPrint from "../../assets/icons/print.svg";
import { selectUUID } from "../../redux/detail/detail.reselect";
import { selectExportData } from "../../redux/export/export.reselect";
import { setEditUUID, setShowEditSupplierModal } from "../../redux/modal/modal.actions";
import { printData } from "../../utils/export-print-data-to-pdf.util";

import DropDownContainer from '../drop-down-container/drop-down-container.componenet';

const DetailEdit = ({
    DropDownButton,
    uuid,
    setEditUUID,
    setEditModal,
}) => {
    const handleClick = () => {
        setEditUUID(uuid)
        setEditModal();
    }

    return (
        <DropDownContainer
            DropDownButton={DropDownButton}
            icon={false}
            size="medium"
            dropDwon={false}
            handleClick={handleClick}
        >
            Edit
        </DropDownContainer>
    )
}


const mapStateToProps = createStructuredSelector({
    uuid: selectUUID,
});

const mapDispatchToProps = (dispatch) => ({
    setEditUUID: (uuid) =>
        dispatch(setEditUUID(uuid))
})


export default connect(mapStateToProps, mapDispatchToProps)(DetailEdit)