import React, { useState } from "react";
import ReactDOMServer from 'react-dom/server';

import "./document-body.styles.scss";

import { connect } from "react-redux";
import { createStructuredSelector } from 'reselect';

import { authTokenSelector } from "../../redux/user/user.reselect";
import useFetchDetail from "../../effects/use-fetch-detail";
import { selectUUID } from "../../redux/detail/detail.reselect";

import DetailBodySection from "../detail-body-section/detail-body-section.componenet";
import Spinner from "../spinner/spinner.component";
import ErrorBox from "../error-box/error-box.component";
import { selectIsRefresh } from "../../redux/refresh/refresh.reselect";
import { useEffect } from "react";
import { setRefresh } from "../../redux/refresh/refresh.actions";

import { Provider } from 'react-redux';

import { store } from '../../redux/store';
import { useRef } from "react";
import { useLayoutEffect } from "react";
import useFetchDocument from "../../effects/use-fetch-document";
import { selectFormInfo } from "../../redux/form/form.reselect";
import { withRouter } from "react-router-dom";
import DocumentBodyHeaderSection from "../document-body-header-section/document-body-header-section.component";
import DocumentBodySection from "../document-body-section/document-body-section.component";
import DocumentTable from "../document-table/document-table.component";
import DocumentTotal from "../docuemnt-total/docuemnt-total.component";

const DocumentBody = ({
    setRefresh,
    data,
    isLoading,
    error,
    tableData,
    isRefresh,
    contentRef,
    title,
    match,
    tableColumns,
    watermark,
    totalBalance
}) => {
    const [a4Height, setA4Height] = useState(null);

    const getPageMargins = () => {
        return `@page { margin: ${'40px'} ${'10px'} ${'40px'} ${'10px'} !important; }`;
    };

    useEffect(() => {
        if (isRefresh) {
            setRefresh(false);
        }

    }, [isRefresh])

    let contentData = null
    return (
        <div className="document-body container" >
            <div className="document-body__a4  document-body__a4--watermark" ref={contentRef}>
                {watermark &&
                    <div className="document-body__a4__watermark">
                        Attachment
                    </div>
                }
                {isLoading ?
                    <div className="document-body__spinner">
                        <Spinner />
                    </div>
                    :
                    error ?
                        <div className="document-body__error">
                            <ErrorBox
                                error={error}
                                icon={error.status ?
                                    error.status === 404 ? false :
                                        true :
                                    true
                                }
                            />
                        </div>
                        :
                        <>
                            <div className="document-body__header">
                                {
                                    data.header.map((headerData, index) => {
                                        return (<DocumentBodyHeaderSection
                                            key={index}
                                            headerData={headerData} isMainTitle={index === 1 ? true : false}
                                        />)
                                    })
                                }
                            </div>
                            <div className="document-body__body">
                                {data.body.sections.map((sectionData, index) => {
                                    return (
                                        <DocumentBodySection
                                            key={index}
                                            sectionData={sectionData}
                                            isSmall={index > 1 ? true : false}
                                        />
                                    )
                                })}
                            </div>
                            <div className="document-body__table ">
                                {
                                    tableColumns &&
                                    <DocumentTable
                                        tableColumns={tableColumns}
                                        tableData={data.body.table}
                                    />
                                }

                            </div>
                            <div className="document-body__total">
                                {
                                    data.body.total &&
                                    <DocumentTotal
                                        totalData={data.body.total}
                                    />
                                }
                            </div>

                            {totalBalance && <div className="document-body__total">
                                <DocumentTotal
                                    totalData={data.body.totalBalance}
                                    totalBalance={totalBalance}
                                />

                            </div>}

                            {/* <style>{getPageMargins()}</style> */}
                        </>
                }
            </div >
        </div >
    )
}

const mapStateToProps = createStructuredSelector({
    authTokenFromState: authTokenSelector,
    uuid: selectUUID,
    isRefresh: selectIsRefresh,
});

const mapDispatchToProps = (dispatch) => ({
    setRefresh: (data) =>
        dispatch(setRefresh(data))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DocumentBody))