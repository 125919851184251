import React from "react";
import { connect } from "react-redux";
import { setEditData, setModalTemplate, setModalType, setShowEditCustomerModal, setShowEditSupplierModal } from '../../redux/modal/modal.actions';
import { createStructuredSelector } from 'reselect';
import { selectEditUUID, selectModalFormTemplate, selectShowEditCustomerModal, selectShowEditSupplierModal } from "../../redux/modal/modal.reselect";
import { useEffect } from "react";

import EDIT_CUSTOMER_FORM_TEMPLATE from './edit-customer.template.js';

import ModalContainer from "../modal/modal.container";
import useFetchEdit from "../../effects/use-fetch-edit.effect";
import { authTokenSelector } from "../../redux/user/user.reselect";

const EditSupplier = ({
    showEditCustomer,
    setModalTemplate,
    setModalType,
    formTemplate,
    editUUID,
    authToken,
    setShowEditCustomerModal,
    isFetching,
    setEditData
}) => {
    useEffect(() => {
        setModalType("EDIT_CUSTOMER")
        setModalTemplate(EDIT_CUSTOMER_FORM_TEMPLATE);
    }, []);
    const endpoint = '/customers';
    const endpointUrl = `${endpoint}/${editUUID}`;
    const authTokenFromState = authToken;

    const { data, error } = useFetchEdit(endpointUrl, authTokenFromState, showEditCustomer);

    useEffect(() => {
        setEditData(data)
    })
    
    return (
        <div className="edit-supplier">
            {
                Object.keys(formTemplate).length > 0 ?
                    <ModalContainer
                        showModal={showEditCustomer}
                        setShowModal={setShowEditCustomerModal}
                        endpointUrl={endpointUrl}
                        size={"LARGE"}
                    />
                    : null
            }
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    showEditCustomer: selectShowEditCustomerModal,
    formTemplate: selectModalFormTemplate,
    editUUID: selectEditUUID,
    authToken: authTokenSelector,
})

const mapDispatchToProps = (disptach) => ({
    setShowEditCustomerModal: () =>
        disptach(setShowEditCustomerModal()),
    setModalTemplate: (formTemplate) =>
        disptach(setModalTemplate(formTemplate)),
    setModalType: (modalType) =>
        disptach(setModalType(modalType)),
    setEditData: (editData) =>
        disptach(setEditData(editData))
})


export default connect(mapStateToProps, mapDispatchToProps)(EditSupplier);