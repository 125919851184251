export const isArrayEqual = (array1, array2) => {
    if (array1.length !== array2.length) {
        return false;
    } else if (array1.length === 0 && array2.length === 0) {
        return true;
    }

    for (var i = 0; i < array1.length; i++) {
        if (array1[i] !== array2[i]) {
            return false;
        }
    }

    return true
}

export const isArrayOfObjectEqual = (array1, array2) => {
    if (array1.length !== array2.length) {
        return false;
    }

    const isEqual = !(array1.map((object1, index) => {
        const object2 = array2[index];
        if (Object.keys(object1).length !== Object.keys(object2).length) {
            return false
        }
        return Object.keys(object1).every(key => {
            if (typeof object1[key] === "object") {
                const isSubObjctEqual = !(Object.keys(object1[key]).map(key2 => {
                    if (Object.keys(object1[key]).length !== Object.keys(object2[key]).length) {
                        return false
                    }

                    return object1[key][key2] === object2[key][key2]
                }).includes(false))

                if (isSubObjctEqual) {
                    return true
                }
            }
            return object1[key] === object2[key]
        })
    }).includes(false));

    if (!isEqual) {
        return false;
    }

    return true;
}

export const removeObjectWithoutSpecifiedPropertiesHasValue = (collection, properties) => {
    return (collection.filter(object => {
        const isNumbers =properties.every(property => {
            return ( 
                object[property]
            )
        })
        
        if(!isNumbers){
            return false 
        }

        return true
    }))
}