import React from 'react';

import './log-in-custom-button.styles.scss';
import ButtonSpinner from '../../components/button-spinner/button-spinner.compoenent';

const LogInCustomButton = ({children, isLoading, ...otherProps}) => (
    <button 
        className={`log-in-custom-button ${ isLoading ? 'log-in-custom-button--disabled' : ''}` }  
        {...otherProps} 
        disabled = { isLoading }
    >
        { 
            isLoading ? 
                <ButtonSpinner /> :
                children
        }
    </button>
);

export default LogInCustomButton;