import "./home-body.styles.scss";

import IconSales from "../../assets/icons/increasing.svg"
import IconCost from "../../assets/icons/expense.svg"
import IconProfit from "../../assets/icons/profit.svg"

import HomeNavCard from "../home-nav-card/home-nav-card.component";
import HomeNav from "../home-nav/home-nav.component";
import { getThisWeek } from "../../utils/date.utils";
import { useState } from "react";
import { format } from "date-fns";
import useFetchReport from "../../effects/use-fetch-report.effect";
import { useEffect } from "react";
import { createStructuredSelector } from "reselect";
import { authTokenSelector } from "../../redux/user/user.reselect";
import { selectReportDateRange } from "../../redux/report/report.reselect";
import { setReportDateRange, setReportSummaryData } from "../../redux/report/report.actions";
import { connect } from "react-redux";

const HomeBody = ({
    navItems1,
    navItems2,
    authToken,
    reportDateRange,
    setReportSummaryData,
    isReportNoAccess
}) => {
    const endpoint = "/salesreports/salesbyprofit"

    const thisWeek = getThisWeek();
    const [defaultDateRange, setDefaultDateRange] = useState({
        startDate: format(thisWeek.startDate, "yyyy-MM-dd"),
        endDate: format(thisWeek.endDate, "yyyy-MM-dd"),
    })

    const { data, dataExport, summaryData, totalData, numItems, error, isLoading } = useFetchReport(
        endpoint,
        authToken,
        null,
        0,
        0,
        Object.keys(reportDateRange).length ? reportDateRange : defaultDateRange,
        false,
        null,
        null
    );

    useEffect(() => {
        if (summaryData) {
            setReportSummaryData(summaryData)
        }
    }, [summaryData])

    return (
        <div className="home-body">
            <HomeNav navItems={navItems1} />
            <HomeNav
                navItems={navItems2}
                hasChart={true}
                isLoading={isLoading}
                isReportNoAccess={isReportNoAccess}
            />
            {!isReportNoAccess &&
                <div className="container-fluid home-body__card">
                    <div className="row">
                        <div className="home-body__card__single col-md-4">
                            <HomeNavCard
                                alignCard={`left`}
                                title={`Total Sales`}
                                content={totalData ? totalData.totalSales : null}
                                icon={IconSales}
                            />
                        </div>
                        <div className="home-body__card__single col-md-4">
                            <HomeNavCard
                                alignCard={`center`}
                                title={`Total Cost`}
                                content={totalData ? totalData.costOfGoodsSold : null}
                                icon={IconCost}
                            />
                        </div>
                        <div className="home-body__card__single col-md-4">
                            <HomeNavCard
                                alignCard={`right`}
                                title={`Gross Profit`}
                                content={totalData ? totalData.grossProfit : null}
                                icon={IconProfit}
                            />
                        </div>
                    </div>

                </div>
            }


        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    authToken: authTokenSelector,
    reportDateRange: selectReportDateRange,
});

const mapDispatchToProps = (dispatch) => ({
    setReportDateRange: (data) =>
        dispatch(setReportDateRange(data)),
    setReportSummaryData: (data) =>
        dispatch(setReportSummaryData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeBody);