import React, {useRef, useEffect} from 'react';

const useOutSideClick = (ref, callbackOutsideClick) => {
    useEffect(() => {
        const handleClickOutside = (event) => {
            if(ref.current && !ref.current.contains(event.target)){
                callbackOutsideClick();
            }
        }
        window.addEventListener("click", handleClickOutside);
        return () => {
            window.removeEventListener("click", handleClickOutside)
        }
    }, [ref])
}

export default useOutSideClick;