import React from "react";
import { connect } from "react-redux";
import { setEditData, setModalTemplate, setModalType, setShowEditCustomerGroupModal, setShowEditSupplierGroupModal} from '../../redux/modal/modal.actions';
import { createStructuredSelector } from 'reselect';
import { selectEditUUID, selectModalFormTemplate, selectShowEditCustomerGroupModal, selectShowEditSupplierGroupModal, selectShowEditSupplierModal } from "../../redux/modal/modal.reselect";
import { useEffect } from "react";

import EDIT_SUPPLIER_GROUP_FORM_TEMPLATE from './edit-supplier-group.template';

import ModalContainer from "../modal/modal.container";
import useFetchEdit from "../../effects/use-fetch-edit.effect";
import { authTokenSelector } from "../../redux/user/user.reselect";

const EditSupplierGroup = ({
    showEditSupplierGroup,
    setModalTemplate,
    setModalType,
    formTemplate,
    editUUID,
    authToken,
    setShowEditSupplierGroupModal,
    isFetching,
    setEditData
}) => {
    useEffect(() => {
        setModalType("EDIT_SUPPLIER_GROUP")
        setModalTemplate(EDIT_SUPPLIER_GROUP_FORM_TEMPLATE);
    }, []);
    const endpoint = '/suppliergroups';
    const endpointUrl = `${endpoint}/${editUUID}`;
    const authTokenFromState = authToken;

    const { data, error } = useFetchEdit(endpointUrl, authTokenFromState, showEditSupplierGroup);

    useEffect(() => {
        setEditData(data)
    })
    
    return (
        <div className="edit-supplier-group">
            {
                Object.keys(formTemplate).length > 0 ?
                    <ModalContainer
                        showModal={showEditSupplierGroup}
                        setShowModal={setShowEditSupplierGroupModal}
                        endpointUrl={endpointUrl}
                        size={"SMALL"}
                    />
                    : null
            }
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    showEditSupplierGroup: selectShowEditSupplierGroupModal,
    formTemplate: selectModalFormTemplate,
    editUUID: selectEditUUID,
    authToken: authTokenSelector,
})

const mapDispatchToProps = (disptach) => ({
    setShowEditSupplierGroupModal: () =>
        disptach(setShowEditSupplierGroupModal()),
    setModalTemplate: (formTemplate) =>
        disptach(setModalTemplate(formTemplate)),
    setModalType: (modalType) =>
        disptach(setModalType(modalType)),
    setEditData: (editData) =>
        disptach(setEditData(editData))
})


export default connect(mapStateToProps, mapDispatchToProps)(EditSupplierGroup);