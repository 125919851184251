import { format } from 'date-fns';
import FormTableCalculateDisplay from '../../../components/form-table-calculate/form-table-calculate.component';
import FormTableHeaderCell from '../../../components/form-table-header-cell/form-table-header-cell.component';
import FormTableInlineDelete from '../../../components/form-table-inline-delete/form-table-inline-delete.component';
import FormTableSelect from '../../../components/form-table-select/form-table-select.component';
import FormTableTextField from '../../../components/form-table-text-field/form-table-text-field.component';

export const DESIGNATION_INFO = {
  formTitle: "Designation",
  formTitleForNew: "New Designation",
  path: "/designations",
  listPage: "/designations",
}

export const DESIGNATION_FORM_TEMPLATE = {
  bodySections: {
    basic: {
      sectionType: "basic",
      sectionTypeId: "BASIC",
      expandable: false,
      breakPoint: 1,
      fieldName: "designation",
      fieldItems: [
        {
          label: "Designation Code",
          type: "text",
          name: "designationCode",
          initialFocus: false,
          validationProps: {
            required: "Designation Code is required"
          }
        },
        {
          label: "Designation Name",
          type: "text",
          name: "designationName",
          initialFocus: false,
          validationProps: {
            required: "Designation Name is required"
          }
        }
      ]
    }
  },
  watchFields: []
}
