export const SUPPLIER_GROUPS_COLUMNS = [
    {
        Header: "Supplier Group Code",
        accessor: "supplierGroupCode",
        style: {
            position: "sticky",
            left:0,
            top:0,
            zIndex: 2
        }
    },
    {
        Header: "Supplier Group Name",
        accessor: "supplierGroupName",
    }
]