export const LOCAL_FILTER_FIELDS = [
    {
        label: "Department Code",
        type: "text",
        name: "departmentCode",
    },
    {
        label: "Department Name",
        type: "text",
        name: "departmentName",
    }
]