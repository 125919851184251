import React, { useEffect, useMemo, useState, useRef } from 'react';
import loadash from 'lodash';
import usePrevious from '../../utils/usePrevious';
import {
    useTable,
    useResizeColumns,
    useBlockLayout,
    useSortBy,
    useRowSelect,
    useGlobalFilter,
    useFlexLayout
} from 'react-table';

import './list-table-body.styles.scss';

import ListTable from '../list-table/list-table.component';
import useMoreFetch from '../../effects/use-fetch.effect';
import { connect } from 'react-redux';
import { authTokenSelector } from '../../redux/user/user.reselect';
import { createStructuredSelector } from 'reselect';
import ErrorBox from '../error-box/error-box.component';
import Spinner from '../spinner/spinner.component';
import TableCheckbox from '../table-checkbox/table-checkbox.componenet';
import { selectFilterInput, selectLocalFilterFieldNamesAndValues } from '../../redux/filter/filter.reselect';
import ListLocalFilter from '../list-local-filter/list-local-filter.component';
import { setExportData } from '../../redux/export/export.actions';
import { selectIsRefresh, selectRefresh } from '../../redux/refresh/refresh.reselect';
import { setRefresh } from '../../redux/refresh/refresh.actions';
import { selectDeletedItems, selectIsDeleted } from '../../redux/delete/delete.reselect';
import { setDeactivateCheck, setLoadedData, setTotalNumItems } from '../../redux/list/list.actions';
import { selectDeactivateCheck, selectEditedItem, selectLoadedData, selectLoadedSkip, selectTotalNumItems } from '../../redux/list/list.reselect';
import CheckBox from '../checkbox/checkbox.component';


const ListTableBody = ({
    tableColumns,
    endpoint,
    authToken,
    filterInput,
    localFilterFields,
    localFilterFieldNamesAndValues,
    setExportData,
    setRefresh,
    isRefresh,
    deletedItems,
    setLoadedData,
    loadedData,
    loadedSkip,
    loadedTotalNumItems,
    editedItem,
    deactivateCheck,
    setDeactivateCheck
}) => {
    const columns = useMemo(() => tableColumns, []);

    const endpointUrl = endpoint;
    const limit = 20;

    const dataRef = useRef();
    const skipRef = useRef();
    const totalNumItemsRef = useRef();

    const [skip, setSkip] = useState(0);
    const [numberOfDeletedItems, setNumberOfDeletedItems] = useState(0)
    const [hasMore, setHasMore] = useState(true);
    const { data, dataExport, numItems, error, isLoading } = useMoreFetch(
        endpointUrl,
        authToken,
        setSkip,
        loadedSkip ? loadedSkip : skip,
        limit,
        filterInput,
        localFilterFieldNamesAndValues,
        isRefresh,
        deletedItems,
        setLoadedData,
        loadedData,
        loadedSkip,
        loadedTotalNumItems,
        editedItem,
        deactivateCheck
    );

    const handleDeactivate = (event) => {
        setDeactivateCheck(event.target.checked)
    }

    useEffect(() => {
        if (loadedSkip) {
            setSkip(loadedSkip)
        }
    }, [loadedSkip])

    useEffect(() => {
        if (numItems <= limit) {
            setHasMore(false)
        } else if (data.length >= numItems) {
            setHasMore(false)
        } else {
            setHasMore(true)
        }
    })

    useEffect(() => {
        setExportData(dataExport);
    }, [dataExport])

    useEffect(() => {
        if (isRefresh) {
            setHasMore(true);
            setRefresh(false);
        }
    }, [isRefresh])

    useEffect(() => {
        const numberOfDeletedItems = deletedItems ? deletedItems.length : 0;
        setNumberOfDeletedItems(numberOfDeletedItems)
    }, [deletedItems])

    useEffect(() => {
        dataRef.current = data
    }, [data])

    useEffect(() => {
        skipRef.current = skip
    }, [skip])

    useEffect(() => {
        totalNumItemsRef.current = numItems
    }, [numItems])

    useEffect(() => {
        return () => {
            //do this if only it is going to go its realted edit page
            // setLoadedData({ 
            //     data: dataRef.current, 
            //     skip: skipRef.current, 
            //     totalNumItems: totalNumItemsRef.current 
            // })
        }
    }, [])

    const fetchMoreData = async () => {
        if (data.length >= numItems) {
            setHasMore(false)
        }
        setSkip(prevSkip => (prevSkip - numberOfDeletedItems) + limit);
        setNumberOfDeletedItems(0)
    }

    const defaultColumn = React.useMemo(
        () => ({
            minWidth: 30,
            width: 140,
            maxWidth: 400
        }),
        []
    );

    const tableMethods = useTable({
        columns,
        data,
        defaultColumn
    },
        useGlobalFilter,
        useResizeColumns,
        // useBlockLayout,
        useSortBy,
        useRowSelect,
        useFlexLayout
        // hooks => {
        //     hooks.visibleColumns.push(columns => [
        //         {
        //             id: 'selection',
        //             width: 20,
        //             Header: ({ getToggleAllRowsSelectedProps }) => (
        //                 <span>
        //                     <TableCheckbox {...getToggleAllRowsSelectedProps()} />
        //                 </span>
        //             ),
        //             Cell: ({ row }) => (
        //                 <span>
        //                     <TableCheckbox {...row.getToggleRowSelectedProps()} />
        //                 </span>
        //             ),
        //         },
        //         ...columns,
        //     ])
        // }
    );

    useEffect(() => () => {
        setDeactivateCheck(false);
    }, [])

    return (
        <div className="list-table-body container">
            <div className="list-table-body__header">
                <div className="list-table-body__filter">
                    <ListLocalFilter localFilterFields={localFilterFields} />
                </div>
                <div className="list-table-body__deactivate">
                    <CheckBox
                        handleDeactivate={(event) => handleDeactivate(event)}
                        checked={deactivateCheck}
                    >Include deactivated
                    </CheckBox>
                </div>
            </div>
            <div className="list-table-body__table">
                {isLoading ?
                    <div className='list-table-body__spinner'>
                        <Spinner />
                    </div> :
                    error ?
                        <div className='list-table-body__error'>
                            <ErrorBox
                                error={error}
                                icon={error.status ?
                                    error.status === 404 ? false :
                                        true :
                                    true
                                }
                            />
                        </div> :
                        <ListTable
                            tableMethods={tableMethods}
                            error={error}
                            data={data}
                            dataRef={dataRef.current}
                            skip={skip}
                            skipRef={skipRef.current}
                            totalNumItemsRef={totalNumItemsRef.current}
                            fetchMoreData={fetchMoreData}
                            hasMore={hasMore}
                        />
                }
            </div>
        </div>
    )
};

const mapStateToProps = createStructuredSelector({
    authToken: authTokenSelector,
    filterInput: selectFilterInput,
    localFilterFieldNamesAndValues: selectLocalFilterFieldNamesAndValues,
    isRefresh: selectIsRefresh,
    deletedItems: selectDeletedItems,
    loadedData: selectLoadedData,
    loadedSkip: selectLoadedSkip,
    loadedTotalNumItems: selectTotalNumItems,
    editedItem: selectEditedItem,
    deactivateCheck: selectDeactivateCheck
});

const mapDispatchToProps = (dispatch) => ({
    setExportData: (exportData) =>
        dispatch(setExportData(exportData)),
    setRefresh: (data) =>
        dispatch(setRefresh(data)),
    setLoadedData: (data) =>
        dispatch(setLoadedData(data)),
    setDeactivateCheck: (data) =>
        dispatch(setDeactivateCheck(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(ListTableBody)