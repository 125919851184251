import { createSelector } from "reselect";

const report = (state) => state.report;

// export const selectShowEditSupplierForm = createSelector(
//     [form],
//     form => form.showEditSupplier
// );

// export const selectShowAddPurchaseOrderForm = createSelector(
//     [form],
//     form => form.showAddPurchaseOrder
// );

export const selectEditUUID = createSelector(
    [report],
    report => report.editUUID
);

export const selectFormEdit = createSelector(
    [report],
    report => report.formEdit
);

export const selectFormTableData = createSelector(
    [report],
    report => report.tableData
);

export const selectFormTableLatestData = createSelector(
    [report],
    report => report.tableLatestData
);

export const selectFormTableLatestRows = createSelector(
    [report],
    report => report.tableLatestRows
);

// export const selectFormTableRows = createSelector(
//     [selectFormTableLatestData],
//     tableLatestData => tableLatestData.items && [...tableLatestData.items]
// );

export const selectRemoveTableRow = createSelector(
    [report],
    form => form.removeTableRow
);

export const selectEditData = createSelector(
    [report],
    form => form.editData
);

export const selectFormIsFetching = createSelector(
    [report],
    form => form.isFetching
);

export const selectFormExpandSection = createSelector(
    [report],
    form => form.formExpandSection
);

export const selectReportInfo = createSelector(
    [report],
    form => form.reportInfo
);

export const selectFormType = createSelector(
    [report],
    form => form.formType
);

export const selectReportColumn = createSelector(
    [report],
    report => report.reportColumn
);

export const selectFormValue = createSelector(
    [report],
    form => form.formValues
)

export const selectFormTotalValues = createSelector(
    [report],
    form => form.formTotalValues
);

export const selectFormChangedValues = createSelector(
    [report],
    form => form.formChangedValues
);

export const selectFormErrors = createSelector(
    [report],
    form => form.errorMessage
)

export const selectFormValidationErrors = createSelector(
    [report],
    form => form.formValidationErrors
)

export const selectIsLoading = createSelector(
    [report],
    form => form.isLoading
)

export const selectIsSaved = createSelector(
    [report],
    form => form.isSaved
);

export const selectIsFail = createSelector(
    [report],
    form => form.isFail
);

export const selectErrorMessage = createSelector(
    [report],
    form => form.errorMessage
)

export const selectWindowHeight = createSelector(
    [report],
    form => form.windowHeight
)

export const selectIsEditByFullForm = createSelector(
    [report],
    form => form.isEditByFullForm
)

export const selectSelectedField = createSelector(
    [report],
    form => form.selectedField
)

export const selectSelectedFieldValue = createSelector(
    [report],
    form => form.selectedFieldValue
)

export const selectReportSideBarNav = createSelector(
    [report],
    report => report.reportSideBarNav
)

export const selectReportDateRange = createSelector(
    [report],
    report => report.reportDateRange
)

export const selectReportSideBarNavActiveItem = createSelector(
    [report],
    report => report.reportSideBarNavActiveItem
)

export const selectReportSummaryData = createSelector(
    [report],
    report => report.reportSummaryData
)

export const selectFormProgress= createSelector(
    [report],
    form => form.formProgress
)

export const selectValueEntredBlur= createSelector(
    [report],
    form => form.valueEntredBlur
)





