import "./list-header.styles.scss";

import IconAdd from "../../assets/icons/add-white.svg";

import CustomButton from "../custom-button/custom-button.componenet";
import TitleHeader from "../title-header/title-header.componenet";
import DropDownButton from "../drop-down-button/drop-down-button.componenet";
import DropDownContainer from "../drop-down-container/drop-down-container.componenet";
import ListGlobalFilter from "../list-global-filter/list-global-filter.component";
import ListExport from "../list-export/list-export.compoenent";
import ListPrint from "../list-print/list-print.compoenent";
import ListRefresh from "../list-refresh/list-refresh.component";
import ListDeleteItems from "../list-delete-items/list-delete-items.componenet";

const ListHeader = ({
    buttonAction,
    buttonActionLabel,
    endpoint,
    title,
    isViewOnly
}) => {
    return (
        <div className="list-header container">
            <TitleHeader>{title}</TitleHeader>
            <div className="list-header__tools">
                <div className="list-header__tools__filter-actions">
                    <div className="list-header__tools__filter-actions__global-filter">
                        <ListGlobalFilter
                            endpoint={endpoint}
                        />
                    </div>
                    <ListExport
                        DropDownButton={DropDownButton}
                    />
                    <ListPrint
                        DropDownButton={DropDownButton}
                    />
                    <ListRefresh
                        DropDownButton={DropDownButton}
                    />
                </div>
                {
                    !isViewOnly &&
                    <CustomButton
                        handleClick={buttonAction}
                        icon={IconAdd}
                    >
                        {buttonActionLabel}
                    </CustomButton>
                }
            </div>
        </div>
    )
};


export default ListHeader;