import { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { setReportSideBarNavActiveItem } from "../../redux/report/report.actions";
import { selectReportSideBarNav, selectReportSideBarNavActiveItem } from "../../redux/report/report.reselect";
import "./report-side-bar.styles.scss";

const ReportSideBar = ({
    reportSideBarNav,
    setReportSideBarNavActiveItem,
    reportSideBarNavActiveItem,
    history,
    match
}) => {

    const handleClick = (event, id, pagePath) => {
        event.preventDefault();
        setReportSideBarNavActiveItem(id)
    }

    return (
        <div className="report-side-bar">
            <ul className="report-side-bar__nav">
                {
                    reportSideBarNav.map((item, index) => {
                        return (
                            <li
                                className={`report-side-bar__nav__item`}
                                key={index}
                            >
                                <button
                                    className={`report-side-bar__nav__item__button
                                    ${index === reportSideBarNavActiveItem && "report-side-bar__nav__item__button--active"}`}
                                    onClick={event => handleClick(event, index, item.pagePath)}
                                >{item.text}</button>
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    reportSideBarNav: selectReportSideBarNav,
    reportSideBarNavActiveItem: selectReportSideBarNavActiveItem
})

const mapDispatchToProps = (dispatch) => ({
    setReportSideBarNavActiveItem: (data) =>
        dispatch(setReportSideBarNavActiveItem(data)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportSideBar));