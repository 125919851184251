import React, { useEffect } from 'react';

import { createStructuredSelector } from 'reselect';
import { selectModalFormTemplate, selectShowAddItemTaxModal } from '../../redux/modal/modal.reselect';
import { connect } from 'react-redux';
import ADD_ITEM_TAX_FORM_TEMPLATE from './add-item-tax.template';
import { setModalTemplate, setModalType, setShowAddItemTaxModal } from '../../redux/modal/modal.actions';

import ModalContainer from '../modal/modal.container';

const AddItemTax = ({
    showAddItemTax,
    setModalTemplate,
    setModalType,
    formTemplate,
    setShowAddItemTaxModal
}) => {
    useEffect(() => {
        setModalType("ADD_ITEM_TAX")
        setModalTemplate(ADD_ITEM_TAX_FORM_TEMPLATE);
    }, []);
    
    return (
        <div className="add_item_tax">
            {
                Object.keys(formTemplate).length > 0 ?
                    <ModalContainer
                        showModal={showAddItemTax}
                        setShowModal={setShowAddItemTaxModal}
                        size={"MEDIUM"}
                    /> 
                    : null
            }
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    showAddItemTax: selectShowAddItemTaxModal,
    formTemplate: selectModalFormTemplate
})

const mapDispatchToProps = (disptach) => ({
    setShowAddItemTaxModal: () =>
        disptach(setShowAddItemTaxModal()),
    setModalTemplate: (formTemplate) =>
        disptach(setModalTemplate(formTemplate)),
    setModalType: (modalType) =>
        disptach(setModalType(modalType))
})

export default connect(mapStateToProps, mapDispatchToProps)(AddItemTax);