export const LOCAL_FILTER_FIELDS = [
    {
        label: "Product Code",
        type: "text",
        name: "itemCode",
    },
    {
        label: "Product Name",
        type: "text",
        name: "itemName",
    },
    {
        label: "Category",
        type: "text",
        name: "itemGroupCode",
    },
    {
        label: "Location Code",
        type: "text",
        name: "locationCode",
    }
]