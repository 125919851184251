import React from "react";

import "./detail-header-print.styles.scss";

import TitleHeader from "../title-header/title-header.componenet";
import DetailEdit from "../detail-edit/detail-edit.componenet";
import DropDownButton from "../drop-down-button/drop-down-button.componenet";
import DetailRefresh from "../detail-refresh/detail-refresh.component";
import DetailPrint from "../detail-print/detail-print.componenet";



const DetailHeaderPrint = ({ title, contentRef }) => {
    return (
        <div className="detail-header-print container">
            <TitleHeader>{title}</TitleHeader>
            <div className="detail-header-print__tools">
                <div className="detail-header-print__tools__actions">
                    <DetailRefresh
                        DropDownButton={DropDownButton}
                    />
                    <DetailPrint
                        DropDownButton={DropDownButton}
                        contentRef = {contentRef}
                    />
                </div>
            </div>
        </div>
    )
}

export default DetailHeaderPrint;