import React from "react";

import "./detail-body-section.styles.scss";

import { connect } from "react-redux";
import { createStructuredSelector } from 'reselect';
import { useState } from "react";
import { useEffect } from "react";
import { checkForNonDisplayField } from "../../utils/check-value.utlis";

import DetailBodySectionHeader from "../detail-body-section-header/detail-body-section-header.componenet";
import DetailBodySubsection from "../detail-body-subsection/detail-body-subsection.componenet";
import DetailTable from "../detail-table/detail-table.component";


const DetailBodySection = ({
    sectionData,
    contentRef,
    forPrint,
    a4Height,
    detailTableColumns
}) => {
    const [collapse, setCollapse,] = useState({})

    const handleClick = () => {
        setCollapse(prevState => ({
            ...prevState,
            [sectionData.sectionId]: !collapse[sectionData.sectionId]
        }));
    }

    useEffect(() => {
        setCollapse(prevState => ({
            ...prevState,
            [sectionData.sectionId]: true
        }));

    }, [])


    return (
        <>
            {!sectionData.hasContent && forPrint ?
                null :
                <div className={`detail-body-section ${forPrint ? "detail-body-section--print" : null}`}>
                    <DetailBodySectionHeader
                        title={sectionData.sectionTitle}
                        handleClick={handleClick}
                        collapseSection={collapse[sectionData.sectionId]}
                        forPrint={forPrint}
                    />
                    {collapse[sectionData.sectionId] ?
                        sectionData.hasContent ?
                            !sectionData.isInTable ?
                                sectionData.subSection ?
                                    Object.keys(sectionData).map(key => {
                                        if (!checkForNonDisplayField(key, forPrint)) {
                                            return null
                                        }

                                        return (
                                            <DetailBodySubsection
                                                sectionData={sectionData[key]}
                                                key={key}
                                                subSection={true}
                                                forPrint={forPrint}
                                                a4Height={a4Height}
                                            />
                                        )
                                    }) :
                                    <DetailBodySubsection
                                        sectionData={sectionData}
                                        forPrint={forPrint}
                                        a4Height={a4Height}
                                    />
                                :
                                <DetailTable
                                    sectionData={sectionData}
                                    detailTableColumns={detailTableColumns}
                                />
                            :
                            <div className="detail-body-section__error-empty">
                                {"No " + sectionData.sectionTitle}
                            </div>
                        :
                        null
                    }
                </div>
            }
        </>
    )
}

const mapStateToProps = createStructuredSelector({

});


export default connect(mapStateToProps)(DetailBodySection)