import listActionTypes from "./list.types"

const INITIAL_STATE = {
    listType: null,
    selectedRowsUUID: null,
    data: null,
    skip: null,
    scrollPosition: 0,
    totalNumItems: 0,
    isLoading: false,
    errorMessage: null,
    editedItem: null,
    pagePath: null,
    deactivateCheck: false,
}
const listReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case listActionTypes.SET_LIST_TYPE:
            return {
                ...state,
                listType: action.payload
            }
        case listActionTypes.SET_SELECTED_ROWS_UUID:
            return {
                ...state,
                selectedRowsUUID: action.payload
            }
        case listActionTypes.SET_LOADED_DATA:
            return {
                ...state,
                data: action.payload.data,
                skip: action.payload.skip,
                totalNumItems: action.payload.totalNumItems
            }
        case listActionTypes.SET_SCROLL_POSITION:
            return {
                ...state,
                scrollPosition: action.position
            }
        case listActionTypes.SET_PAGE_PATH:
            return {
                ...state,
                pagePath: action.payload
            }
        case listActionTypes.FETCH_ITEMS_START:
            return {
                ...state,
                isLoading: true
            }
        case listActionTypes.FETCH_ITEMS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                editedItem: action.payload
            }
        case listActionTypes.FETCH_ITEMS_FAILURE:
            return {
                ...state,
                isFail: true,
                isLoading: false,
                errorMessage: action.payload
            }
        case listActionTypes.REMOVE_EDITED_ITEM:
            return {
                ...state,
                editedItem: null
            }
        case listActionTypes.REST_LIST:
            console.log("reset is running")
            return {
                data: null
            }
        case listActionTypes.SET_DEACTIVATE_CHECK:
            return {
                ...state,
                deactivateCheck: action.payload
            }
        default:
            return state;
    }
}

export default listReducer;