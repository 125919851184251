import UserActionTypes from './user.types';
import authServices from '../../services/auth.services';

const setMessage = message => ({
    type: UserActionTypes.SET_USER_MESSAGE,
    payload: message
})

const clearMessage = message => ({
    type: UserActionTypes.CLEAR_USER_MESSAGE
})

const loginStart = () => ({
    type: UserActionTypes.LOGIN_START
})

const loginSuccess = user => ({
    type: UserActionTypes.LOGIN_SUCCESS,
    payload: user
})

const loginFailure = () => ({
    type: UserActionTypes.LOGIN_FAILURE
})

export const setIsLoggedIn = () => ({
    type: UserActionTypes.SET_LOGGED_IN
})

export const loginUser = ({ username, password, rememberMe}) => {
    return async dispatch => {
        try{            
            dispatch(loginStart());
            const userResponse = await authServices.login(username, password, rememberMe);

            if(!userResponse.authToken){
                throw userResponse;
            }

            dispatch(loginSuccess(userResponse));
            dispatch(setMessage(userResponse.message));
            return userResponse;
        } catch(error){
            dispatch(loginFailure());
            dispatch(setMessage(error.message));
        }
    }
}

export const logoutUser = (authToken) => {
    authServices.logout(authToken);
    return {
        type: UserActionTypes.LOGOUT
    }
}

export const setAccessRights = (accessRights) => ({
    type: UserActionTypes.SET_ACCESS_RIGHT,
    payload: accessRights
})





