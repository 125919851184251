import { format } from 'date-fns';

import { displayCellValue, displayCellValueNumberToTwoDecimal } from '../../../utils/column-values.util';

const ENDPOINT = "/purchaseorders";

export const SALES_RECEIPTS_COLUMNS_DOCUMENT = [
  {
    Header: "No.",
    id: "count",
    Cell: ({ row }) => Number(row.id) + 1,
    width: 50,
  },
  {
    Header: "Item Code",
    accessor: "itemCode",
    width: 120,
    Cell: ({ value }) => {
      return displayCellValue(value);
    }
  },
  {
    Header: "Item Name",
    accessor: "itemName",
    width: 200,
    Cell: ({ value }) => {
      return displayCellValue(value);
    }
  },
  {
    Header: "Qty",
    accessor: "qty",
    width: 90,
    Cell: ({ value }) => {
      return displayCellValueNumberToTwoDecimal(value);
    }
  },
  {
    Header: "UoM",
    accessor: "unitOfMeasureCode",
    width: 90,
    Cell: ({ value }) => {
      return displayCellValue(value);
    }
  },
  {
    Header: "Unit Price (ETB)",
    accessor: "unitPrice",
    width: 120,
    Cell: ({ value }) => {
      return displayCellValueNumberToTwoDecimal(value);
    }
  },
  {
    Header: "Subtotal (ETB)",
    accessor: "subTotal",
    width: 120,
    Cell: ({ value }) => {
      return displayCellValueNumberToTwoDecimal(value);
    }
  },
]