import React, { useEffect } from 'react';

import './modal-header.styles.scss';
import RemoveIcon from '../../assets/icons/remove.svg';
import { setIsFail, setModalFromValidationError, setShowAddSupplierModal, submitModalFormFailure } from '../../redux/modal/modal.actions';
import { connect } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { createStructuredSelector } from 'reselect';
import { selectModalFormValue } from '../../redux/modal/modal.reselect';

const ModalHeader = ({ 
    setShowAddSupplierModal, 
    modalTitle,
    setModalFromValidationError,
    formValues,
    setIsFail,
    handleCloseModal
}) => {
    return(
        <div className="modal-headers">
            <h4 className="modal-headers__title">
                {modalTitle}
            </h4>
            <button
                className="modal-headers__action"
                onClick={ handleCloseModal } >
                <img src={RemoveIcon} />
            </button>
        </div>
    )
};

const mapStateToProps = createStructuredSelector({
    formValues: selectModalFormValue,
});

const mapDispatchToProps = dispatch => ({
    setShowAddSupplierModal: () => 
        dispatch(setShowAddSupplierModal()),
    setModalFromValidationError: (errors) =>
        dispatch(setModalFromValidationError(errors)),
    setIsFail: (error) => 
        dispatch(setIsFail(error))
})
  
export default connect(mapStateToProps, mapDispatchToProps)(ModalHeader);
