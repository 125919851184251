import ImgTest from "../../assets/help/img_1.jpg";

export const HELP_DATA = [
    {
        title: "Add a new Supplier",
        body: {
            paragraph: "Before you can buy items from a supplier, you need to add them to your system. To do that, please follow these steps:",
            list: [
                'Go to the “Suppliers” from the Navigation Bar or the Home Navigation',
                'Click “Add new Supplier” button',
                'Complete the form and click “Save”'
            ]
        },
        img: ImgTest
    },
    {
        title: "Edit or View a Supplier",
        body: {
            paragraph: "To edit or view a Supplier, please follow these steps:",
            list: [
                'Navigate to the “Supplier” from the Navigation Bar or the Home Navigation',
                'Select the Supplier you want to View or Edit',
                'Make changes or view the Supplier'
            ]
        },
        img: ImgTest
    },
    {
        title: "Supplier Payment",
        body: {
            paragraph: "The supplier payment details show the history of your transactions with the supplier. The balance reflects the amount that you owe or are owed by the supplier. A positive balance means that you have to pay the supplier, while a negative balance means that the supplier has to pay you. The available credit indicates how much money you can use from the supplier to buy items in the future. To access the supplier payment details, please follow these steps:",
            list: [
                'Navigate to the “Suppliers” from the Navigation Bar or the Home Navigation',
                'Select the Supplier you want to View or Edit',
                'Expand the “Payment Detail” section if it it is collapsed'
            ]
        },
        img: ImgTest
    },
    {
        title: "Add a new Supplier Group",
        body: {
            paragraph: "Before you can add a new supplier, you need to assign them to a Supplier Group. To create a Supplier Group, please follow these steps:",
            list: [
                'Navigate to the “Supplier Groups” from the Navigation Bar or the Home Navigation',
                'Click “Add new Supplier Group” button',
                'Complete the form and click “Save”'
            ]
        },
        img: ImgTest
    },
    {
        title: "Edit or View a Supplier Group",
        body: {
            paragraph: "To edit or view a Supplier Group, please follow these steps:",
            list: [
                'Navigate to the “Supplier Groups” from the Navigation Bar or the Home Navigation',
                'Select the Supplier Group you want to View or Edit',
                'Make Changes or View the Supplier Group'
            ]
        },
        img: ImgTest
    },
    {
        title: "Add a new Purchase Order",
        body: {
            paragraph: "A purchase order is a document that a buyer sends to a supplier to authorize a purchase. A purchase order becomes a contract once the supplier accepts it. To create a new Purchase Order, please follow these steps:",
            list: [
                'Navigate to the “Purchase Orders” from the Navigation Bar or the Home Navigation',
                'Click “Add new Purchase Order” button',
                'Complete the form and click “Save”'
            ]
        },
        img: ImgTest
    },
    {
        title: "Edit or View a Purchase Order",
        body: {
            paragraph: "To edit or view a Purchase Order, please follow these steps:",
            list: [
                'Navigate to the “Purchase Orders” from the Navigation Bar or the Home Navigation',
                'Select the Purchase Order you want to View or Edit',
                'Make Changes or View the Purchase Order'
            ]
        },
        img: ImgTest
    },
    {
        title: "Receive Order",
        body: {
            paragraph: "Receiving items from suppliers will increase the stock level of the inventory. To receive the Items from a Purchase Order, adhere to the following steps:",
            list: [
                'Navigate to the “Purchase Orders” from the Navigation Bar or the Home Navigation',
                'Choose the Purchase Order for which you want to receive the Items',
                'Click on the “Receive” tab in the Sidebar Navigation',
                'Complete the form and click “Save”'
            ]
        },
        img: ImgTest
    },
    {
        title: "Return Purchased Items",
        body: {
            paragraph: "If you are not satisfied with your purchase, you have the option to return it to the supplier. To initiate a return, please follow these steps:",
            list: [
                'Navigate to the “Purchase Orders” from the Navigation Bar or the Home Navigation',
                'Choose the Purchase Order for which you want to return the Items',
                'Click on the “Return” tab in the Sidebar Navigation',
                'Complete the form and click “Save”'
            ]
        },
        img: ImgTest
    },
    {
        title: "Unstock purchase returns",
        body: {
            paragraph: "After you have returned the item, you need to adjust your inventory level accordingly. To do that, please follow these steps:",
            list: [
                'Navigate to the “Purchase Orders” from the Navigation Bar or the Home Navigation',
                'Choose the Purchase Order for which you want to unstock the Items',
                'Click on the “Unstock” tab in the Sidebar Navigation',
                'Complete the form and click “Save”'
            ]
        },
        img: ImgTest
    },
    {
        title: "Purchase Order Documents",
        body: {
            paragraph: "Documents related to the purchase order may need to be printed out. To print them, please follow these steps:",
            list: [
                'Navigate to the “Purchase Orders” from the Navigation Bar or the Home Navigation',
                'Choose the Purchase Order for which you want to print or view documents',
                'Click on the “Print” button',
                'Select the document you want to Print or View'
            ]
        },
        img: ImgTest
    },
    {
        title: "Add a new Item",
        body: {
            paragraph: "To manage your inventory effectively, you need to create an item record for each product you sell. To do that, please follow these steps:",
            list: [
                'Go to “Items” from the Navigation Bar or the Home Navigation',
                'Click “Add new Item” button',
                'Complete the form and click “Save”',
            ]
        },
        img: ImgTest
    },
    {
        title: "Edit or View an Item",
        body: {
            paragraph: "To edit or view an Item, please follow these steps:",
            list: [
                'Navigate to the “Items” from the Navigation Bar or the Home Navigation',
                'Select the Item you want to View or Edit',
                'Make changes or view the Item',
            ]
        },
        img: ImgTest
    },
    {
        title: "Set Beginning Stock",
        body: {
            paragraph: "The beginning stock lets you specify the initial quantity on hand for an item in each location. To set up the beginning stock, please follow these steps:",
            list: [
                'Go to “Items” from the Navigation Bar or the Home Navigation',
                'Choose the Item for which you want to set the beginning stock',
                'Click on the “Beginning Stock” tab in the Sidebar Navigation',
                'Complete the form and click “Save”'
            ]
        },
        img: ImgTest
    },
    {
        title: "Stock Adjustments",
        body: {
            paragraph: "There may be occasions when you need to adjust the inventory manually. To do that, please follow these steps:",
            list: [
                'Navigate to the “Items” from the Navigation Bar or the Home Navigation',
                'Choose the Item for which you want to adjust stock',
                'Click on the “Stock Adjustment” tab in the Sidebar Navigation',
                'Complete the form and click “Save”'
            ]
        },
        img: ImgTest
    },
    {
        title: "Add a new Item Group",
        body: {
            paragraph: "To add a new item, you must first create an Item Group for it. To create an Item Group, please follow these steps:",
            list: [
                'Navigate to the “Item Groups” from the Navigation Bar or the Home Navigation',
                'Click “Add new Item Group” button',
                'Complete the form and click “Save”'
            ]
        },
        img: ImgTest
    },
    {
        title: "Edit or View a Item Group",
        body: {
            paragraph: "To edit or view a Item Group, please follow these steps:",
            list: [
                'Go to the “Item Groups” from the Navigation Bar or the Home Navigation',
                'Select the Item Group you want to View or Edit',
                'Make Changes or View the Item Group'
            ]
        },
        img: ImgTest
    },
    {
        title: "Add a new stock transfer",
        body: {
            paragraph: "Occasionally, we may have to transfer inventory between different locations. To perform this operation, please follow these steps:",
            list: [
                'Navigate to the “Stock Transfers” from the Navigation Bar or the Home Navigation',
                'Click “Add new stock transfer” button',
                'Complete the form and click “Save”'
            ]
        },
        img: ImgTest
    },
    {
        title: "Edit or View a  stock transfer",
        body: {
            paragraph: "To edit or view a stock transfer, please follow these steps:",
            list: [
                'Go to the “Stock Transfers” from the Navigation Bar or the Home Navigation',
                'Select the Stock Transfer you want to View or Edit',
                'Make changes or view the Stock Transfer'
            ]
        },
        img: ImgTest
    },
    {
        title: "Receiving stock transfer",
        body: {
            paragraph: "Stock that has been moved from one location to another location needs to be confirmed by the receiving end. To complete this process, please follow these steps:",
            list: [
                'Go to “Items” from the Navigation Bar or the Home Navigation',
                'Choose the Item for which you want to set the beginning stock',
                'Click on the “Beginning Stock” tab in the Sidebar Navigation',
                'Complete the form and click “Save”'
            ]
        },
        img: ImgTest
    },
    {
        title: "Add a new Unit of Measure",
        body: {
            paragraph: "To add a new item, you must first create a Unit of Measure for it. A unit of measure is a standard amount of a physical quantity that is used to compare and measure other quantities of the same kind. To create an Unit of Measure, please follow these steps:",
            list: [
                'Navigate to the “Unit of Measures” from the Navigation Bar or the Home Navigation',
                'Click “Add new Unit of Measure” button',
                'Complete the form and click “Save”'
            ]
        },
        img: ImgTest
    },
    {
        title: "Edit or View a Unit of Measure",
        body: {
            paragraph: "To edit or view a Unit of Measure, please follow these steps:",
            list: [
                'Go to the ““Unit of Measures” from the Navigation Bar or the Home Navigation',
                'Select the Unit of Measure you want to View or Edit',
                'Make Changes or View the Unit of Measure'
            ]
        },
        img: ImgTest
    },
    {
        title: "Add a new Item Tax",
        body: {
            paragraph: "An Item Tax is required for every item in a purchase order or a sales order. Therefore, you need to have at least one Item Tax in the system. To create a new Item Tax, please follow these steps:",
            list: [
                'Navigate to the “Unit of Measures” from the Navigation Bar or the Home Navigation',
                'Click “Add new Unit of Measure” button',
                'Complete the form and click “Save”'
            ]
        },
        img: ImgTest
    },
    {
        title: "Edit or View an Item Tax",
        body: {
            paragraph: "To edit or view an Item Tax, please follow these steps:",
            list: [
                'Go to the ““Item Taxes” from the Navigation Bar or the Home Navigation',
                'Select the Item Tax  you want to View or Edit',
                'Make Changes or View the Item Tax'
            ]
        },
        img: ImgTest
    },
    {
        title: "Add a new Location",
        body: {
            paragraph: "To monitor the stock levels across various sites, you need to register the locations that the company operates in. Please follow these steps to do so:",
            list: [
                'Navigate to the “Locations” from the Navigation Bar or the Home Navigation',
                'Click “Add new Location” button',
                'Complete the form and click “Save”'
            ]
        },
        img: ImgTest
    },
    {
        title: "Edit or View an Location",
        body: {
            paragraph: "To edit or view a Location, please follow these steps:",
            list: [
                'Go to the ““Locations” from the Navigation Bar or the Home Navigation',
                'Select the Location you want to View or Edit',
                'Make Changes or View the Location'
            ]
        },
        img: ImgTest
    },
    {
        title: "Add a new Customer",
        body: {
            paragraph: "Before you can sell items to a customer, you need to add them to your system. To do that, please follow these steps:",
            list: [
                'Go to the “Customers” from the Navigation Bar or the Home Navigation',
                'Click “Add new Customer” button',
                'Complete the form and click “Save”'
            ]
        },
        img: ImgTest
    },
    {
        title: "Edit or View a Customer",
        body: {
            paragraph: "To edit or view a Customer, please follow these steps:",
            list: [
                'Navigate to the “Customers” from the Navigation Bar or the Home Navigation',
                'Select the Customer you want to View or Edit',
                'Make changes or view the customer'
            ]
        },
        img: ImgTest
    },
    {
        title: "Customer Payment",
        body: {
            paragraph: "The customer payment details show the history of your transactions with the customer. The balance reflects the amount that you owe or are owed by the customer. A positive balance means that the customer has to pay you, while a negative balance means that you have to pay the customer. The available credit indicates how much money the customer can use to buy items in the future from you. To access the customer payment details, please follow these steps:",
            list: [
                'Navigate to the “Customers” from the Navigation Bar or the Home Navigation',
                'Select the Customeryou want to View or Edit',
                'Expand the “Payment Detail” section if it it is collapsed'
            ]
        },
        img: ImgTest
    },
    {
        title: "Add a new Customer Group",
        body: {
            paragraph: "Before you can add a new customer, you need to assign them to a Customer Group. To create a Customer Group, please follow these steps:",
            list: [
                'Go to  the “Customer Groups” from the Navigation Bar or the Home Navigation',
                'Click “Add new Customer Group” button',
                'Complete the form and click “Save”'
            ]
        },
        img: ImgTest
    },
    {
        title: "Edit or View a Customer Group",
        body: {
            paragraph: "To edit or view a Customer Group, please follow these steps:",
            list: [
                'Navigate to the “Customer Groups” from the Navigation Bar or the Home Navigation',
                'Select the Customer Group you want to View or Edit',
                'Make Changes or View the Customer Group'
            ]
        },
        img: ImgTest
    },
    {
        title: "Add a new Sales Order",
        body: {
            paragraph: "A sales order is a document that a seller creates for its own use when processing a customer order. A sales order confirms the sale of goods or services involved in a transaction. To create a new Sales Order, please follow these steps:",
            list: [
                'Go to the “Sales Orders” from the Navigation Bar or the Home Navigation',
                'Click “Add new Sales Order” button',
                'Complete the form and click “Save”'
            ]
        },
        img: ImgTest
    },
    {
        title: "Edit or View a Sales Order",
        body: {
            paragraph: "To edit or view a Sales Order, please follow these steps:",
            list: [
                'Navigate to the “Sales Orders” from the Navigation Bar or the Home Navigation',
                'Select the Sales Order you want to View or Edit',
                'Make Changes or View the Sales Order'
            ]
        },
        img: ImgTest
    },
    {
        title: "Picking",
        body: {
            paragraph: "Picking items from inventory will decrease the stock level of the inventory. To pick items for a Sales Order, please follow these steps:",
            list: [
                'Navigate to the “Sales Orders” from the Navigation Bar or the Home Navigation',
                'Choose the Sales Order for which you want to pick items for',
                'Click on the “Pick” tab in the Sidebar Navigation',
                'Complete the form and click “Save”'
            ]
        },
        img: ImgTest
    },
    {
        title: "Packing",
        body: {
            paragraph: "Occasionally, Packing items in a box may be a required operation to perform. To pack items for a Sales Order, please follow these steps:",
            list: [
                'Go to the “Sales Orders” from the Navigation Bar or the Home Navigation',
                'Choose the Sales Order for which you want to pack items for',
                'Click on the “Pack” tab in the Sidebar Navigation',
                'Complete the form and click “Save”'
            ]
        },
        img: ImgTest
    },
    {
        title: "Shipping",
        body: {
            paragraph: "Shipping packed items may be necessary. To ship a pack, please follow these steps:",
            list: [
                'Go to the “Sales Orders” from the Navigation Bar or the Home Navigation',
                'Choose the Sales Order for which you want to ship packs',
                'Click on the “Ship” tab in the Sidebar Navigation',
                'Complete the form and click “Save”'
            ]
        },
        img: ImgTest
    },
    {
        title: "Return Sold Items",
        body: {
            paragraph: "If the customer is not satisfied with your items, they may have the option to return it to you. To initiate a return, please follow these steps:",
            list: [
                'Navigate to the “Sales Orders” from the Navigation Bar or the Home Navigation',
                'Choose the Sales Order for which you want to return the Items',
                'Click on the “Return” tab in the Sidebar Navigation',
                'Complete the form and click “Save”'
            ]
        },
        img: ImgTest
    },
    {
        title: "Restock sold returns",
        body: {
            paragraph: "After the customer has returned the items, you need to adjust your inventory level accordingly. To do that, please follow these steps:",
            list: [
                'Navigate to the “Sales Orders” from the Navigation Bar or the Home Navigation',
                'Choose the Sales Order for which you want to restock the Items',
                'Click on the “Restock” tab in the Sidebar Navigation',
                'Complete the form and click “Save”'
            ]
        },
        img: ImgTest
    },
    {
        title: "Sales Order Documents",
        body: {
            paragraph: "Documents related to the sales order may need to be printed out. To print them, please follow these steps:",
            list: [
                'Navigate to the “Sales  Orders” from the Navigation Bar or the Home Navigation',
                'Choose the Sales Order for which you want to print or view documents',
                'Click on the “Print” button',
                'Select the document you want to Print or View'
            ]
        },
        img: ImgTest
    },
    {
        title: "Add New account",
        body: {
            paragraph: "Any user who has the access right to the account app can add a new account. To add a new account, please follow these steps:",
            list: [
                'Go to the “Accounts” from drop down of your account name',
                'Click “Add account” button',
                'Complete the form and click “Save”'
            ]
        },
        img: ImgTest
    },
    {
        title: "Edit or View an account",
        body: {
            paragraph: "To edit or view an Account, please follow these steps:",
            list: [
                'Navigate to the “Accounts” from drop down of your account name',
                'Select the Account you want to View or Edit',
                'Make changes or view the Account'
            ]
        },
        img: ImgTest
    },
    {
        title: "Assign access right for an account",
        body: {
            paragraph: "To ensure safe and secure transactions for inventory and orders, assign access rights to each account. To assign access right for an account follow these steps:",
            list: [
                'Navigate to the “Accounts” from drop down of your account name',
                'Choose the Account for which you want to set the access right',
                'Assign the desired access right for the account and click “Save”'
            ]
        },
        img: ImgTest
    },
    {
        title: "Set a company",
        body: {
            paragraph: "The company profile contains information about your company that appears on printed documents. To update the company profile, follow these steps:",
            list: [
                'Navigate to the “Company” from drop down of your account name',
                'Add new or make changes and click “save”',
            ]
        },
        img: ImgTest
    },
    {
        title: "Reports",
        body: {
            paragraph: "Reports provide a clear view of your inventory and orders. To view reports, follow these steps:",
            list: [
                'Select the report you want to see from the Navigation Bar or the Home Navigation',
                'Choose the date range for the report using the “Date Range” option',
                'Reports are also available from Home. Choose the date range for the report using the “Date Range” option'
            ]
        },
        img: ImgTest
    },
]