import React from 'react';

import './log-in.styles.scss';

import Logo from '../../assets/icons/kaliber-logo-login.png';
import LogInForm from '../../components/log-in-form/log-in-form.component';

const LogIn = () => (
    <div className="log-in">
       <div className="container">
           <div className='row align-items-center log-in__wraper'>
                <div className='col-12'>
                    <div className='row justify-content-center'>
                        <div className='col-12 log-in__logo-img-wraper'>
                            <img src={Logo} className="log-in__logo-img"/>
                        </div>
                    </div>
                    <div className='row justify-content-center '>
                        <div className='col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5'>
                            <LogInForm />
                        </div>
                    </div>
                </div>
           </div>
       </div>
    </div>
);

export default LogIn;


