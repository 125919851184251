import React, { useState } from "react";
import { useEffect } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { useFormContext, Controller } from "react-hook-form";
import FormFieldError from "../form-field-error/form-field-error.componenet";
import "./form-table-date-picker.styles.scss"

const FormTableDatePicker = ({
    label,
    type,
    handleChange,
    initialFocus,
    onChange,
    required,
    fieldName,
    rowNumber,
    fieldItemName,
    validationProps,
    fieldIndex,
    sectionTypeId,
    ...otherProps }) => {
    const name = fieldName + '.' + rowNumber + '.' + fieldItemName;
    const { register, formState: { errors }, setFocus, control, setValue } = useFormContext({

    });

    return (
        <div className="form-table-date-picker">
            <Controller
                control={control}
                {...register(name, validationProps)}
                ref={null}
                defaultValue={new Date()}
                render={({
                    field: { onChange, onBlur, value, name, ref, setValue },
                }) => {
                    return (
                        <DatePicker
                            className={`form-table-date-picker__input 
                                ${errors[fieldName]?.[rowNumber]?.[fieldItemName] && "form-table-date-picker__input--error"}`}
                            wrapperClassName="form-table-date-picker__date-view"
                            defaultOptions={true}
                            selected={value}
                            dateFormat="dd/MM/yyyy"
                            todayButton="Today"
                            onSelect={onChange}
                            onChange={onChange}
                            {...otherProps}
                        />
                    )
                }}
            />

            {errors?.[fieldName]?.[rowNumber]?.[fieldItemName] && <FormFieldError errorMessage={errors?.[fieldName]?.[rowNumber]?.[fieldItemName].message} />}
        </div>

    );
};

export default FormTableDatePicker