import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { setAlert, setAlertEndpoint, setUUIDForAlert } from "../../redux/alert/alert.actions";
import { setUUID } from "../../redux/detail/detail.actions";
import { setEditUUID, setShowAddSupplierModal, setShowEdit, setShowEditSupplierModal } from "../../redux/modal/modal.actions";
import { selectModalType } from "../../redux/modal/modal.reselect";

import { createStructuredSelector } from 'reselect';
import { selectListType, selectPagePath } from "../../redux/list/list.reselect";
import { selectDetailPagePath } from "../../redux/detail/detail.reselect";

import TableInlineActionButton from '../../components/table-inline-action-button/table-inline-action-button.componenet';
import DropDownContainer from '../drop-down-container/drop-down-container.componenet';

import IconEdit from '../../assets/icons/edit.svg';
import IconDelete from '../../assets/icons/delete.svg';
import IconPageView from '../../assets/icons/pageview.svg';
import { selectIsEditByFullForm } from "../../redux/form/form.reselect";

const ACTION_DROP_DOWN_ITEMS = [
    {
        id: "details",
        text: "Details",
        icon: IconPageView
    },
    {
        id: "edit",
        text: "Edit",
        icon: IconEdit
    },
    {
        id: "delete",
        text: "Delete",
        icon: IconDelete
    }
]

const ListActions = ({
    history,
    setUUID,
    uuid,
    setEditUUID,
    setAlert,
    setUUIDForAlert,
    setAlertEndpoint,
    endpoint,
    listType,
    setShowEdit,
    detailPagePath,
    pagePath,
    isEditByFullForm
}) => {
    const handleClick = (event) => {
        event.stopPropagation();
        if (event.target.id === "details") {
            setUUID(uuid)
            history.push(detailPagePath);
        } else if (event.target.id === "edit") {
            if(isEditByFullForm){
                return history.push(`${pagePath}/${uuid}`);
            }
            setEditUUID(uuid);
            setShowEdit(listType);

        } else if (event.target.id === "delete") {
            setAlert("DELETE_ITEMS")
            setAlertEndpoint(endpoint)
            setUUIDForAlert(uuid)
        }
    }

    return (
        <DropDownContainer
            dropDownItems={ACTION_DROP_DOWN_ITEMS}
            DropDownButton={TableInlineActionButton}
            dropDwon={true}
            size="small"
            isNotStopPropagation={true}
            handleClick={(event) => { handleClick(event) }}
        />
    )
}

const mapStateToProps = createStructuredSelector({
    listType: selectListType,
    detailPagePath: selectDetailPagePath,
    pagePath: selectPagePath,
    isEditByFullForm: selectIsEditByFullForm
})

const mapDispatchToProps = (dispatch) => ({
    setUUID: (uuid) =>
        dispatch(setUUID(uuid)),
    setEditUUID: (uuid) =>
        dispatch(setEditUUID(uuid)),
    setAlert: (alertType) =>
        dispatch(setAlert(alertType)),
    setAlert: (alertType) =>
        dispatch(setAlert(alertType)),
    setUUIDForAlert: (uuid) =>
        dispatch(setUUIDForAlert(uuid)),
    setAlertEndpoint: (uuid) =>
        dispatch(setAlertEndpoint(uuid)),
    setShowEdit: (listType) =>
        dispatch(setShowEdit(listType))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ListActions))


