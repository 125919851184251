import {createSelector} from 'reselect'

const deleteSelector = (state) => state.delete; 

export const selectDeleteIsLoading = createSelector(
    [deleteSelector],
    alert => alert.isLoading
);

export const selectIsDeleted = createSelector(
    [deleteSelector],
    alert => alert.isDeleted
);

export const selectDeletedItems = createSelector(
    [deleteSelector],
    alert => alert.deletedItems
);