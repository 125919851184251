import React, { useRef } from "react";

import "./detail-print-display.styles.scss"

import DetailBody from "../../components/detail-body/detail-body.componenet";
import { useEffect } from "react";
import { useState } from "react";
import { createStructuredSelector} from "reselect";
import DetailHeaderPrint from "../../components/detail-header-print/detail-header-print.component";
import DetailBodyPrintBoxComponent from "../../components/detail-body-print/detail-body-print.component";
import DetailBodyPrint from "../../components/detail-body-print/detail-body-print.component";
import { selectDetailPrintEndpoint, selectDetailPrintTitle } from "../../redux/detail/detail.reselect";
import { connect } from "react-redux";

const DetailPrintDisplay = ({
    detailPrintTitle,
    detailPrintEndpoint
}) => {
    const contentRef = useRef(null);
    const title = "Supplier"

    return (
        <>
            <DetailHeaderPrint
                title={detailPrintTitle}
                contentRef={contentRef}
            />
            <DetailBodyPrint
                title={detailPrintTitle}
                endpoint={detailPrintEndpoint}
                contentRef={contentRef}
            />
        </>
    )
}

const mapStateToProps = createStructuredSelector({
    detailPrintTitle: selectDetailPrintTitle,
    detailPrintEndpoint: selectDetailPrintEndpoint
});

export default connect(mapStateToProps, null)(DetailPrintDisplay);
