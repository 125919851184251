export const LOCAL_FILTER_FIELDS = [
    {
        label: "Code",
        type: "text",
        name: "customerGroupCode",
    },
    {
        label: "Name",
        type: "text",
        name: "customerGroupName",
    }
]