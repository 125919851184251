const ADD_ITEM_TAX_FORM_TEMPLATE ={
    modalTitle: "New Item Tax",
    path: "/itemtaxes",
    listPage: "/list-item-taxes",
    bodySections: {
      basic: {
        sectionType: "basic",
        sectionTypeId: "basic",
        expandable: false,
        position: "LAST",
        breakPoint: 0,
        fieldName: "itemTax",
        fieldItems: [
          {
            label: "Item Tax Name",
            type: "text",
            name: "itemTax",
            initialFocus: true,
            validationProps: {
              required: "Item tax is required"
            }
          },
          {
            label: "Tax Rate",
            type: "text",
            name: "taxRate",
            initialFocus: false,
            validationProps: {
              required: "Item tax rate is required"
            }
          },
          {
            label: "description",
            type: "textarea",
            name: "description",
            required: false,
            initialFocus: false
          }
        ]
      }
    }
}

export default ADD_ITEM_TAX_FORM_TEMPLATE;