import React from "react";

import DropDownContainer from '../drop-down-container/drop-down-container.componenet';
import IconActionDown from "../../assets/icons/action-down.svg";
import { setAlert, setAlertEndpoint, setUUIDForAlert } from "../../redux/alert/alert.actions";
import { connect } from "react-redux";
import { createStructuredSelector } from 'reselect';
import { selectSelectedRowsUUID } from "../../redux/list/list.reselect";

const ACTION_DROP_DOWN_ITEMS = [
    {
        id: "delete",
        text:"delete"
    }
]

const ListDeleteItems = ({
    DropDownButton,
    endpoint,
    setAlert,
    setAlertEndpoint,
    selectSelectedRowsUUID,
    setUUIDForAlert
}) => {
    const handleClick = (event) => {
        if (event.target.id === "delete") {
            setAlert("DELETE_ITEMS")
            setAlertEndpoint(endpoint)
            setUUIDForAlert(selectSelectedRowsUUID)
        }
    }

    return (
        <DropDownContainer
            DropDownButton={DropDownButton}
            dropDownItems={ACTION_DROP_DOWN_ITEMS}
            icon={IconActionDown}
            dropDwon={true}
            size="medium"
            handleClick={(event) => { handleClick(event) }}
        />
    )
}

const mapStateToProps = createStructuredSelector({
    selectSelectedRowsUUID: selectSelectedRowsUUID
});


const mapDispatchToProps = (dispatch) => ({
    setAlert: (alertType) =>
        dispatch(setAlert(alertType)),
    setAlertEndpoint: (uuid) =>
        dispatch(setAlertEndpoint(uuid)),
    setUUIDForAlert: (uuid) =>
        dispatch(setUUIDForAlert(uuid)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ListDeleteItems)
