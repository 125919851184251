import { displayCellValue } from '../../../utils/column-values.util';

export const SUPPLIERS_COLUMNS = [
    {
        Header: "Supplier Code",
        accessor: "supplierCode",
        style: {
            position: "sticky",
            left:0,
            top:0,
            zIndex: 2
        }
    },
    {
        Header: "Supplier Name",
        accessor: "supplierName",
    },
    {
        Header: "Supplier Tin No",
        accessor: "supplierTinNumber",
    },
    {
        Header: "Supplier Phone Number 1",
        accessor: "supplierPhoneNumber1",
        Cell: ({ value }) => {
            return displayCellValue(value);
         }
    },
    {
        Header: "Supplier Email",
        accessor: "supplierEmail",
        Cell: ({ value }) => {
            return displayCellValue(value);
         }
    },
]