import alertActionType from "./alert.types"

const INITIAL_STATE = {
    isOn: false,
    alertType: null,
    alertUUID: null,
    alertEndpoint: null
}
const alertReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case alertActionType.SET_ALERT:
            return {
                ...state,
                isOn: !state.isOn,
                // alertUUID: !state.isOn ? state.alertUUID : null,
                alertType: action.alertType
            }
        case alertActionType.SET_UUID_FOR_ALERT:
            return {
                ...state,
                alertUUID: action.uuid
            }
        case alertActionType.SET_ENDPOINT_FOR_ALERT:
            return {
                ...state,
                alertEndpoint: action.endpoint
            }
        case alertActionType.RESET_ALERT:
            return {
                ...state,
                isOn: false,
                alertType: null,
                alertUUID: null,
                alertEndpoint: null
            }
        default:
            return state;
    }
}

export default alertReducer;