import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { setShowAddItemGroupModal, setShowAddItemModal, setShowAddItemTaxModal } from '../../redux/modal/modal.actions';

import ListTableBody from '../../components/list-table-body/list-table-body.componenet';
import ListHeader from '../../components/list-header/list-header.component';

import { ITEM_COLUMNS } from './columns-items.data';
import { LOCAL_FILTER_FIELDS } from './local-filter-filters-items.data';
import { useEffect } from 'react';
import { resetList, setListType, setLoadedData } from '../../redux/list/list.actions';
import { setDetailPagePath } from '../../redux/detail/detail.actions';
import { setFilterEmpty } from '../../redux/filter/filter.actions';
import { selectListType } from '../../redux/list/list.reselect';

const LIST_TYPE = "LIST_ITEM";

const TYPE = {
    LIST_ITEM: "LIST_ITEM",
    EDIT_ITEM: "EDIT_ITEM"
}

const ListItems= ({
    setShowAddItemModal,
    setListType,
    setLoadedData,
    setModalType,
    setDetailPagePath,
    setFilterEmpty,
    listType
}) => {
    const endpoint = "/items";

    useEffect(() => {
        if (listType !== TYPE.LIST_ITEM) {
            setLoadedData({ data: null, skip: null, totalNumItems: 0 });
            setFilterEmpty();
        }

        setListType(TYPE.LIST_ITEM);
        setDetailPagePath("/detail-item")
    }, [])

    return (
        <>
            <ListHeader
                buttonAction={setShowAddItemModal}
                buttonActionLabel={"Add Item"}
                endpoint={endpoint}
                title={"Items"}
            />
            <ListTableBody
                tableColumns={ITEM_COLUMNS}
                localFilterFields={LOCAL_FILTER_FIELDS}
                endpoint={endpoint}
            />
        </>
    )
};

const mapStateToProps = createStructuredSelector({
    listType: selectListType,
})

const mapDispatchToProps = (dispatch) => ({
    setShowAddItemModal: () =>
        dispatch(setShowAddItemModal()),
    setListType: (listType) =>
        dispatch(setListType(listType)),
    setLoadedData: (data) =>
        dispatch(setLoadedData(data)),
    setDetailPagePath: (pagePath) =>
        dispatch(setDetailPagePath(pagePath)),
    setFilterEmpty: () =>
        dispatch(setFilterEmpty())
})
export default connect(mapStateToProps, mapDispatchToProps)(ListItems)