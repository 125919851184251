import React, { useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import './list-table.styles.scss';

import IconArrowUpward from '../../assets/icons/arrow_upward.svg';
import IconArrowDownward from '../../assets/icons/arrow_downward.svg';
import Spinner from '../spinner/spinner.component';
import Paragraph from '../paragraph/paragraph.componet';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { setLoadedData, setScrollPosition, setSelectedRowsUUID } from '../../redux/list/list.actions';
import { selectScrollPosition } from '../../redux/list/list.reselect';
import { withRouter } from 'react-router-dom';

const ListTable = ({
    tableMethods,
    data,
    skip,
    fetchMoreData,
    hasMore,
    setSelectedRowsUUID,
    setScrollPosition,
    scrollPosition,
    history,
    match,
    dataRef,
    skipRef,
    totalNumItemsRef,
    setLoadedData
}) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,
        selectedFlatRows,
        state: {
            globalFilter
        },
        setGlobalFilter,
    } = tableMethods;

    const scrollableDiv = useRef();
    const scrollPositionRef = useRef(0);

    const handleOnScroll = () => {
        scrollPositionRef.current = scrollableDiv.current.scrollTop
    }

    const handleRowClick = (uuid) => {
        setLoadedData({
            data: dataRef,
            skip: skipRef,
            totalNumItems: totalNumItemsRef
        })

        history.push(`${history.location.pathname}/${uuid}`)
    }

    useEffect(() => {
        if (scrollPosition && skip !== 0) {
            scrollableDiv.current.scrollTop = scrollPosition;
        }
    }, [])

    useEffect(() => {
        const selectedRows = selectedFlatRows.map(row => row.original.uuid);
        setSelectedRowsUUID(selectedRows)

        return () => {
            setSelectedRowsUUID(null)
        }
    }, [selectedFlatRows])

    useEffect(() => {
        return () => {
            setScrollPosition(scrollPositionRef.current)
        }
    }, [])

    return (
        <div className="scrollable" id="scrollableDiv" ref={scrollableDiv} onScroll={handleOnScroll}>
            <InfiniteScroll
                className="infinite-scroll"
                dataLength={data.length}
                next={fetchMoreData}
                hasMore={hasMore}
                loader={
                    <div className="infinite-scroll__spinner">
                        <Spinner position="center" size="large" />
                    </div>
                }
                scrollableTarget="scrollableDiv"
                endMessage={
                    <Paragraph>
                        No more data, you have reached the end.
                    </Paragraph>
                }

            >
                <table className="list-table" {...getTableProps()}>
                    <thead className="list-table__header">
                        {headerGroups.map(headerGroup => (
                            <tr className="list-table__header__group" {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th
                                        className="list-table__header__item"
                                        {...column.getHeaderProps([

                                            {
                                                className: column.className,
                                                style: column.style
                                            }
                                        ])}
                                    >
                                        <span {...column.getSortByToggleProps()}>
                                            {column.render('Header')}
                                        </span>
                                        <span className="list-table__header__item__sort">
                                            {column.isSorted ?
                                                column.isSortedDesc ?
                                                    <img
                                                        src={IconArrowDownward}
                                                        className="list-table__header__item__sort__icon"
                                                    /> :
                                                    <img
                                                        src={IconArrowUpward}
                                                        className="list-table__header__item__sort__icon"
                                                    />
                                                : ''}
                                        </span>
                                        <span style={{ border: '3px solid coral', width: '3px', height: '40px' }}
                                            {...column.getResizerProps()}
                                            className={`list-table__header__item__resizer 
                                                ${column.isResizing ?
                                                    "ist-table__header__item__resizer--isResizing" : ""
                                                }`}
                                        />
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody className="list-table__body" {...getTableBodyProps()}>
                        {rows.map(row => {
                            prepareRow(row)
                            return (
                                <tr className="list-table__body__row" {...row.getRowProps()} >
                                    {row.cells.map(cell => (
                                        <td
                                            className="list-table__body__row__cell"
                                            {...cell.getCellProps([
                                                {
                                                    className: cell.column.className,
                                                    style: cell.column.style
                                                }
                                            ])}
                                            data-label={cell.render('Header')}
                                            onClick={() => { handleRowClick(row.original.uuid) }}
                                        >
                                            <span className="list-table__body__row__cell__header">
                                                {cell.render('Header')}
                                            </span>
                                            <span className="list-table__body__row__cell__data">
                                                {cell.render('Cell')}
                                            </span>
                                        </td>
                                    ))}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </InfiniteScroll>
        </div>
    )
};

const mapStateToProps = createStructuredSelector({
    scrollPosition: selectScrollPosition
});

const mapDispatchToProps = (dispatch) => ({
    setSelectedRowsUUID: (data) =>
        dispatch(setSelectedRowsUUID(data)),
    setScrollPosition: (position) =>
        dispatch(setScrollPosition(position)),
    setLoadedData: (data) =>
        dispatch(setLoadedData(data))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ListTable));