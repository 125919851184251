import "./help-body-item.styles.scss";

const HelpBodyItem = ({
    item
}) => {
    return (
        <div className="help-body-item">
            <div className="help-body-item__title">
                {item.title}
            </div>
            <div className="help-body-item__body">
                {item.body && item.body.paragraph &&
                    <div className="help-body-item__body__paragrph">
                        {item.body.paragraph}
                    </div>
                }
                {item.body && item.body.list &&
                    <ul className="help-body-item__body__list">
                        {item.body.list.map((single, index) => {
                            return (
                                <li key={index} className="help-body-item__body__list__item">{single}</li>
                            )
                        })}
                    </ul>
                }
            </div>
        </div>
    )
}

export default HelpBodyItem;