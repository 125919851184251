import React, { useState } from "react";
import ReactDOMServer from 'react-dom/server';

import "./detail-body.styles.scss";

import { connect } from "react-redux";
import { createStructuredSelector } from 'reselect';

import { authTokenSelector } from "../../redux/user/user.reselect";
import useFetchDetail from "../../effects/use-fetch-detail";
import { selectUUID } from "../../redux/detail/detail.reselect";

import DetailBodySection from "../detail-body-section/detail-body-section.componenet";
import Spinner from "../spinner/spinner.component";
import ErrorBox from "../error-box/error-box.component";
import { selectIsRefresh } from "../../redux/refresh/refresh.reselect";
import { useEffect } from "react";
import { setRefresh } from "../../redux/refresh/refresh.actions";


import { Provider } from 'react-redux';

import { store } from '../../redux/store';
import { useRef } from "react";
import { setDetailData } from "../../redux/detail/detail.actions";

const DetailBody = ({
    authTokenFromState,
    endpoint,
    uuid,
    setRefresh,
    isRefresh,
    title,
    setDetailData,
    detailTableColumns
}) => {
    const endpointUrl = endpoint + '/' + uuid;
    const { data, error, isLoading } = useFetchDetail(
        endpointUrl,
        authTokenFromState,
        uuid,
        isRefresh
    );

    useEffect(() => {
        if (isRefresh) {
            setRefresh(false);
        }
    }, [isRefresh])

    useEffect(() => {
        setDetailData(data)
    },[data])

    let contentData = null;

    return (
        <div className="detail-body container">
            {isLoading ?

                <div className="detail-body__spinner">
                    <Spinner />
                </div>
                :
                error ?
                    <div className="detail-body__error">
                        <ErrorBox
                            error={error}
                            icon={error.status ?
                                error.status === 404 ? false :
                                    true :
                                true
                            }
                        />
                    </div>
                    :
                    <>
                        {contentData =
                            data.map((sectionData, index) => {
                                return (
                                    <DetailBodySection
                                        key={index}
                                        sectionData={sectionData}
                                        detailTableColumns={detailTableColumns}
                                    />
                                )
                            })}
                    </>

            }
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    authTokenFromState: authTokenSelector,
    uuid: selectUUID,
    isRefresh: selectIsRefresh,
});

const mapDispatchToProps = (dispatch) => ({
    setRefresh: (data) =>
        dispatch(setRefresh(data)),
    setDetailData: (data) =>
        dispatch(setDetailData(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(DetailBody)