import './drop-down.styles.scss';

const DropDown = ({
    dropDownItems,
    size,
    handleClick
}) => (
    <ul className={`action-drop-down 
        ${size === "small" ?
                'action-drop-down--small' :
                size === "medium" ?
                    'action-drop-down--medium' :
                    size === "large" ?
                        'action-drop-down--large' :
                        null
        }`}
    >
        {dropDownItems.map((dropDownItem, index) => (
            <li className="action-drop-down__item" key={index}>
                <img className="action-drop-down__item__icon" src={dropDownItem.icon} />
                <button 
                    className="action-drop-down__item__btn"
                    onClick={handleClick}
                    id={dropDownItem.id}
                >
                    {dropDownItem.text}
                </button>
            </li>
        ))}
    </ul>
);

export default DropDown;