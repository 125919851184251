import { format } from 'date-fns';
import TableCellStatus from '../../components/table-cell-status/table-cell-status.component';
import { displayCellValue, displayCellValueNumberToTwoDecimal } from '../../utils/column-values.util';
// import FormTableCalculateDisplay from '../../../components/form-table-calculate/form-table-calculate.component';
// import FormTableHeaderCell from '../../../components/form-table-header-cell/form-table-header-cell.component';
// import FormTableInlineDelete from '../../../components/form-table-inline-delete/form-table-inline-delete.component';
// import FormTableSelect from '../../../components/form-table-select/form-table-select.component';
// import FormTableTextField from '../../../components/form-table-text-field/form-table-text-field.component';

export const INVENTORY_REPORT_REORDER_BY_ITEM_INFO = {
  reportTitle: "Inventory Reports",
  path: "/inventoryreports",
  subPath: "/inventoryreorder",
  listPage: "/inventory-reports",
  hasChart: false,
  tableHasGrandTotal: false,
}

export const INVENTORY_REPORT_REORDER_BY_ITEM_COLUMN_TEMPLATE = [
  {
    Header: "Item Code",
    accessor: "itemCode",
    Cell: ({ value }) => {
      return displayCellValue(value);
    },
    Footer: 'Grand Total'
  },
  {
    Header: "Item Name",
    accessor: "itemName",
    Cell: ({ value }) => {
      return displayCellValue(value);
    },
  },
  {
    Header: "Location",
    accessor: "locationCode",
    Cell: ({ value }) => {
      return displayCellValue(value);
    },
  },
  {
    Header: "Reorder Level",
    accessor: "reorderLevel",
    Cell: ({ value }) => {
      return displayCellValueNumberToTwoDecimal(value);
    }
  },
  {
    Header: "Qty on Hand",
    accessor: "qtyOnHand",
    Cell: ({ value }) => {
        return displayCellValueNumberToTwoDecimal(value);
    }
  }
]
