import React from 'react';

import './custom-button.styles.scss';

import ButtonSpinner from '../button-spinner/button-spinner.compoenent';

const CustomButton = ({
    children,
    isLoading,
    inverted,
    handleClick,
    icon,
    ...otherProps
}) => (
    <button
        className={`custom-button ${isLoading ?
            'custom-button--disabled' :
            ''}
            ${inverted ?
                'custom-button--inverted' :
                null}`}

        {...otherProps}
        disabled={isLoading}
        onClick = { handleClick }
    >
        {icon ? 
            <img className="custom-button__icon" src={icon} /> :
            null
        }
        { 
            children
        }
    </button>
);

export default CustomButton;
