export const getThisWeek = () => {
    const now = new Date();
    const day = now.getDay()

    const startDate = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate()-day
    );

    const endDate = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() + (6 - day)
    )

    return {
        startDate,
        endDate
    }
}
