import { startCase } from 'lodash';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { createStructuredSelector } from 'reselect';
import { selectReportData, selectReportSummaryData } from '../../redux/report/report.reselect';

const data = [
    {
        name: '27/01/2023',
        totalSales: 1138,
        costOfGoodsSold: 2400,
        grossProfit: 2400,
    },
    {
        name: '24/01/2023',
        totalSales: 13242,
        costOfGoodsSold: 3679.42,
        grossProfit: 9562.58,
    },
];

const ReportLineChart = ({
    reportSummaryData,
    dataKeys
}) => {
    return (
        <ResponsiveContainer width="100%" height={400}>
            <LineChart
                width={500}
                height={300}
                data={reportSummaryData ? reportSummaryData : ""}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis stroke="#666" dataKey="date" />
                <YAxis stroke="#666" />
                <Tooltip />
                <Legend />
                <Line name={startCase(dataKeys[0])} type="monotone" dataKey={dataKeys[0]} stroke="#D88546" activeDot={{ r: 8 }} />
                <Line name={startCase(dataKeys[1])} dataKey={dataKeys[1]} stroke="#32A865" />
            </LineChart>
        </ResponsiveContainer>
    );
}


const mapStateToProps = createStructuredSelector({
    reportSummaryData: selectReportSummaryData
});

export default connect(mapStateToProps)(ReportLineChart)
