const UserActionTypes = {
    LOGIN_START: 'LOGIN_START',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAILURE: 'LOGIN_FAILURE',
    SET_LOGGED_IN: 'SET_LOGGED_IN',
    LOGOUT: 'LOGOUT',
    SET_USER_MESSAGE: 'SET_USER_MESSAGE',
    CLEAR_USER_MESSAGE: 'CLEAR_USER_MESSAGE',
    SET_ACCESS_RIGHT: "SET_ACCESS_RIGHT"
};

export default UserActionTypes;