import './error-box.styles.scss';

import IconErrorBox from '../../assets/icons/error-box.svg';

const ErrorBox = ({ error, icon }) => (
    <div className={`error-box`}>
        {
            icon &&
            <div className="error-box__icon">
                <img src={IconErrorBox} />
            </div>
        }

        <div className="error-box__container">
            <div className="error-box__container__name">{error.name}</div>
            <div className="error-box__container__message">{error.message}</div>
        </div>
    </div>
)

export default ErrorBox;