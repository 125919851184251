import { deactivateActionTypes } from "./deactivate.types";
import deactivateService from "../../services/deactivate.service";

const deactivateStart = () => ({
    type: deactivateActionTypes.DEACTIVATE_START
})

const deactivateSuccess = () => ({
    type: deactivateActionTypes.DEACTIVATE_SUCCESS
})

const deactivateFailure = (errorMessage) => ({
    type: deactivateActionTypes.DEACTIVATE_FAILURE,
    payload: errorMessage
})

export const deactivate = (authTokenFromState, endpoint) => {
    return async dispatch => {
        try{
            console.log()
            dispatch(deactivateStart());
            const response = await deactivateService(authTokenFromState, endpoint);
            dispatch(deactivateSuccess());

            return response;
        } catch(error){
            dispatch(deactivateFailure(error.message))
            throw error;
        }
    }
}

export const setIsFail = (error) => ({
    type: deactivateActionTypes.SET_IS_FAIL,
    payload: error
})

export const setDeletedItems = (items) => ({
    type: deactivateActionTypes.SET_DELETED_ITEMS,
    payload: items
})

export const removeDeletedItems = () => ({
    type: deactivateActionTypes.REMOVE_DELETED_ITEMS,
})

