import { isArrayOfObjectEqual } from "../../utils/array-values.utils";
import { reportActionTypes } from "./report.types";

const INITIAL_STATE = {
    reportInfo: null,
    formType: null,
    reportColumn: null,
    formExpandSection: {},
    formValues: {},
    isLoading: false,
    isSaved: false,
    isFail: false,
    errorMessage: null,
    formValidationErrors: null,
    windowHeight: null,
    editUUID: null,
    formEdit: false,
    isEditByFullForm: false,
    tableData: [],
    tableLatestData: {},
    tableLatestRows: [],
    editData: null,
    isFetching: false,
    removeTableRow: [],
    formTotalValues: null,
    formChangedValues: null,
    selectedField: null,
    selectedFieldValue: null,
    reportSideBarNav: [],
    reportSideBarNavActiveItem: 0,
    formProgress: 0,
    valueEntredBlur: false,
    reportDateRange: {},
    reportSummaryData: []
}

const formReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case reportActionTypes.SET_EDIT_UUID:
            return {
                ...state,
                editUUID: action.payload
            }
        case reportActionTypes.SET_EDIT_DATA:
            return {
                ...state,
                editData: action.payload
            }
        case reportActionTypes.SET_FORM_TABLE_DATA:
            return {
                ...state,
                tableData: action.payload
            }
        case reportActionTypes.SET_FORM_LATEST_DATA:
            return {
                ...state,
                tableLatestData: action.payload
            }
        case reportActionTypes.SET_TABLE_LATEST_ROWS:
            return {
                ...state,
                tableLatestRows: action.payload ? [...action.payload] : []
            }
        case reportActionTypes.SET_IS_FETCHING:
            return {
                ...state,
                isFetching: action.payload
            }
        case reportActionTypes.SET_FORM_EXPAND_SECTION:
            const sectionTypeId = action.sectionTypeId;
            const isExpand = action.isExpand;

            if (isExpand) {
                return {
                    ...state,
                    formExpandSection: {
                        ...state.formExpandSection,
                        [sectionTypeId]: true
                    }
                }
            }

            return {
                ...state,
                formExpandSection: {
                    ...state.formExpandSection,
                    [sectionTypeId]: !state.formExpandSection[sectionTypeId]
                }
            }
        case reportActionTypes.SET_FORM_TYPE:
            return {
                ...state,
                formType: action.payload
            }
        case reportActionTypes.SET_REPORT_INFO:
            return {
                ...state,
                reportInfo: action.payload
            }
        case reportActionTypes.SET_REPORT_COLUMN:
            return {
                ...state,
                reportColumn: action.payload
            }
        case reportActionTypes.SET_FORM_TEMPLATE_ENABLE_FIELD:
            // state.form.formTemplate.bodySections.paymentDetails.fieldItems[1].disabled = false;
            // const disabledField = state.form.formTemplate.bodySections.paymentDetails.fieldItems[1]
            return {
                ...state,
                formInfo: {
                    ...state.formInfo
                },
                // formTemplate: {
                //     ...state.formTemplate
                // }
                // formTemplate: {
                //     ...state.formTemplate
                //     // bodySections: {
                //     //     ...state.formTemplate.bodySections,
                //     //     paymentDetails:{
                //     //         ...state.formTemplate.bodySections.paymentDetails,
                //     //         fieldItems: [
                //     //             ...state.formTemplate.bodySections.paymentDetails.fieldItems,
                //     //             disabledField
                //     //         ]
                //     //     }
                //     // }
                // }
            }
        case reportActionTypes.SET_FORM_EDIT:
            return {
                ...state,
                formEdit: action.payload
            }
        case reportActionTypes.SUBMIT_FORM_START:
            return {
                ...state,
                isLoading: true
            }
        case reportActionTypes.SUBMIT_FORM_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true
            }
        case reportActionTypes.SUBMIT_FORM_FAILURE:
            return {
                ...state,
                isFail: true,
                isLoading: false,
                errorMessage: action.payload
            }
        case reportActionTypes.SET_IS_FAIL:
            return {
                ...state,
                isFail: false,
                errorMessage: action.payload
            }
        case reportActionTypes.RESET_ERROR_MESSAGE_AND_IS_FAIL:
            return {
                ...state,
                errorMessage: null,
                isFail: false
            }
        case reportActionTypes.SET_FORM_VALIDATION_ERROR:
            return {
                ...state,
                formValidationErrors: action.payload
            }
        case reportActionTypes.SET_WINDOW_HEIGHT:
            return {
                ...state,
                windowHeight: action.payload
            }
        case reportActionTypes.ADD_ROW_FORM_TABLE:
            const newTableDate = state.tableData.push(state.tableData[0]);

            return {
                ...state,
                tableData: newTableDate
            }
        case reportActionTypes.REMOVE_ROW_FORM_TABLE:
            return {
                ...state,
                removeTableRow: action.payload
            }
        case reportActionTypes.SET_FORM_TOTAL_VALUES:
            return {
                ...state,
                formTotalValues: action.payload
            }
        case reportActionTypes.SET_FORM_CHANGED_VALUES:
            return {
                ...state,
                formChangedValues: action.payload
            }
        case reportActionTypes.SET_IS_EDIT_FULL_FORM:
            return {
                ...state,
                isEditByFullForm: action.payload
            }
        case reportActionTypes.SET_SELECTED_FIELD:
            return {
                ...state,
                selectedField: action.payload
            }
        case reportActionTypes.SET_SELECTED_FIELD_VALUE:
            return {
                ...state,
                selectedFieldValue: action.payload
            }
        case reportActionTypes.SET_REPORT_SIDE_BAR_NAV:
            return {
                ...state,
                reportSideBarNav: action.payload
            }
        case reportActionTypes.SET_REPORT_SIDE_BAR_NAV_ACTIVE_ITEM:
            return {
                ...state,
                reportSideBarNavActiveItem: action.payload
            }
        case reportActionTypes.SET_FORM_PROGRESS:
            return {
                ...state,
                formProgress: action.payload
            }
        case reportActionTypes.SET_REMOVE_REMOVE_TABLE_ROW:
            return {
                ...state,
                removeTableRow: []
            }
        case reportActionTypes.SET_VALUE_ENTERED_BLUR:
            return {
                ...state,
                valueEntredBlur: action.payload
            }
        case reportActionTypes.SET_REPORT_DATE_RANGE:
            return {
                ...state,
                reportDateRange: action.payload
            }
        case reportActionTypes.SET_REPORT_SUMMARY_DATA:
            return {
                ...state,
                reportSummaryData: action.payload
            }
        default:
            return state;
    }
}

export default formReducer;