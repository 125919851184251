import react from 'react';

import './button-spinner.styles.scss';
import ButtonSpinnerImg from '../../assets/icons/button-spinner.svg'

const ButtonSpinner = () => (
    <span className='button-spinner'>
        <img className='button-spinner__img' src= { ButtonSpinnerImg } alt="spinner" />
    </span>
);

export default ButtonSpinner; 