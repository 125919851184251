import React, { useEffect } from 'react';

import './form-body-section.styles.scss';

import IconExpand from '../../assets/icons/expand-arrow-modal.svg';
import FormTextarea from '../form-textarea/form-textarea.componenet';
import SelectField from '../select-field/select-field.componenet';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { selectModalEdit, selectModalFormValue } from '../../redux/modal/modal.reselect';
import {
    setIsFormFetching, setFormExpandSection
} from '../../redux/form/form.actions';

import FormField from '../form-field/form-field.component';
import AddRemoveButton from '../add-remove-button/add-remove-button.componenet';
import { authTokenSelector } from '../../redux/user/user.reselect';
import DatePickerField from '../date-picker-field/date-picker-field.component';
import { selectEditData, selectFormChangedValues, selectFormEdit, selectFormExpandSection, selectFormTotalValues } from '../../redux/form/form.reselect';
import { useState } from 'react';
import { selectIsRefresh } from '../../redux/refresh/refresh.reselect';
import { setRefresh } from '../../redux/refresh/refresh.actions';
import { method } from 'lodash';
import CheckBox from '../checkbox/checkbox.component';
import CheckBoxForm from '../checkbox-form/checkbox-form.component';

const FormBodySection = ({
    formExpandSection,
    setFormExpandSection,
    setModalFieldValue,
    modalFormValues,
    addMorePart,
    removePart,
    setIsFormFetching,
    authToken,
    bodySection: {
        sectionType,
        sectionTypeId,
        fieldName,
        expandable,
        isExpand,
        fieldItems,
        addButton,
        multiForm,
        mutliFormTitle,
        position,
        breakPoint
    },
    editSectionData,
    formTotalValues,
    formChangedValues,
    formEdit,
    editData,
    isRefresh,
    setRefresh,
    methods,
    isViewOnly
}) => {
    const { setValue, reset } = useFormContext();

    const { fields, append, remove } = useFieldArray({
        name: fieldName
    });

    let partNumber = null;
    const part = "part";
    const handleChange = (event, partNumber) => {
        setModalFieldValue(
            sectionTypeId,
            part + (partNumber),
            event.target.name,
            event.target.value
        )
    }
    const handleSelectChange = (value, action, partNumber) => {
        setModalFieldValue(
            sectionTypeId, part + (partNumber),
            action.name,
            value
        )
    }
    const handleSectionExpand = (event) => {
        event.preventDefault();
        setFormExpandSection(event.target.dataset.sectiontypeid);
    }
    const handleAddMore = (event) => {
        event.preventDefault();
        addMorePart(sectionTypeId, fields[0]);
    }
    const handleRemove = (event) => {
        event.preventDefault();
        removePart(sectionTypeId, part + (partNumber));
    }
    const renderForm = (field, partNumber) => {
        switch (field.type) {
            case 'text':
            case 'email':
            case 'password':
                return (
                    <FormField
                        label={field.label}
                        type={field.type}
                        initialFocus={field.initialFocus}
                        validationProps={field.isNoEditValidation && formEdit ? {} : field.validationProps}
                        fieldName={fieldName}
                        partNumber={partNumber}
                        fieldItemName={field.name}
                        defaultValue={field.defaultValue}
                        disabled={field.disabled}
                    />)
            case 'textarea':
                return (
                    <FormTextarea
                        label={field.label}
                        type={field.type}
                        initialFocus={field.initialFocus}
                        validationProps={field.validationProps}
                        fieldName={fieldName}
                        partNumber={partNumber}
                        fieldItemName={field.name}
                        disabled={field.disabled}
                    />
                )
            case 'select':
                return (
                    <SelectField
                        label={field.label}
                        type={field.type}
                        required={field.required}
                        initialFocus={field.initialFocus}
                        validationProps={field.validationProps}
                        fieldName={fieldName}
                        partNumber={partNumber}
                        fieldItemName={field.name}
                        authToken={authToken}
                        endpointUrl={field.url}
                        mutliselect={field.muliselect}
                        hasDefaultValue={field.hasDefaultValue}
                        defaultValueUrl={field.defaultValueUrl}
                        defaultValue={field.defaultValue}
                        disabled={field.disabled}
                    />
                )
            case 'date':
                return (
                    <DatePickerField
                        label={field.label}
                        type={field.type}
                        initialFocus={field.initialFocus}
                        validationProps={field.validationProps}
                        fieldName={fieldName}
                        partNumber={partNumber}
                        fieldItemName={field.name}
                        disabled={field.disabled}
                    />)
            case 'checkbox':
                return (
                    <CheckBoxForm
                        label={field.label}
                        type={field.type}
                        initialFocus={field.initialFocus}
                        validationProps={field.validationProps}
                        fieldName={fieldName}
                        partNumber={partNumber}
                        fieldItemName={field.name}
                        disabled={field.disabled}
                    />)
            default:
                break;
        }
    }

    const [subSectionCount, setSubSectionCount] = useState(0)
    useEffect(() => {
        if (formEdit && editSectionData &&
            (subSectionCount < (editSectionData.length))) {
            append({ ...editSectionData[subSectionCount] }, {
                shouldFocus: true,
            });
            return setSubSectionCount(subSectionCount + 1)
        }
    }, [subSectionCount])

    useEffect(() => {
        if (!formEdit) {
            append({}, {
                shouldFocus: false,
            });
        }
    }, [])

    useEffect(() => {
        setIsFormFetching(false)
    }, [])

    useEffect(() => {
        if (isExpand) {
            setFormExpandSection(sectionTypeId, true);
        }
    }, [isExpand])

    useEffect(() => {
        if (formTotalValues && fieldName === "total") {
            Object.entries(formTotalValues).map(([key, value]) => {
                const name = `total.${0}.${key}`
                setValue(name, value)
            })
        }
    }, [formTotalValues])

    useEffect(() => {
        if (formChangedValues && fieldName === "payment") {
            Object.keys(formChangedValues).map((key) => {
                formChangedValues[key].map((object, index) => {
                    Object.entries(object).map(([field, value]) => {
                        const name = `${key}.${index}.${field}`
                        setValue(name, value)
                    })
                })
            })
        }
    }, [formChangedValues])

    useEffect(() => {
        return () => {
            reset({});
        }
    }, [])

    return (
        <div
            className={`form-body-section ${sectionTypeId !== 'BASIC' ?
                'form-body-section--margin-top-5' :
                null}
                ${position === 'LAST' ?
                    'form-body-section--last' :
                    null}
                `}
        >
            {
                sectionTypeId !== 'BASIC' ?
                    <div className="form-body-section__header">
                        <button
                            className="form-body-section__header__title-action"
                            data-sectiontypeid={sectionTypeId}
                            id={sectionTypeId}
                            onClick={expandable ? handleSectionExpand : (event) => event.preventDefault()}>
                            <h5 className="form-body-section__header__title-action__title">{sectionType}</h5>
                            {expandable ? <img
                                className={`form-body-section__header__title-action__img
                                        ${formExpandSection[sectionTypeId] ?
                                        'form-body-section__header__title-action__img--expand' :
                                        null}`}
                                src={IconExpand} alt="expand icon" />
                                :
                                null
                            }
                        </button>
                    </div> :
                    null
            }
            <div className={`form-body-section__sub-section
                    ${sectionTypeId !== 'BASIC' ?
                    formExpandSection[sectionTypeId] ?
                        'form-body-section__sub-section--expand' :
                        'form-body-section__sub-section--collapse' :
                    null}`}
            >
                <div className="form-body-section__body">
                    {
                        fields.map((field, index) => {
                            partNumber = index;

                            const fieldLength = fieldItems.length;
                            const col1Fields = fieldItems.slice(0, breakPoint)
                            const col2Fields = fieldItems.slice(breakPoint, fieldLength + 1)

                            return (
                                <div key={field.id} >
                                    {
                                        index > 0 &&
                                        <div className="form-body-section__body__parts__separator" />
                                    }
                                    {
                                        multiForm === true ?
                                            <div className="form-body-section__body__sub-header">
                                                <h6 className="form-body-section__body__sub-header__title">{mutliFormTitle} {partNumber + 1}</h6>
                                            </div> :
                                            null
                                    }
                                    <div className="container-fluid">
                                        <div className="row form-body-section__body__parts">
                                            <div className={`col-md-6`}>
                                                <div className="row">
                                                    {col1Fields.map((fieldItem, fieldIndex) => (
                                                        <div
                                                            className={`form-body-section__body__parts__item  ${fieldItem.size === 'small' ?
                                                                'col-6  col-md-6' :
                                                                'col-md-12'
                                                                }`}
                                                            key={fieldItem.name}
                                                        >
                                                            {renderForm(fieldItem, partNumber)}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className={`col-md-6`}>
                                                <div className="row" >
                                                    {col2Fields.map((fieldItem, fieldIndex) => (
                                                        <div
                                                            className={`form-body-section__body__parts__item  ${fieldItem.size === 'small' ?
                                                                'col-6  col-md-6' :
                                                                'col-md-12'
                                                                }`}
                                                            key={fieldItem.name}
                                                        >
                                                            {renderForm(fieldItem, partNumber)}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-body-section__body__buttons">
                                        {
                                            partNumber > 0 && <AddRemoveButton
                                                handleClick={(event) => { event.preventDefault(); remove(index); }}
                                                type="remove"
                                            > Remove </AddRemoveButton>
                                        }
                                    </div>
                                </div>)
                        })
                    }
                </div>
                {
                   !isViewOnly && multiForm === true ?
                        <div className={`row form-body-section__footer`}>
                            {
                                addButton && <AddRemoveButton
                                    handleClick={(event) => { event.preventDefault(); append({}) }}
                                    type="add"
                                > Add </AddRemoveButton>
                            }
                        </div> :
                        false
                }

            </div>
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    formExpandSection: selectFormExpandSection,
    modalFormValues: selectModalFormValue,
    authToken: authTokenSelector,
    formTotalValues: selectFormTotalValues,
    formChangedValues: selectFormChangedValues,
    formEdit: selectFormEdit,
    isRefresh: selectIsRefresh
});

const mapDispatchToProps = (dispatch) => ({
    setFormExpandSection: (sectionTypeId, isExpand) =>
        dispatch(setFormExpandSection(sectionTypeId, isExpand)),
    setIsFormFetching: (isFetching) =>
        dispatch(setIsFormFetching(isFetching)),
    setRefresh: (isFetching) =>
        dispatch(setRefresh(isFetching)),
})

export default connect(mapStateToProps, mapDispatchToProps)(FormBodySection);