import React, { useRef } from "react";

import { useEffect } from "react";
import { useState } from "react";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import DocumentHeader from "../../../components/document-header/document-header.componenet";
import DocumentBody from "../../../components/document-body/document-body.componenet";
import { SALES_ORDERS_COLUMNS_DOCUMENT } from "./sales-orders-columns-document.data";
import useFetchDocument from "../../../effects/use-fetch-document";
import { withRouter } from "react-router-dom";
import { authTokenSelector } from "../../../redux/user/user.reselect";
import { selectIsRefresh } from "../../../redux/refresh/refresh.reselect";

const SalesOrderDocument = ({
    authTokenFromState,
    match,
    isRefresh
}) => {
    const contentRef = useRef(null);
    const title = "Sales Order"
    const path = "/salesorders";

    const endpoint = `${path}/doucments/${match.params.uuid}`;
    const { data, error, isLoading } = useFetchDocument(
        endpoint,
        authTokenFromState,
        isRefresh
    );

    const [dataIsReady, setDataIsReady] = useState(false);
    const [dataForDoucment, setDataForDoucment] = useState(null);
    useEffect(() => {
        setDataForDoucment({
            header: [
                data.company,
                data.salesOrderSummary
            ],
            body: {
                sections: [
                    data.customerWithBillingAddressSummary,
                    data.customerWithShippingAddressSummary,
                    data.deliveryDate,
                    data.shippingMethodSummary
                ],
                table: data.items,
                total: data.total
            }
        })
        setDataIsReady(true)
    }, [data])

    return (
        <>{dataIsReady &&
            <>
                <DocumentHeader
                    title={title}
                    contentRef={contentRef}
                />
                <DocumentBody
                    title={title}
                    endpoint={endpoint}
                    contentRef={contentRef}
                    data={dataForDoucment}
                    tableColumns={SALES_ORDERS_COLUMNS_DOCUMENT}
                    tableData={data.items}
                    error={error}
                    isLoading={isLoading}
                />
            </>
        }

        </>
    )
}

const mapStateToProps = createStructuredSelector({
    authTokenFromState: authTokenSelector,
    isRefresh: selectIsRefresh,
});

export default withRouter(connect(mapStateToProps, null)(SalesOrderDocument));
