import React from "react";
import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { connect } from "react-redux";
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { createStructuredSelector } from "reselect";
import fetchSelect from "../../effects/fetch-select";
import { setSelectedField } from "../../redux/form/form.actions";
import { authTokenSelector } from "../../redux/user/user.reselect";
import FormFieldError from "../form-field-error/form-field-error.componenet";
import './form-table-select.styles.scss';

const FormTableSelect = ({
    label,
    type,
    handleChange,
    initialFocus,
    onChange,
    required,
    fieldName,
    rowNumber,
    fieldItemName,
    validationProps,
    authToken,
    endpointUrl,
    selectEdit,
    mutliselect,
    disabled,
    setSelectedField,
    ...otherProps
}) => {
    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            fontSize: "14px",
            backgroundColor: state.isSelected ? "#F4F5F6" : "",
            borderRadius: "7px",
            marginTop: "3px",
            textTransform: "capitalize",
            color: state.isSelected ? "#111" : null,
            "&:hover": {
                backgroundColor: "#F4F5F6",
            },
        }),
        control: (styles) => ({
            ...styles,
            fontFamily: '"Roboto", sans-serif',
            border: '1px solid #F4F5F6',
            minHeight: '37px',
            backgroundColor: '#F4F5F6',
            textIndent: '10px',
            borderRadius: '0px',
            fontSize: '14px',
            textTransform: "capitalize",
            boxShadow: "none",
            "&:focus-within": {
                borderColor: "#3084B7"
            }
        }),
        indicatorSeparator: (styles) => ({ display: 'none' }),
        singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.7 : 1;
            const color = state.isDisabled ? '#000' : "";
            const transition = 'opacity 300ms';
            return {
                ...provided,
                opacity,
                transition,
                color
            };
        },
    }

    const { control, register, formState: { errors } } = useFormContext();
    const name = fieldName + '.' + rowNumber + '.' + fieldItemName;

    const loadOptions = (inputValue) => {
        return new Promise((resolve, reject) => {
            resolve(fetchSelect(endpointUrl, authToken, inputValue))
        });
    };

    const onChangeSelect = (value) => {
        const selectedField = {
            name,
            value
        }

        setSelectedField(selectedField);
    }

    useEffect(() => {
        return () => {
            setSelectedField(null)
        }
    }, [])

    return (
        <div className='form-table-select'>
            <Controller
                control={control}
                {...register(name, validationProps)}
                ref={null}
                defaultValue={''}
                render={({
                    field: { onChange, onBlur, value, name, ref },
                }) => {
                    return (
                        <AsyncSelect
                            className={`select-field__select ${errors[fieldName]?.[rowNumber]?.[fieldItemName] && 'select-field__select--error'}`}
                            styles={customStyles}
                            defaultOptions={true}
                            cacheOptions={true}
                            value={value}
                            onChange={(value) => {
                                onChangeSelect(value);
                                onChange(value)
                            }}
                            loadOptions={loadOptions}
                            isMulti={mutliselect}
                            menuPortalTarget={document.body}
                            menuPlacement="auto"
                            isDisabled={disabled}
                        />
                    )
                }}
            />
            {errors?.[fieldName]?.[rowNumber]?.[fieldItemName] && <FormFieldError errorMessage={errors?.[fieldName]?.[rowNumber]?.[fieldItemName].message} />}
        </div>
    )
};

const mapStateToProps = createStructuredSelector({
    authToken: authTokenSelector,
});

const mapDispatchToProps = (dispatch) => ({
    setSelectedField: (data) =>
        dispatch(setSelectedField(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(FormTableSelect);