import { formatNumber } from "libphonenumber-js";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectFormTableLatestData, selectFormTableLatestRows } from "../../redux/form/form.reselect";
import { formatNumberToTwoDecimal, stringToNumber } from "../../utils/format-number.util";

import "./form-table-calculate.styles.scss";

const FormTableCalculateDisplay = ({
    fieldName,
    rowNumber,
    calculateType,
    valuesToCalculate,
    formTableLatesRow
}) => {
    const calculate = () => {
        switch (calculateType) {
            case "same":
                const sameValue = valuesToCalculate.map(value => {
                    return (
                        formTableLatesRow.length &&
                            formTableLatesRow[rowNumber] &&
                            formTableLatesRow[rowNumber][value] ?
                            formTableLatesRow[rowNumber][value]
                            : 0
                    )
                })

                return sameValue;
            case "multiply":
                const actualValues = valuesToCalculate.map(value => {
                    return (
                        formTableLatesRow.length &&
                            formTableLatesRow[rowNumber] &&
                            formTableLatesRow[rowNumber][value] ?
                            formTableLatesRow[rowNumber][value]
                            : 0
                    )
                })

                if (actualValues) {
                    return actualValues.reduce((prevValue, value) => {
                        return prevValue = prevValue * stringToNumber(value)
                    }, 1)
                }

                return null
        }
    }

    return (
        <div className="form-table-calculate">
            {
                formatNumberToTwoDecimal(calculate())
            }
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    formTableLatesRow: selectFormTableLatestRows
})

export default connect(mapStateToProps)(FormTableCalculateDisplay)