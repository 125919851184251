import { format } from 'date-fns';

import ListActions from '../../components/list-actions/list-actions.componenet';
import ListTableMainCell from '../../components/list-table-main-cell/list-table-main-cell.component';
import { displayCellValue, displayCellValueNumberToTwoDecimal } from '../../utils/column-values.util';

const ENDPOINT = "/items";

export const ITEM_COLUMNS = [
    {
        Header: "Item Code",
        accessor: "itemCode",
        style: {
            position: "sticky",
            left: 0,
            top: 0,
            zIndex: 2
        },
        Cell: ({ value, row }) => {
            const uuid = row.original.uuid;
            return (
                <ListTableMainCell
                    value={value}
                    uuid={uuid}
                />
            )
        }
    },
    {
        Header: "Item Name",
        accessor: "itemName"
    },
    {
        Header: "Unit of Measure",
        accessor: "unitOfMeasureCode",
        Cell: ({ value }) => {
            return displayCellValue(value);
        }
    },
    {
        Header: "Qty on Hand",
        accessor: "totalQtyOnHand",
        Cell: ({ value }) => {
            return displayCellValueNumberToTwoDecimal(value);
        }
    },
    {
        Header: "Unit Selling Price (ETB)",
        accessor: "unitSellingPrice",
        Cell: ({ value }) => {
            return displayCellValueNumberToTwoDecimal(value);
        }
    },
    {
        Header: "Total Value",
        accessor: "totalValue",
        Cell: ({ value }) => {
            return displayCellValueNumberToTwoDecimal(value);
        }
    },
    {
        Header: "Created At",
        accessor: "createdAt",
        Cell: ({ value }) => (
            format(new Date(value), 'dd/MM/yyyy')
        )
    },
    {
        id: "action",
        width: 70,
        Cell: ({ row }) => {
            const uuid = row.original.uuid;
            return (<ListActions
                uuid={uuid}
                endpoint={ENDPOINT}
            />)
        }
    }
]