import React, { useEffect } from 'react';

import { createStructuredSelector } from 'reselect';
import { selectModalFormTemplate, selectModalTitle, selectShowAddSupplierGroupModal, selectShowAddSupplierModal, selectShowSupplierModal } from '../../redux/modal/modal.reselect';
import { connect } from 'react-redux';
import ADD_SUPPLIER_GROUP_FORM_TEMPLATE from './add-supplier-group.template.js';
import { setModalTemplate, setModalType, setShowAddSupplierGroupModal, setShowAddSupplierModal } from '../../redux/modal/modal.actions';

import ModalContainer from '../modal/modal.container';

const AddSupplierGroup = ({
    showAddSupplierGroup,
    setModalTemplate,
    setModalType,
    formTemplate,
    setShowAddSupplierGroupModal
}) => {
    useEffect(() => {
        setModalType("ADD_SUPPLIER_GROUP")
        setModalTemplate(ADD_SUPPLIER_GROUP_FORM_TEMPLATE);
    }, []);
    
    return (
        <div className="add_supplier_group">
            {
                Object.keys(formTemplate).length > 0 ?
                    <ModalContainer
                        showModal={showAddSupplierGroup}
                        setShowModal={setShowAddSupplierGroupModal}
                        size={"SMALL"}
                    /> 
                    : null
            }
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    showAddSupplierGroup: selectShowAddSupplierGroupModal,
    formTemplate: selectModalFormTemplate
})

const mapDispatchToProps = (disptach) => ({
    setShowAddSupplierGroupModal: () =>
        disptach(setShowAddSupplierGroupModal()),
    setModalTemplate: (formTemplate) =>
        disptach(setModalTemplate(formTemplate)),
    setModalType: (modalType) =>
        disptach(setModalType(modalType))
})

export default connect(mapStateToProps, mapDispatchToProps)(AddSupplierGroup);