export const CUSTOMER_GROUP_INFO = {
  formTitle: "Customer Group",
  formTitleForNew: "New Customer Group",
  path: "/customergroups",
  listPage: "/customer-groups",
}

export const CUSTOMER_GROUP_FORM_TEMPLATE = {
  bodySections: {
    basic: {
      sectionType: "basic",
      sectionTypeId: "BASIC",
      expandable: false,
      breakPoint: 1,
      fieldName: "customerGroup",
      fieldItems: [
        {
          label: "Customer Group Code",
          type: "text",
          name: "customerGroupCode",
          initialFocus: false,
          validationProps: {
            required: "Customer group code is required"
          }
        },
        {
          label: "Customer Group Name",
          type: "text",
          name: "customerGroupName",
          initialFocus: false,
          validationProps: {
            required: "Customer group name is required"
          }
        }
      ]
    }
  },
  watchFields: []
}
