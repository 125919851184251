import React from 'react';

const withFormTemplate = WrappedComponent => {
    const form = ({ formType, formTemplate, isLoading = false, endpoint, isViewOnly, isNoDeactivate }) => {
        return formTemplate ?
                <WrappedComponent
                    isLoading={isLoading}
                    endpoint={endpoint}
                    isViewOnly={isViewOnly}
                    isNoDeactivate={isNoDeactivate}
                /> :
                null
    }

    return form;
};

export default withFormTemplate;