import { displayCellValue } from '../../../utils/column-values.util';

export const CUSTOMERS_COLUMNS = [
    {
        Header: "Customer Code",
        accessor: "customerCode",
        style: {
            position: "sticky",
            left: 0,
            top: 0,
            zIndex: 2
        }
    },
    {
        Header: "Customer Name",
        accessor: "customerName",
    },
    {
        Header: "Customer Tin",
        accessor: "customerTin",
        Cell: ({ value }) => {
            return displayCellValue(value);
        }
    },
    {
        Header: "Customer Phone No 1",
        accessor: "customerPhoneNumber1",
        Cell: ({ value }) => {
            return displayCellValue(value);
        }
    },
    {
        Header: "Customer Email",
        accessor: "customerEmail",
        Cell: ({ value }) => {
            return displayCellValue(value);
        }
    },
]