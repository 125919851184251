import { CSVDownload } from "react-csv";
import lodash from 'lodash';

const ExportCSV = ({ data }) => {
    const headers = Object.keys(data[0]).map(key => ({
        label: lodash.startCase(key),
        key
    }));
    
    const timestamp = new Date().getTime();

    return (
        <CSVDownload data={data} headers={headers} filename={timestamp + ".csv" } target="_blank" />
    )
}

export default ExportCSV;