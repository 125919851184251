const ADD_ITEM_FORM_TEMPLATE ={
    modalTitle: "New Item",
    path: "/items",
    listPage: "/list-items",
    bodySections: {
      basic: {
        sectionType: "basic",
        sectionTypeId: "basic",
        expandable: false,
        breakPoint: 0,
        fieldName: "item",
        fieldItems: [
          {
            label: "Item Code",
            type: "text",
            name: "itemCode",
            initialFocus: true,
            validationProps: {
              required: "Item code is required"
            }
          },
          {
            label: "Item Name",
            type: "text",
            name: "itemName",
            initialFocus: false,
            validationProps: {
              required: "Item name is required"
            }
          },
          {
            label: "Item Group",
            type: "select",
            name: "ItemGroups",
            required: false,
            initialFocus: false,
            muliselect:true,
            url: "/itemgroups"
          },
          {
            label: "Unit of Measure",
            type: "select",
            name: "UnitOfMeasure",
            required: false,
            initialFocus: false,
            muliselect:false,
            url: "/unitofmeasures"
          },
          {
            label: "Unit Selling Price (ETB)",
            type: "text",
            name: "unitSellingPrice",
            initialFocus: false,
          },
          {
            label: "Beginning Unit Cost (ETB) ",
            type: "text",
            name: "beginningUnitCost",
            initialFocus: false,
          },
          {
            label: "Reorder Level",
            type: "text",
            name: "reorderLevel",
            initialFocus: false,
            required: false,
          }
        ]
      },
      stockDetails: {
        sectionType: "stock details",
        sectionTypeId: "stockDetails",
        multiForm: true,
        mutliFormTitle: "Stock",
        expandable: true,
        isExpand: true,
        addButton: true,
        position: "LAST",
        breakPoint: 1,
        fieldName: "itemStock",
        fieldItems: [
          {
            label: "Beginning Quantity",
            type: "text",
            name: "beginningQty",
            initialFocus: false,
            validationProps: {
              required: "Beginning quantity is required"
            }
          },
          {
            label: "Location Code",
            type: "select",
            name: "Location",
            initialFocus: false,
            muliselect:false,
            url: "/locations",
            validationProps: {
              required: "Location code is required"
            }
          },
        ]
      },
    }
}

export default ADD_ITEM_FORM_TEMPLATE;