import { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { setFormSideBarNavActiveItem } from "../../redux/form/form.actions";
import { selectFormSideBarNav, selectFormSideBarNavActiveItem } from "../../redux/form/form.reselect";
import "./form-side-bar.styles.scss";

const FormSideBar = ({
    formSideBarNav,
    setFormSideBarNavActiveItem,
    formSideBarNavActiveItem,
    history,
    match
}) => {

    const handleClick = (event, id, pagePath) => {
        event.preventDefault();
        setFormSideBarNavActiveItem(id)
    }

    return (
        <div className="form-side-bar">
            <ul className="form-side-bar__nav">
                {
                    formSideBarNav.map((item, index) => {
                        return (
                            <li
                                className={`form-side-bar__nav__item`}
                                key={index}
                            >
                                <button
                                    className={`form-side-bar__nav__item__button
                                    ${index === formSideBarNavActiveItem && "form-side-bar__nav__item__button--active"}`}
                                    onClick={event => handleClick(event, index, item.pagePath)}
                                >{item.text}</button>
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    formSideBarNav: selectFormSideBarNav,
    formSideBarNavActiveItem: selectFormSideBarNavActiveItem
})

const mapDispatchToProps = (dispatch) => ({
    setFormSideBarNavActiveItem: (data) =>
        dispatch(setFormSideBarNavActiveItem(data)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FormSideBar));