import { format } from 'date-fns';
import FormTableCalculateDisplay from '../../../components/form-table-calculate/form-table-calculate.component';
import FormTableHeaderCell from '../../../components/form-table-header-cell/form-table-header-cell.component';
import FormTableInlineDelete from '../../../components/form-table-inline-delete/form-table-inline-delete.component';
import FormTableSelect from '../../../components/form-table-select/form-table-select.component';
import FormTableTextField from '../../../components/form-table-text-field/form-table-text-field.component';

export const ITEM_INFO = {
  formTitle: "Product",
  formTitleForNew: "New Product",
  path: "/items",
  listPage: "/items",
}

export const ITEM_FORM_TEMPLATE = {
  bodySections: {
    basic: {
      sectionType: "basic",
      sectionTypeId: "BASIC",
      expandable: false,
      breakPoint: 5,
      fieldName: "item",
      fieldItems: [
        {
          label: "Product Code",
          type: "text",
          name: "itemCode",
          initialFocus: false,
          validationProps: {
            required: "Product code is required"
          }
        },
        {
          label: "Product Name",
          type: "text",
          name: "itemName",
          initialFocus: false,
          validationProps: {
            required: "Product name is required"
          }
        },
        {
          label: "Category",
          type: "select",
          name: "ItemGroup",
          required: false,
          initialFocus: false,
          muliselect: false,
          url: "/itemgroups",
        },
        {
          label: "Unit of Measure",
          type: "select",
          name: "UnitOfMeasure",
          required: false,
          initialFocus: false,
          muliselect: false,
          url: "/unitofmeasures",
          validationProps: {
            required: "Unit of measure is required"
          }
        },
        {
          label: "Unit Price (ETB)",
          type: "text",
          name: "unitSellingPrice",
          initialFocus: false,
          validationProps: {
            required: "Unit Price is required"
          }
        },
        {
          label: "Unit Cost (ETB)",
          type: "text",
          name: "unitCost",
          initialFocus: false,
          required: false,
        },
        {
          label: "Beginning Unit Cost (ETB)",
          type: "text",
          name: "beginningUnitCost",
          initialFocus: false,
          required: false,
        },
        {
          label: "Average Unit Cost (ETB)",
          type: "text",
          name: "averageUnitCost",
          initialFocus: false,
          required: false,
          disabled: true
        },
        {
          label: "Reorder Level",
          type: "text",
          name: "reorderLevel",
          initialFocus: false,
          required: false,
        },
        {
          label: "Remark",
          type: "text",
          name: "description",
          initialFocus: false,
          required: false,
        },
      ]
    },
    tableForm: {
      sectionType: "Stock Details",
      sectionTypeId: "tableForm",
      fieldName: "items",
      showEditOnly: true, 
      hasNoAction: true,
      hasProgress: false,
      hasAutoFillButton: false,
      tableColumns: [
        {
          Header: "No.",
          id: "count",
          Cell: ({ row }) => Number(row.id) + 1,
          width: 30,
        },
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"Location"}
                required={false}
              />
            )
          },
          accessor: "location",
          width: 160,
          Cell: ({ row }) => {
            const endpointUrl = "/locations";
            const validationProps = {
              required: "Location is required"
            }

            return (<FormTableSelect
              fieldName={"items"}
              fieldItemName={"Location"}
              rowNumber={row.id}
              value={row.value}
              endpointUrl={endpointUrl}
              validationProps={validationProps}
              disabled={true}
            />)
          }
        },
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"Qty on Hand"}
                required={false}
              />
            )
          },
          accessor: "qtyOnHand",
          width: 160,
          Cell: ({ row }) => {
            return (<FormTableTextField
              fieldName={"items"}
              fieldItemName={"qtyOnHand"}
              rowNumber={row.id}
              valueType={"number"}
              validationProps={false}
              align="left"
              disabled={true}
            />)
          }
        },
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"Reserved"}
                required={false}
              />
            )
          },
          accessor: "reserve",
          width: 160,
          Cell: ({ row }) => {
            return (<FormTableTextField
              fieldName={"items"}
              fieldItemName={"reserve"}
              rowNumber={row.id}
              valueType={"number"}
              validationProps={false}
              align="left"
              disabled={true}
            />)
          }
        },
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"Available"}
                required={false}
              />
            )
          },
          accessor: "available",
          width: 160,
          Cell: ({ row }) => {
            return (<FormTableTextField
              fieldName={"items"}
              fieldItemName={"available"}
              rowNumber={row.id}
              valueType={"number"}
              validationProps={false}
              align="left"
              disabled={true}
            />)
          }
        }
      ]
    },
    totalDetails: {
      sectionType: "Total",
      sectionTypeId: "totalDetails",
      showEditOnly: true, 
      multiForm: false,
      expandable: false,
      isExpand: true,
      addButton: true,
      position: "LAST",
      breakPoint: 0,
      fieldName: "total",
      fieldItems: [
        {
          label: "Qty On Hand",
          type: "text",
          name: "totalQty",
          initialFocus: false,
          disabled: true
        }
      ],
    }
  },
  watchFields: []
}
