import React from 'react';

import './log-in-form-input.styles.scss';

const LogInFormInput = ({ 
    handleChange,
    handleKeyDown, 
    initialFocus, 
    register, 
    name, 
    label, 
    ...otherProps 
}) => (
   <div className="log-in-form-group">
       <label className="log-in-form-group__label">{ label }</label>
       <input 
            className="log-in-form-group__input" 
            {...otherProps} 
            { ...register(name) } 
            name= { name } 
            autoFocus = { initialFocus }
            onChange={ handleChange }
            onKeyDown = { handleKeyDown }
        />
   </div> 
);

export default LogInFormInput;