import React from "react";
import FormFieldError from "../form-field-error/form-field-error.componenet";
import { Controller, useFormContext } from "react-hook-form";

import "./form-field.styles.scss";
import { useEffect } from "react";
import { useRef } from "react";
import { connect } from "react-redux";
import { setValueEntredBlur } from "../../redux/form/form.actions";

const FormField = ({
    label,
    type,
    handleChange,
    initialFocus,
    onChange,
    required,
    fieldName,
    partNumber,
    fieldItemName,
    validationProps,
    fieldIndex,
    defaultValue,
    sectionTypeId,
    disabled,
    setValueEntredBlur,
    ...otherProps }) => {
    const { register, formState: { errors }, control } = useFormContext();
    const name = fieldName + '.' + partNumber + '.' + fieldItemName;

    const valueEntered = () => {
        setValueEntredBlur(true)
    }

    return (
        <div className="form-field">
            <label
                className="form-field__label">
                {label}
                {
                    validationProps ?
                        validationProps.required ? <span className="form-field__label__required"> * </span> :
                            null :
                        null
                }
            </label>

            <Controller
                control={control}
                {...register(name, validationProps)}
                ref={null}
                defaultValue={defaultValue}
                render={({
                    field: { onChange, onBlur, value, name, ref },
                }) => {
                    return (
                        <input
                            className={`form-field__input 
                             ${errors[fieldName]?.[partNumber]?.[fieldItemName] && "form-field__input--error"}
                             ${disabled && "form-field__input--disabled"}
                             `}
                            type={type}

                            value={value}
                            onChange={onChange}
                            autoFocus={initialFocus}
                            {...otherProps}
                            onBlur={(event) => {
                                valueEntered();
                            }}
                            disabled={disabled}

                        />
                    )
                }}

            />
            {errors?.[fieldName]?.[partNumber]?.[fieldItemName] && <FormFieldError errorMessage={errors?.[fieldName]?.[partNumber]?.[fieldItemName].message} />}
        </div>
    )
}
const mapDispatchToProps = dispatch => ({
    setValueEntredBlur: (data) =>
        dispatch(setValueEntredBlur(data))
})

export default connect(null, mapDispatchToProps)(FormField);
