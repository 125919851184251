
import { useState, useEffect } from "react";
import { Axios } from "../config";
import { getAuthToken } from '../utils/auth_token.utils'

const useFetchFieldValues = (
    endpoint,
    authTokenFromState,
    selectedField,
    shouldFetch
) => {
    const [fieldData, setFieldData] = useState([]);
    const [fieldError, setFieldError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const tokenHeader = getAuthToken(authTokenFromState);
    const fetchData = async () => {
        try {
            const response = await Axios.get(endpoint, {
                headers: tokenHeader,
                params: {
                    selectedValue: selectedField.value
                }
            })

            setFieldData(response.data);
            setIsLoading(false);
        } catch (error) {
            if (error.response) {
                if (error.response.status === 400
                    || error.response.status === 401
                    || error.response.status === 404
                ) {
                    setFieldError({
                        name: error.response.data.name,
                        message: error.response.data.message,
                        status: error.response.status
                    })
                } else {
                    setFieldError({
                        name: "system error",
                        message: "Please, try again after few moments. If you still can’t get the result your are looking for, contact your administrator."
                    })
                }
            }
            else if (error.request) {
                setFieldError({
                    name: "network error",
                    message: "Please, make sure you are connected to the network. If you still can’t get the result your are looking for, contact your administrator."
                })
            }
            else {
                setFieldError({
                    name: "unknown error",
                    message: "An unknown error occurred, please contact your administrator."
                })
            }

            setIsLoading(false);
            setFieldData([]);
        }
    }

    useEffect(() => {
        if (selectedField) {
            fetchData();
        }
    }, [selectedField]);

    return {
        fieldData,
        fieldError,
        isLoading
    };
}

export default useFetchFieldValues;