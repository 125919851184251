const EDIT_SUPPLIER_GROUP_FORM_TEMPLATE ={
    modalTitle: "Edit Customer Group",
    path: "/customergroups",
    listPage: "/list-customer-groups",
    bodySections: {
      basic: {
        sectionType: "basic",
        sectionTypeId: "BASIC_DETAILS",
        expandable: false,
        position: "LAST",
        breakPoint: 0,
        fieldName: "customerGroup",
        fieldItems: [
          {
            label: "Customer Group Name",
            type: "text",
            name: "customerGroup",
            initialFocus: true,
            validationProps: {
              required: "Customer group is required"
            }
          },
          {
            label: "description",
            type: "textarea",
            name: "description",
            required: false,
            initialFocus: false
          },
        ]
      }
    }
}

export default EDIT_SUPPLIER_GROUP_FORM_TEMPLATE;