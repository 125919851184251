import { useState, useEffect, useMemo, useRef } from "react";
import { Axios } from "../config";
import { getAuthToken } from '../utils/auth_token.utils'
import { isObjectsEqual } from "../utils/object-values.util";
import { removeEditedItem } from "../redux/list/list.actions";
import { useDispatch } from "react-redux";
import { removeDeletedItems } from "../redux/delete/delete.actions";

const useFetchReport = (
    endpoint,
    authTokenFromState,
    setSkip,
    skip,
    limit,
    reportDateRange,
    isRefresh,
    reportSideBarNavActiveItem,
    deactivateCheck,
    hasNoDate
) => {
    const [data, setData] = useState([]);
    const [dataExport, setDataExport] = useState([]);
    const [summaryData, setSummaryData] = useState([]);
    const [totalData, setTotalData] = useState([]);
    const [numItems, setNumItems] = useState(0);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const prevReportDateRangeRef = useRef({});

    const prevIsRefreshRef = useRef(false);
    const prevSkiphRef = useRef(0);

    const dispatch = useDispatch();

    const tokenHeader = getAuthToken(authTokenFromState);
    const fetchData = async () => {
        try {
            const preReportDateRange = prevReportDateRangeRef.current
            const prevIsRfresh = prevIsRefreshRef.current;

            const skipRest = !isObjectsEqual(preReportDateRange, reportDateRange) ?
                setSkip ? setSkip(0) : null :
                null

            if (isRefresh) {
                setSkip(0);
                prevIsRefreshRef.current = isRefresh;
                return null
            }

            const response = await Axios.get(endpoint, {
                headers: tokenHeader,
                params: {
                    skip: !isObjectsEqual(preReportDateRange, reportDateRange) ? 0 : skip,
                    limit,
                    reportDateRange,
                    deactivate: deactivateCheck
                }
            })

            if ((preReportDateRange && !isObjectsEqual(preReportDateRange, reportDateRange)) ||
                (Object.keys(preReportDateRange).length && skip === 0) ||
                (isRefresh === true)
            ) {
                setData(response.data.dataSlice);
            } else {
                setData(prevData => [...prevData, ...response.data.dataSlice]);
            }

            setDataExport(response.data.dataExport);
            setSummaryData(response.data.summary)
            setTotalData(response.data.total)
            setNumItems(response.data.numItems);
            setError(null);
            setIsLoading(false);

            prevReportDateRangeRef.current = reportDateRange
            prevSkiphRef.current = skip
        } catch (error) {
            if (error.response) {
                if (error.response.status === 400
                    || error.response.status === 401
                    || error.response.status === 404
                ) {
                    setError({
                        name: error.response.data.name,
                        message: error.response.data.message,
                        status: error.response.status
                    })
                } else {
                    setError({
                        name: "system error",
                        message: "Please, try again after few moments. If you still can’t get the result your are looking for, contact your administrator."
                    })
                }
            }
            else if (error.request) {
                setError({
                    name: "network error",
                    message: "Please, make sure you are connected to the network. If you still can’t get the result your are looking for, contact your administrator."
                })
            }
            else {
                setError({
                    name: "unknown error",
                    message: "An unknown error occurred, please contact your administrator."
                })
            }

            setDataExport([]);
            setSummaryData([])
            setTotalData([])
            setData([]);
            setIsLoading(false);
            prevReportDateRangeRef.current = reportDateRange
        }
    }

    useEffect(() => {
        setDataExport([]);
        setSummaryData([])
        setTotalData([])
        setData([]);
        setNumItems(0)
        prevReportDateRangeRef.current = {};
        prevIsRefreshRef.current = false;
        prevSkiphRef.current = 0;
    }, [reportSideBarNavActiveItem])

    useEffect(() => {
        if ((Object.keys(reportDateRange).length || hasNoDate)) {
            setIsLoading(true)
        }
    }, [reportDateRange, isRefresh, reportSideBarNavActiveItem, deactivateCheck])

    useEffect(() => {
        if ((Object.keys(reportDateRange).length || hasNoDate)) {
            fetchData();
        }
    }, [skip, reportDateRange, isRefresh, reportSideBarNavActiveItem, deactivateCheck]);

    return {
        data,
        dataExport,
        summaryData,
        totalData,
        numItems,
        error,
        isLoading
    };
}

export default useFetchReport;