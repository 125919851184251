import React, { useRef } from "react";

import "./help-filter.styles.scss";

import IconSearch from "../../assets/icons/search-icon-gray.svg";

import { useDebouncedCallback, useDebounce } from "use-debounce"
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";

import { setFilterInput, setLocalFilterFieldNamesAndValues } from "../../redux/filter/filter.actions";
import { selectFilterInput, selectLocalFilterFieldNamesAndValues } from "../../redux/filter/filter.reselect";
import { useState } from "react";
import { useEffect } from "react";

const ListGlobalFilter = ({
    helpData,
    handleFilter
}) => {
    const field = useRef();
    const [mount, setMount] = useState(false)
    const handleClick = () => {
        field.current.focus();
    }

    const [inputValue, setInputValue] = useState('');
    const [value] = useDebounce(inputValue, 200);


    useEffect(() => {
        if(value !==""){
            const filteredHelp = helpData.filter((item) => {
                return item.title.toLowerCase().includes(value.toLocaleLowerCase())
            });
            handleFilter(filteredHelp)
        } else {
            handleFilter(helpData)
        }
        
    }, [value])

    return (
        <span className="help-filter" onClick={handleClick}>
            <img
                className="help-filter__icon"
                src={IconSearch}
            />
            <input
                className="help-filter__field"
                type="search"
                placeholder="Search help"
                ref={field}
                onChange={event => setInputValue(event.target.value)}
                value={inputValue}
            />
        </span>
    )
};

const mapStateToProps = createStructuredSelector({
    filterInput: selectFilterInput,
    localFilter: selectLocalFilterFieldNamesAndValues
});

const mapDispatchToPropos = (dispatch) => ({
    setFilterInput: (filterInput) => dispatch(setFilterInput(filterInput)),
    setLocalFilterFieldNamesAndValues: (inputNameAndValue) => dispatch(setLocalFilterFieldNamesAndValues(inputNameAndValue))
})

export default connect(mapStateToProps, mapDispatchToPropos)(ListGlobalFilter);