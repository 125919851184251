import "./home-nav-item.styles.scss";

import IconNavList from '../../assets/icons/nav-list.svg';
import IconRightArrow from '../../assets/icons/right-arrow.svg';
import { Link } from "react-router-dom";

const HomeNavItem = ({
    navItem
}) => {
    return (
        <div className="home-nav-item">
            <div className="home-nav-item__header">
                <span className="home-nav-item__header__icon">
                    <img src={navItem.icon} />
                </span>
               
                <span className="home-nav-item__header__title">
                    {navItem.title}
                </span>
            </div>
            <ul className="home-nav-item__body">
                {
                    navItem.items.map(item => {
                        return (
                            <li className="home-nav-item__body__item">
                                <Link
                                    to={item.page}
                                    className="home-nav-item__body__item__link"
                                >
                                    {item.text}
                                    <img src={IconRightArrow} />
                                </Link>
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    )
}

export default HomeNavItem;