import detailActionType from "./detail.types"

const INITIAL_STATE = {
    uuid: null,
    detailData: null,
    detailPagePath: null,
    detailPrintTitle: null,
    detailPrintEndpoint: null
}
const detailReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case detailActionType.SET_UUID:
            return {
                ...state,
                uuid: action.payload
            }
        case detailActionType.SET_DETAIL_DATA:
            return {
                ...state,
                detailData: action.payload
            }
        case detailActionType.SET_DETAIL_PAGE_PATH:
            return {
                ...state,
                detailPagePath: action.payload
            }
        case detailActionType.SET_DETAIL_PRINT:
            return {
                ...state,
                detailPrintTitle: action.payload.title,
                detailPrintEndpoint: action.payload.endpoint,
            }
        default:
            return state;
    }
}

export default detailReducer;